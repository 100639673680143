// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthHead_root__ewcgU {
  width: 100%;
  height: 180px;
  padding: 0 15px 30px;
  position: relative;
}
.AuthHead_root__ewcgU:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 23, 39, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.AuthHead_headline__A4Zsm,
.AuthHead_description__\\+Xzqy {
  position: relative;
  z-index: 2;
}

.AuthHead_headline__A4Zsm {
  font-weight: var(--bold);
  font-size: 32px;
  color: var(--color-white);
  line-height: 1.1;
  margin: 0;
}

.AuthHead_description__\\+Xzqy {
  font-weight: var(--medium);
  font-size: 14px;
  color: var(--color-white);
  margin: 15px 0 0;
}
.AuthHead_description__\\+Xzqy p {
  margin: 0 0 10px;
}
.AuthHead_description__\\+Xzqy p:last-of-type {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/AuthHead/AuthHead.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,aAAA;EACA,oBAAA;EACA,kBAAA;AAAF;AACE;EACE,WAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,uCAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AACJ;;AAGA;;EAEE,kBAAA;EACA,UAAA;AAAF;;AAGA;EACE,wBAAA;EACA,eAAA;EACA,yBAAA;EACA,gBAAA;EACA,SAAA;AAAF;;AAGA;EACE,0BAAA;EACA,eAAA;EACA,yBAAA;EACA,gBAAA;AAAF;AACE;EACE,gBAAA;AACJ;AAAI;EACE,SAAA;AAEN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  height: 180px;\n  padding: 0 15px 30px;\n  position: relative;\n  &:after {\n    content: '';\n    display: block;\n    width: 100%;\n    height: 100%;\n    background-color: set-color(--color-mirage, 0.5);\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n}\n\n.headline,\n.description {\n  position: relative;\n  z-index: 2;\n}\n\n.headline {\n  font-weight: var(--bold);\n  font-size: 32px;\n  color: var(--color-white);\n  line-height: 1.1;\n  margin: 0;\n}\n\n.description {\n  font-weight: var(--medium);\n  font-size: 14px;\n  color: var(--color-white);\n  margin: 15px 0 0;\n  p {\n    margin: 0 0 10px;\n    &:last-of-type {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AuthHead_root__ewcgU`,
	"headline": `AuthHead_headline__A4Zsm`,
	"description": `AuthHead_description__+Xzqy`
};
export default ___CSS_LOADER_EXPORT___;
