// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomerSupport_root__link__2t6HO {
  padding: 10px;
  transition: background-color var(--def-transition-300ms);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.CustomerSupport_root__link__2t6HO:hover {
  background-color: var(--color-athens-gray);
}
.CustomerSupport_root__link__2t6HO:before {
  content: "";
  display: block;
  width: 80%;
  height: 1px;
  background-color: var(--color-athens-gray);
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  left: 50%;
}
.CustomerSupport_root__link__icon__j8vBv, .CustomerSupport_root__link__text__G-pJI {
  display: inline-block;
}
.CustomerSupport_root__link__icon__j8vBv {
  width: 26px;
  animation: "tada" 1s infinite;
}
.CustomerSupport_root__link__text__G-pJI {
  width: calc(100% - 26px);
  font-size: 14px;
  color: var(--color-primary);
  line-height: 1.2;
  padding: 0 0 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/SideBar/CustomerSupport/CustomerSupport.module.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,wDAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;AADJ;AAEI;EACE,0CAAA;AAAN;AAEI;EACE,WAAA;EACA,cAAA;EACA,UAAA;EACA,WAAA;EACA,0CAAA;EACA,2BAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;AAAN;AAEI;EAEE,qBAAA;AADN;AAGI;EACE,WAAA;EACA,6BAAA;AADN;AAGI;EACE,wBAAA;EACA,eAAA;EACA,2BAAA;EACA,gBAAA;EACA,mBAAA;AADN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  &__link {\n    padding: 10px;\n    transition: background-color var(--def-transition-300ms);\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    &:hover {\n      background-color: var(--color-athens-gray);\n    }\n    &:before {\n      content: '';\n      display: block;\n      width: 80%;\n      height: 1px;\n      background-color: var(--color-athens-gray);\n      transform: translateX(-50%);\n      position: absolute;\n      top: 0;\n      left: 50%;\n    }\n    &__icon,\n    &__text {\n      display: inline-block;\n    }\n    &__icon {\n      width: 26px;\n      animation: 'tada' 1s infinite;\n    }\n    &__text {\n      width: calc(100% - 26px);\n      font-size: 14px;\n      color: var(--color-primary);\n      line-height: 1.2;\n      padding: 0 0 0 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root__link": `CustomerSupport_root__link__2t6HO`,
	"root__link__icon": `CustomerSupport_root__link__icon__j8vBv`,
	"root__link__text": `CustomerSupport_root__link__text__G-pJI`
};
export default ___CSS_LOADER_EXPORT___;
