import styles from '@components/common/PackageList/columns/columns.module.scss';
import { PackageStatus } from '@components/common/PackageStatus/PackageStatus';
import routesSchema from '@libs/routes-schema';
import { classNames, getPackageStatus } from '@libs/utils';
import { Link } from 'react-router-dom';

const columns: any[] = [
  {
    name: '',
    width: '70px',
    selector: ({ CodEstadoPaquete }: { CodEstadoPaquete: number }) => {
      const status = getPackageStatus(CodEstadoPaquete);

      return status ? (
        <PackageStatus
          type='icon'
          value={{
            code: status.code,
            slug: status.slug,
            text: status.text,
          }}
        />
      ) : (
        '-'
      );
    },
  },
  {
    name: 'Código',
    selector: ({ CodPaquete }: { CodPaquete: string }) => (
      <Link
        to={routesSchema.packages.view + '/' + CodPaquete}
        className={classNames(styles.cell, styles.cell__code)}
      >
        {CodPaquete}
      </Link>
    ),
  },
  {
    name: 'Dirección',
    selector: ({ Address }: { Address: any }) => {
      if (Address && Address?.length > 0) {
        return (
          <span className={styles.cell} title={Address}>
            {Address.slice(0, 15) + '...'}
          </span>
        );
      }

      return '-';
    },
  },
  {
    name: 'Receptor',
    selector: ({ Owner }: { Owner: any }) => (
      <span className={styles.cell} title={Owner}>
        {Owner}
      </span>
    ),
  },
  {
    name: 'Estado',
    selector: ({ CodEstadoPaquete }: { CodEstadoPaquete: number }) => {
      const status = getPackageStatus(CodEstadoPaquete);

      return status ? (
        <PackageStatus
          type='text'
          value={{
            code: status.code,
            slug: status.slug,
            text: status.text,
          }}
        />
      ) : (
        '-'
      );
    },
  },
];

export default columns;
