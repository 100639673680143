import { Icon } from '@components/ui';
import styles from '@components/ui/FormControl/FormControl.module.scss';
import { classNames, delayFn, get18YearsAgo } from '@libs/utils';
import { getStrToLink } from '@libs/utils/layout';
import {
  FC,
  KeyboardEvent,
  LegacyRef,
  ReactElement,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DateTimePicker from 'react-datetime-picker';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  useController,
} from 'react-hook-form';

type FormControlProps = {
  control: Control<FieldValues, any>;
  type:
    | 'text'
    | 'email'
    | 'password'
    | 'number'
    | 'textarea'
    | 'select'
    | 'checkbox'
    | 'radio'
    | 'hidden'
    | 'datetime';
  fieldName: string;
  fieldRef?: RefObject<any>;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string | number;
  insertValue?: string | number;
  checked?: boolean | undefined;
  options?: { value: string | number; text: string | number }[];
  className?: string;
  placeholder?: string | JSX.Element;
  label?: string | JSX.Element;
  rows?: number;
  transformLabel?: boolean;
  isPassword?: boolean;
  icon?: string;
  width?: 25 | 33 | 50 | 75 | 100;
  validation?: 'on' | 'off';
  readOnly?: boolean;
  relativeErrors?: boolean;
  actionButton?: {
    text: string;
    onClick: () => void;
  };
  handleChange?: (value: any) => void | undefined;
  setValue?: UseFormSetValue<FieldValues>;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void | undefined;
};

export const FormControl: FC<FormControlProps> = ({
  control,
  type,
  fieldName,
  fieldRef,
  required,
  disabled,
  defaultValue,
  insertValue,
  checked = false,
  options = [],
  className = '',
  label,
  placeholder,
  rows = 6,
  transformLabel = false,
  isPassword,
  icon,
  width = 100,
  validation = 'on',
  readOnly = false,
  actionButton,
  relativeErrors = true,
  handleChange,
  setValue,
  onKeyUp,
}): ReactElement => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const {
    field: { onChange, onBlur, ref, name, value },
    fieldState: { isTouched, isDirty },
    formState: { errors },
  } = useController({
    name: fieldName,
    control,
    defaultValue,
  });
  const datePickerMaxDate = useMemo((): Date => get18YearsAgo(), []);

  useEffect(() => {
    setValue && setValue(fieldName, defaultValue);
  }, [defaultValue, value]);

  switch (type) {
    case 'text':
    case 'email':
    case 'password':
    case 'number': {
      return (
        <div
          className={classNames(
            styles.root,
            styles[`root__width_${width}`],
            validation === 'on'
              ? isTouched && errors[`${name}`]
                ? styles.root__error__rel
                : ''
              : '',
            validation === 'on'
              ? isTouched && errors[`${name}`]
                ? styles.root__error__rel
                : ''
              : ''
          )}
        >
          <div className={styles.root__inner}>
            {label && (
              <label htmlFor={fieldName} className={styles.field__label}>
                {label}
              </label>
            )}
            <div className={styles.field_wrapper}>
              {icon && icon?.length > 0 && (
                <div className={styles.field__icon}>
                  <Icon name={icon} />
                </div>
              )}
              <input
                id={fieldName}
                ref={
                  fieldRef && validation === 'off'
                    ? (fieldRef as LegacyRef<HTMLInputElement>)
                    : ref
                }
                type={
                  type === 'password'
                    ? isPasswordVisible
                      ? 'text'
                      : 'password'
                    : type
                }
                placeholder={placeholder as string}
                disabled={disabled}
                required={required}
                defaultValue={value}
                readOnly={readOnly}
                autoComplete='off'
                className={classNames(
                  className,
                  styles.field,
                  styles[`field__${type}`],
                  icon && icon?.length > 0 ? styles.field__has_icon : '',
                  disabled ? styles[`field__${type}__disabled`] : '',
                  validation === 'on'
                    ? isTouched && errors[`${name}`]
                      ? styles.field__error
                      : isDirty && !errors[`${name}`]
                      ? styles.field__success
                      : ''
                    : ''
                )}
                onChange={onChange}
                onBlur={onBlur}
              />
              {isPassword && (
                <button
                  type='button'
                  className={styles.field__toggle_password}
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                  {isPasswordVisible && (
                    <Icon
                      name='x0001'
                      width={22}
                      height={22}
                      style={{ marginTop: '1px' }}
                      color={
                        errors[`${name}`]
                          ? 'error'
                          : (isTouched || isDirty) && !errors[`${name}`]
                          ? 'primary'
                          : ''
                      }
                    />
                  )}
                  {!isPasswordVisible && (
                    <Icon
                      name='x0002'
                      width={22}
                      height={14}
                      color={
                        errors[`${name}`]
                          ? 'error'
                          : (isTouched || isDirty) && !errors[`${name}`]
                          ? 'primary'
                          : ''
                      }
                    />
                  )}
                </button>
              )}
              {typeof actionButton !== 'undefined' && (
                <button
                  type='button'
                  className={styles.field__action_button}
                  onClick={actionButton.onClick}
                >
                  {actionButton.text}
                </button>
              )}
            </div>
            {validation === 'on' && errors[`${name}`] && (
              <div
                className={classNames(
                  styles.error,
                  styles[`error__${type}`],
                  relativeErrors ? styles.error__relative : ''
                )}
              >
                <span className={styles.error__text}>
                  {errors[`${name}`]?.message as any}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    case 'textarea': {
      return (
        <div
          className={classNames(
            styles.root,
            styles[`root__width_${width}`],
            validation === 'on'
              ? isTouched && errors[`${name}`]
                ? styles.root__error__rel
                : ''
              : ''
          )}
        >
          <div className={styles.root__inner}>
            {label && (
              <label htmlFor={fieldName} className={styles.field__label}>
                {label}
              </label>
            )}
            <div className={styles.field_wrapper}>
              {icon && icon?.length > 0 && (
                <div className={styles.field__icon}>
                  <Icon name={icon} />
                </div>
              )}
              <textarea
                id={fieldName}
                ref={
                  fieldRef && validation === 'off'
                    ? (fieldRef as LegacyRef<HTMLTextAreaElement>)
                    : ref
                }
                placeholder={placeholder as string}
                disabled={disabled}
                required={required}
                defaultValue={value}
                readOnly={readOnly}
                rows={rows}
                autoComplete='off'
                className={classNames(
                  className,
                  styles.field,
                  styles[`field__${type}`],
                  icon && icon?.length > 0 ? styles.field__has_icon : '',
                  validation === 'on'
                    ? isTouched && errors[`${name}`]
                      ? styles.field__error
                      : isDirty && !errors[`${name}`]
                      ? styles.field__success
                      : ''
                    : ''
                )}
                onChange={onChange}
                onKeyUp={(e: any) => {
                  onChange(e);
                  typeof handleChange !== 'undefined' &&
                    handleChange(e.target.value);
                }}
                onBlur={onBlur}
              />
            </div>
            {validation === 'on' && errors[`${name}`] && (
              <div
                className={classNames(
                  styles.error,
                  styles[`error__${type}`],
                  relativeErrors ? styles.error__relative : ''
                )}
              >
                <span className={styles.error__text}>
                  {errors[`${name}`]?.message as any}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    case 'select': {
      const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
      const [valueObj, setValueObj] = useState<{
        value: any;
        text: any;
      }>();

      useEffect(() => {
        if (defaultValue) {
          setValueObj({
            value: defaultValue,
            text: defaultValue.toString().toUpperCase(),
          });
        }
      }, []);

      return (
        <div
          className={classNames(
            styles.root,
            styles[`root__width_${width}`],
            validation === 'on'
              ? isTouched && errors[`${name}`]
                ? styles.root__error__rel
                : ''
              : ''
          )}
        >
          <div className={styles.root__inner}>
            {label && (
              <label htmlFor={fieldName} className={styles.field__label}>
                {label}
              </label>
            )}
            <div className={styles.field_wrapper}>
              <input
                ref={ref}
                type='hidden'
                name={fieldName}
                defaultValue={valueObj?.value}
                autoComplete='off'
              />
              <input
                type='text'
                defaultValue={valueObj?.text}
                placeholder={placeholder as string}
                disabled={disabled}
                autoComplete='off'
                readOnly
                className={classNames(
                  className,
                  styles.field,
                  styles[`field__${type}`],
                  isTouched && errors[`${name}`]
                    ? styles.field__error
                    : isDirty && !errors[`${name}`]
                    ? styles.field__success
                    : ''
                )}
                onChange={onChange}
                onBlur={onBlur}
                onBlurCapture={() =>
                  delayFn(() => setIsCollapsed(!isCollapsed), 300)
                }
                onClick={() => setIsCollapsed(!isCollapsed)}
              />
              <Icon
                name='x0006'
                width={12}
                height={6}
                classList={classNames(
                  styles[`field__${type}__chevron`],
                  isTouched && errors[`${name}`]
                    ? styles[`field__${type}__chevron__error`]
                    : isDirty && !errors[`${name}`]
                    ? styles[`field__${type}__chevron__success`]
                    : '',
                  isCollapsed ? styles[`field__${type}__chevron__rotated`] : ''
                )}
              />
            </div>
            {options?.length > 0 && (
              <ul
                className={classNames(
                  styles[`field__${type}__dropdown`],
                  isCollapsed
                    ? styles[`field__${type}__dropdown__collapsed`]
                    : ''
                )}
              >
                {options.map(({ value, text }) => (
                  <li
                    key={value}
                    onClick={(e) => {
                      setValueObj({ value, text });
                      onChange(value);
                      onBlur();
                      typeof handleChange !== 'undefined' &&
                        handleChange(value);
                      setIsCollapsed(false);
                    }}
                  >
                    <span data-value={value}>{text}</span>
                  </li>
                ))}
              </ul>
            )}
            {validation === 'on' && errors[`${name}`] && (
              <div
                className={classNames(
                  styles.error,
                  styles[`error__${type}`],
                  relativeErrors ? styles.error__relative : ''
                )}
              >
                <span className={styles.error__text}>
                  {errors[`${name}`]?.message as any}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    case 'checkbox': {
      const transformedLabel = transformLabel
        ? getStrToLink(label as string, 'Link')
        : label;

      return (
        <div
          className={classNames(
            styles.root,
            styles[`root__width_${width}`],
            validation === 'on'
              ? isTouched && errors[`${name}`]
                ? styles.root__error__rel
                : ''
              : ''
          )}
        >
          <div className={styles.root__inner}>
            <div
              className={classNames(
                styles.field_wrapper,
                styles[`field_wrapper__${type}`]
              )}
            >
              <input
                id={fieldName}
                ref={ref}
                type={type}
                defaultChecked={checked}
                value={checked ? 1 : 0}
                placeholder={placeholder as string}
                disabled={disabled}
                required={required}
                autoComplete='off'
                className={styles[`field__${type}__hidden`]}
                onChange={onChange}
                onBlur={onBlur}
              />
              <label
                htmlFor={fieldName}
                className={classNames(
                  className,
                  styles.field,
                  styles[`field__${type}`]
                )}
              ></label>
              <label
                htmlFor={fieldName}
                className={classNames(
                  `${className}`,
                  styles.label,
                  styles[`label__${type}`]
                )}
              >
                {transformedLabel}
              </label>
            </div>
            {validation === 'on' && errors[`${name}`] && (
              <div
                className={classNames(
                  styles.error,
                  styles[`error__${type}`],
                  relativeErrors ? styles.error__relative : ''
                )}
              >
                <span className={styles.error__text}>
                  {errors[`${name}`]?.message as any}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    case 'radio': {
      return (
        <div
          className={classNames(
            styles.root,
            styles[`root__width_${width}`],
            validation === 'on'
              ? isTouched && errors[`${name}`]
                ? styles.root__error__rel
                : ''
              : ''
          )}
        >
          <div className={styles.root__inner}>
            <div
              className={classNames(
                styles.field_wrapper,
                styles[`field_wrapper__${type}`]
              )}
            >
              <input
                id={insertValue + ''}
                ref={ref}
                type={type}
                name={fieldName}
                placeholder={placeholder as string}
                disabled={disabled}
                required={required}
                value={insertValue}
                checked={insertValue === value}
                autoComplete='off'
                className={styles[`field__${type}__hidden`]}
                onChange={onChange}
                onBlur={onBlur}
              />
              <label
                htmlFor={insertValue + ''}
                className={classNames(
                  className,
                  styles.field,
                  styles[`field__${type}`]
                )}
              ></label>
              <label
                htmlFor={insertValue + ''}
                className={classNames(
                  `${className}`,
                  styles.label,
                  styles[`label__${type}`]
                )}
              >
                {label}
              </label>
            </div>
            {validation === 'on' && errors[`${name}`] && (
              <div
                className={classNames(
                  styles.error,
                  styles[`error__${type}`],
                  relativeErrors ? styles.error__relative : ''
                )}
              >
                <span className={styles.error__text}>
                  {errors[`${name}`]?.message as any}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    case 'datetime': {
      return (
        <div
          className={classNames(
            styles.root,
            styles[`root__width_${width}`],
            validation === 'on'
              ? isTouched && errors[`${name}`]
                ? styles.root__error__rel
                : ''
              : ''
          )}
        >
          <div className={styles.root__inner}>
            <div
              className={classNames(
                styles.field_wrapper,
                styles[`field_wrapper__${type}`]
              )}
            >
              {label && (
                <label htmlFor={fieldName} className={styles.field__label}>
                  {label}
                </label>
              )}
              <DateTimePicker
                className={classNames(
                  className,
                  styles.field,
                  styles[`field__${type}`]
                )}
                locale='es-PE'
                format='y-MM-dd'
                yearPlaceholder='YYYY'
                monthPlaceholder='MM'
                dayPlaceholder='DD'
                onChange={onChange}
                defaultValue={datePickerMaxDate}
                value={value}
                isClockOpen={false}
                clearIcon={null}
                maxDate={datePickerMaxDate}
              />
            </div>
            {validation === 'on' && errors[`${name}`] && (
              <div
                className={classNames(
                  styles.error,
                  styles[`error__${type}`],
                  relativeErrors ? styles.error__relative : ''
                )}
              >
                <span className={styles.error__text}>
                  {errors[`${name}`]?.message as any}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    default:
      return <></>;
  }
};
