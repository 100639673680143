// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopBar_root__4cIH9 {
  width: 100%;
  padding: 0 0 0 260px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
}
.TopBar_root__inner__cDsUs {
  width: 100%;
  background-color: var(--color-white);
  padding: 10px 30px 0;
}

.TopBar_divider__5F7-a {
  width: 100%;
  height: 1px;
  background-color: var(--color-athens-gray);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/TopBar/TopBar.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,oBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;AAAF;AACE;EACE,WAAA;EACA,oCAAA;EAEA,oBAAA;AAAJ;;AAIA;EACE,WAAA;EACA,WAAA;EACA,0CAAA;AADF","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  padding: 0 0 0 260px;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 1030;\n  &__inner {\n    width: 100%;\n    background-color: var(--color-white);\n    // box-shadow: var(--box-shadow);\n    padding: 10px 30px 0;\n  }\n}\n\n.divider {\n  width: 100%;\n  height: 1px;\n  background-color: var(--color-athens-gray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `TopBar_root__4cIH9`,
	"root__inner": `TopBar_root__inner__cDsUs`,
	"divider": `TopBar_divider__5F7-a`
};
export default ___CSS_LOADER_EXPORT___;
