import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import { IMenu } from '@store/services/menus/types';

const BASE_URL =
  `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SUFFIX}` as string;

/**
 * Slise / Api
 */
export const menusService = createApi({
  tagTypes: ['Menus'],
  reducerPath: 'menusService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/menus`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    fetchMenu: builder.query<IMenu, { slug: string }>({
      query: (args) => {
        return {
          url: args.slug + '/',
          method: 'GET',
        };
      },
      transformResponse: (result: any, meta, arg) => ({
        items: result?.data || [],
      }),
    }),
  }),
});

export const { useFetchMenuQuery } = menusService;
