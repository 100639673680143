import schema from '@libs/json/page-titles.json';
import { useAppSelector } from '@store/store';
import { useMemo, useState } from 'react';

interface IItem {
  uri: string;
  title: string;
}

export const usePageTitle = (): { title: string } => {
  const { pathname } = useAppSelector((state) => state.router.location);
  const [title, setTitle] = useState<string>('');

  useMemo(() => {
    const item: IItem | undefined = schema.find(
      (item: IItem) => item.uri === pathname
    );

    typeof item !== 'undefined' ? setTitle(item.title) : setTitle(' ');
  }, [pathname]);

  return { title };
};
