import { LogoBrand, UserCard } from '@components/common';
import CustomerSupport from '@components/common/SideBar/CustomerSupport';
import Menu from '@components/common/SideBar/Menu';
import styles from '@components/common/SideBar/SideBar.module.scss';
import { Scrollbars } from '@rastopyr/react-custom-scrollbar';
import { useFetchMenuQuery } from '@store/services/menus/menusService';
import { FC, ReactElement } from 'react';

type SideBarProps = {};

export const SideBar: FC<SideBarProps> = (): ReactElement => {
  const { data, isSuccess } = useFetchMenuQuery({ slug: 'side-bar-menu' });

  return (
    <aside className={styles.root}>
      <Scrollbars autoHide={true} style={{ height: '100%' }}>
        <div className={styles.root__inner}>
          <LogoBrand />
          <UserCard type='desktop' />
          <div className={styles.divider}></div>
          {isSuccess && data?.items && <Menu list={data.items} />}
          <CustomerSupport />
        </div>
      </Scrollbars>
    </aside>
  );
};
