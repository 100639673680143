import * as formatModule from 'date-fns';
import { isAfter } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { ComponentType } from 'react';

export const isDefined = (input: any): any => typeof input !== 'undefined';

export const classNames = (...args: string[]) =>
  [...args].join(' ').trim().replaceAll(/\s\s+/g, ' ');

export const createStyleSheet = (styleSheetId: string) => {
  if (typeof document !== 'undefined') {
    if (!document.getElementById(styleSheetId)) {
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      style.id = styleSheetId;
      head.appendChild(style);
    }
  }
};

export const setStyleSheetVars = (obj: any = {}, styleSheetId: string) => {
  createStyleSheet(styleSheetId);

  if (typeof document !== 'undefined') {
    const { styleSheets } = document;

    if (styleSheets) {
      for (const styleSheet of styleSheets as any) {
        const node = styleSheet.ownerNode;

        if (node?.id === styleSheetId) {
          let cssRules = '';

          for (let i = 0; i < styleSheet.cssRules.length; i++) {
            const cssRule = styleSheet.cssRules[i];

            if (cssRule && cssRule?.selectorText === ':root') {
              styleSheet.deleteRule(i);
            }
          }

          Object.keys(obj).forEach((key) => {
            const p = '--' + key.replace(/([A-Z])/g, '-$1').toLowerCase();
            cssRules += p + ':' + obj[key] + ';\n';
          });

          styleSheet.insertRule(`:root{${cssRules}}`);

          break;
        }
      }
    }
  }
};

export const encodeFormData = (obj: any) => {
  let formBody = [];

  for (const prop in obj) {
    const encodedKey = encodeURIComponent(prop);
    const encodedValue = encodeURIComponent(obj[prop]);

    formBody.push(encodedKey + '=' + encodedValue);
  }

  return formBody.join('&');
};

export const getObjPropValue = (obj: any, key: string): any => {
  if (obj) {
    for (const objKey in obj) {
      if (objKey === key) {
        return obj[objKey];
      }
    }
  }
};

export const getDateTime = (
  dateStr: string,
  tFormat: string = 'd MMMM u h:mm aaa',
  locale: string = 'es'
) => {
  if (!formatModule.isValid(dateStr)) return null;

  const formattedDateStr = dateStr.substring(0, dateStr.lastIndexOf('-'));
  const datetime = new Date(formattedDateStr);

  return formatModule.format(datetime, tFormat, {
    locale: locale === 'es' ? es : enUS,
  });
};

export const isDateTimeAfter = (date1: string, date2: string): boolean => {
  const xDateTime = new Date(date1);
  const yDateTime = new Date(date2);

  return isAfter(xDateTime, yDateTime);
};

export const omitObjProps = (obj: any, ...props: any) =>
  props.reduce(function (result: any, prop: string) {
    result[prop] = obj[prop];
    return result;
  }, {});

export const delayFn = (callback: () => void, delay: number = 1000) =>
  new Promise((resolve) => setTimeout(() => resolve(callback()), delay));

export const lazyImport = (
  path: string,
  delay: number = 1500
): Promise<{ default: ComponentType }> =>
  new Promise((resolve) => setTimeout(() => resolve(import(`${path}`)), delay));

export const getStatusIconBySlug = (
  slug: string | undefined | null
): string | null => {
  switch (slug) {
    case 'created':
      return 'x0021';

    case 'on_route':
      return 'x0022';

    case 'failed':
      return 'x0023';

    case 'delivered':
      return 'x0024';

    default:
      return null;
  }
};

export const getPackageStatus = (value: number | null | undefined) => {
  switch (value) {
    case 0:
      return {
        code: value,
        type: 'A',
        slug: 'all',
        text: 'Todo',
      };

    case 3:
      return {
        code: value,
        type: 'C',
        slug: 'created',
        text: 'Programado',
      };

    case 8:
      return {
        code: value,
        type: 'OR',
        slug: 'on_route',
        text: 'En Ruta',
        iconName: 'x0022',
      };

    case 9:
      return {
        code: value,
        type: 'D',
        slug: 'delivered',
        text: 'Entregado',
      };

    case 11:
      return {
        code: value,
        type: 'F',
        slug: 'failed',
        text: 'Fallido',
      };
  }
};

export const getPackageStatusBySlug = (value: string | undefined) => {
  switch (value) {
    case 'all':
      return {
        code: 0,
        type: 'A',
        slug: 'all',
        text: 'Todo',
      };

    case 'created':
      return {
        code: 3,
        type: 'C',
        slug: 'created',
        text: 'Programado',
      };

    case 'on_route':
      return {
        code: 8,
        type: 'OR',
        slug: 'on_route',
        text: 'En Ruta',
      };

    case 'delivered':
      return {
        code: 9,
        type: 'D',
        slug: 'delivered',
        text: 'Entregado',
      };

    case 'failed':
      return {
        code: 11,
        type: 'F',
        slug: 'failed',
        text: 'Fallido',
      };

    default:
      return {
        code: -1,
        slug: '',
        text: '',
      };
  }
};

export const getPackageStatusByType = (value: string | undefined) => {
  switch (value) {
    case 'A':
      return {
        code: 0,
        type: 'A',
        slug: 'all',
        text: 'Todo',
      };

    case 'C':
      return {
        code: 3,
        type: 'C',
        slug: 'created',
        text: 'Programado',
      };

    case 'OR':
      return {
        code: 8,
        type: 'OR',
        slug: 'on_route',
        text: 'En Ruta',
      };

    case 'D':
      return {
        code: 9,
        type: 'D',
        slug: 'delivered',
        text: 'Entregado',
      };

    case 'F':
      return {
        code: 11,
        type: 'F',
        slug: 'failed',
        text: 'Fallido',
      };

    default:
      return {
        code: -1,
        slug: '',
        text: '',
      };
  }
};

export const getDayBeforeToday = (): string => {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);

  return yesterday.toLocaleDateString();
};

export const get18YearsAgo = (): Date => {
  const today = new Date();
  const eighteenYearsAgo = new Date(today);

  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  return eighteenYearsAgo;
};

export const outputApiMedia = (file: string): string =>
  file && file?.length > 0
    ? process.env.REACT_APP_API_URL + file
    : '/content/auth-bg-img.jpg';
