// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PackageGroup_root__3tXrS {
  width: 100%;
  margin: 0 0 30px;
}
.PackageGroup_root__3tXrS:last-child {
  margin: 0;
}
.PackageGroup_root__head__RiKIS {
  width: 100%;
  margin: 0 0 10px;
}
.PackageGroup_root__head__created__aimWq {
  color: var(--color-medium-purple);
}
.PackageGroup_root__head__on_route__xfC-m {
  color: var(--color-gold-tips);
}
.PackageGroup_root__head__delivered__5sVp- {
  color: var(--color-la-palma);
}
.PackageGroup_root__head__failed__Tw2xa {
  color: var(--color-valencia);
}
.PackageGroup_root__head__RiKIS h4 {
  font-weight: var(--semi-bold);
  font-size: 18px;
}
.PackageGroup_root__head__RiKIS h4 span {
  color: var(--color-primary);
}
.PackageGroup_root__head__action__tBmML {
  font-weight: var(--semi-bold);
  font-size: 14px;
  color: var(--color-primary);
  line-height: 1.2;
}
.PackageGroup_root__head__action__disabled__bPGPv {
  color: var(--color-cadet-blue);
  pointer-events: none;
  cursor: default;
}
.PackageGroup_root__body__not_found__NlMZ6 {
  width: 100%;
  text-align: center;
}
.PackageGroup_root__body__not_found__NlMZ6 p {
  color: var(--color-gray);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/PackageGroup/PackageGroup.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,gBAAA;AAAF;AACE;EACE,SAAA;AACJ;AACE;EACE,WAAA;EACA,gBAAA;AACJ;AAAI;EACE,iCAAA;AAEN;AAAI;EACE,6BAAA;AAEN;AAAI;EACE,4BAAA;AAEN;AAAI;EACE,4BAAA;AAEN;AAAI;EACE,6BAAA;EACA,eAAA;AAEN;AADM;EACE,2BAAA;AAGR;AAAI;EACE,6BAAA;EACA,eAAA;EACA,2BAAA;EACA,gBAAA;AAEN;AAAM;EACE,8BAAA;EACA,oBAAA;EACA,eAAA;AAER;AAGI;EACE,WAAA;EACA,kBAAA;AADN;AAEM;EACE,wBAAA;AAAR","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  margin: 0 0 30px;\n  &:last-child {\n    margin: 0;\n  }\n  &__head {\n    width: 100%;\n    margin: 0 0 10px;\n    &__created {\n      color: var(--color-medium-purple);\n    }\n    &__on_route {\n      color: var(--color-gold-tips);\n    }\n    &__delivered {\n      color: var(--color-la-palma);\n    }\n    &__failed {\n      color: var(--color-valencia);\n    }\n    h4 {\n      font-weight: var(--semi-bold);\n      font-size: 18px;\n      span {\n        color: var(--color-primary);\n      }\n    }\n    &__action {\n      font-weight: var(--semi-bold);\n      font-size: 14px;\n      color: var(--color-primary);\n      line-height: 1.2;\n\n      &__disabled {\n        color: var(--color-cadet-blue);\n        pointer-events: none;\n        cursor: default;\n      }\n    }\n  }\n  &__body {\n    &__not_found {\n      width: 100%;\n      text-align: center;\n      p {\n        color: var(--color-gray);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PackageGroup_root__3tXrS`,
	"root__head": `PackageGroup_root__head__RiKIS`,
	"root__head__created": `PackageGroup_root__head__created__aimWq`,
	"root__head__on_route": `PackageGroup_root__head__on_route__xfC-m`,
	"root__head__delivered": `PackageGroup_root__head__delivered__5sVp-`,
	"root__head__failed": `PackageGroup_root__head__failed__Tw2xa`,
	"root__head__action": `PackageGroup_root__head__action__tBmML`,
	"root__head__action__disabled": `PackageGroup_root__head__action__disabled__bPGPv`,
	"root__body__not_found": `PackageGroup_root__body__not_found__NlMZ6`
};
export default ___CSS_LOADER_EXPORT___;
