import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '@libs/utils';
import { Icon } from '@components/ui';
import styles from '@components/common/PackageNotFound/PackageNotFound.module.scss';

type PackageNotFoundProps = {};

export const PackageNotFound: FC<PackageNotFoundProps> = (): ReactElement => (
  <div
    className={classNames(
      'fx-column',
      'fx-align-center',
      'fx-content-center',
      styles.root
    )}
  >
    <Icon name='x0031' width={64} height={64} />
    <div
      className={classNames(
        'fx-column',
        'fx-align-center',
        'fx-content-center',
        styles.root__description
      )}
    >
      <p>¡Lo siento!</p>
      <p>No se encontró nada en este número.</p>
      <Link to='/customer-support'>¿Consigue ayuda?</Link>
    </div>
  </div>
);
