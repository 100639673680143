import { STYLE_SHEETS_IDS } from '@libs/constants';
import { setStyleSheetVars } from '@libs/utils';
import { useEffect } from 'react';

export const useSetCssVars = () => {
  useEffect(() => {
    window.addEventListener('load', () => setCssVars());
    window.addEventListener('scroll', () => setCssVars());
    window.addEventListener('resize', () => setCssVars());

    return () => {
      window.removeEventListener('load', () => setCssVars());
      window.removeEventListener('scroll', () => setCssVars());
      window.removeEventListener('resize', () => setCssVars());
    };
  }, []);

  function setCssVars() {
    const vars = {
      barsHeight: window.innerHeight * 0.01,
      barsHeightUnit: window.innerHeight * 0.01 + 'px',
    };
    setStyleSheetVars(vars, STYLE_SHEETS_IDS.common);
  }
};
