import { FC, ReactElement } from 'react';
import { Oval } from 'react-loader-spinner';

type SuspenseLoaderProps = {
  paddingBottom?: number;
  position?: 'fixed' | 'absolute';
};

export const SuspenseLoader: FC<SuspenseLoaderProps> = ({
  paddingBottom = 68,
  position = 'fixed',
}): ReactElement => (
  <div
    className='fx-align-center fx-content-center'
    style={{
      width: '100%',
      height: '100%',
      paddingBottom,
      position,
      top: 0,
      left: 0,
    }}
  >
    <Oval
      height={48}
      width={48}
      color='var(--color-primary)'
      wrapperStyle={{}}
      wrapperClass=''
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor='var(--color-primary)'
      strokeWidth={4}
      strokeWidthSecondary={4}
    />
  </div>
);
