import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '@store/store';

interface ISelectedCategory {
  code: number;
  type: '' | 'A' | 'D' | 'OR' | 'F' | 'C';
  slug: string;
  text: string;
}

export interface State {
  selectedCategory: ISelectedCategory;
}

const initialState: State = {
  selectedCategory: {
    code: -1,
    type: '',
    slug: '',
    text: '',
  },
};

/**
 * Create Slice
 */
export const packagesSlice = createSlice({
  initialState,
  name: 'packageSlice',
  reducers: {
    setPackagesCategory: (
      state,
      { payload }: PayloadAction<ISelectedCategory>
    ) => {
      state.selectedCategory = payload;
    },
  },
});

export default packagesSlice.reducer;

export const { setPackagesCategory } = packagesSlice.actions;

/**
 * Define Selectors
 */
const selectSelf = (state: RootState) => state.packages;

export const selectSelectedCategory = createDraftSafeSelector(
  selectSelf,
  ({ selectedCategory }) => selectedCategory
);
