import {
  EmptyResults,
  PackageStatus,
  SuspenseLoader,
} from '@components/common';
import styles from '@components/common/PackageDetails/PackageDetails.module.scss';
import { Icon } from '@components/ui';
import { usePackageEstimatedDateTime } from '@hooks/usePackageEstimatedDateTime';
import { classNames, getPackageStatus } from '@libs/utils';
import { setPageTitle } from '@store/features/meta/meta.slice';
import { useFetchPackageQuery } from '@store/services/packages/packagesService';
import { useAppDispatch } from '@store/store';
import { FC, ReactElement, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

type PackageDetailsProps = {
  code: string;
};

export const PackageDetails: FC<PackageDetailsProps> = ({
  code,
}): ReactElement => {
  const dispatch = useAppDispatch();
  const [tooltipTimeout, setTooltipTimeout] = useState<any>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { data, isLoading, isSuccess } = useFetchPackageQuery(
    { code },
    { skip: false }
  );
  const { packageIsRescheduling } = usePackageEstimatedDateTime(
    data?.package?.EntregaDate,
    data?.package?.CodPaquete
  );

  useEffect(() => {
    dispatch(setPageTitle('Entrega: ' + code));
  }, []);

  const toggleTooltip = () => {
    tooltipTimeout && clearTimeout(tooltipTimeout);

    setShowTooltip(true);

    setTooltipTimeout(
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500)
    );
  };

  if (isLoading) {
    return <SuspenseLoader position='absolute' />;
  }

  if (isSuccess && data && data?.package && data?.roadmap) {
    const status = getPackageStatus(data.package.CodEstadoPaquete);

    return (
      <div className={styles.root}>
        <div className='col-x12 fx'>
          <PackageStatus type='icon' value={status} />
          <div className={styles.head}>
            <div
              className={classNames('fx', 'fx-align-center', styles.head__row)}
            >
              <h1 className={styles.head__code}>{code}</h1>
              <button type='button' className={styles.head__copy}>
                {showTooltip && (
                  <div className={styles.head__copy__tooltip}>Copiado</div>
                )}
                <CopyToClipboard text={code} onCopy={toggleTooltip}>
                  <Icon name='x0026' width={18} height={18} />
                </CopyToClipboard>
              </button>
            </div>
            <div
              className={classNames(
                'fx',
                'fx-align-center',
                styles.head__row,
                styles.head__row__x2
              )}
            >
              <PackageStatus
                type='text'
                value={status}
                classList={styles.head__status}
              />
              <span className={styles.head__date}>
                {data.package.EntregaDate !== '-'
                  ? data.package.EntregaDate
                  : ''}
              </span>
            </div>
          </div>
        </div>
        {data?.roadmap && data.roadmap?.length > 0 && (
          <>
            <div className={styles.divider}></div>
            <div className={styles.roadmap}>
              <h4 className={styles.roadmap__headline}>Mapa de ruta:</h4>
              <ul className={styles.roadmap__list}>
                {data.roadmap.slice(0, 3).map((roadmapItem, index) => (
                  <li
                    key={roadmapItem.CodEstado + '_' + index}
                    className={styles.roadmap__list__item}
                  >
                    <b></b>
                    <span>
                      <i>{roadmapItem.EstadoPaquete}</i>
                      <small>{roadmapItem.Fecha}</small>
                    </span>
                  </li>
                ))}
              </ul>
              <Link
                to={'/packages/roadmap/' + code}
                className={styles.roadmap__link}
              >
                <i>Detalles de la ruta</i>
                <Icon name='x0027' width={20} height={20} />
              </Link>
            </div>
          </>
        )}
        <div className={styles.divider}></div>
        <div className={styles.info}>
          <div className={styles.info__inner}>
            <h6>Entrega estimada:</h6>
            <p>
              {packageIsRescheduling ? (
                <span className={styles.info__inner__reschedule}>
                  <b>Reprogramando...</b>
                  <p>Contactar con el Centro de</p>
                  <Link to='/info/customer-support'> Atención al Cliente</Link>
                </span>
              ) : data.package.EntregaDate !== '-' ? (
                data.package.EntregaDate
              ) : (
                <span className={styles.info__inner__pending}>
                  Pendiente...
                </span>
              )}
            </p>
          </div>
          <div className={styles.info__inner}>
            <h6>Dirección de despacho:</h6>
            <p>
              {data.package.Address !== '-'
                ? data.package.Address
                : 'Aún no asignado'}
            </p>
          </div>
          <div className={styles.info__inner}>
            <h6>Cliente:</h6>
            <p>{data.package.Owner || 'Aprobatorio'}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <EmptyResults
      isEmpty={true}
      desktop={true}
      mobile={true}
      message='¡No se encontraron paquete!'
    />
  );
};
