import { FC, ReactElement, useEffect, useState } from 'react';
import { PlaceType } from '@interfaces/map.interface';
import { Map } from '@components/common/PickAddress/Map/Map';
import { Popup } from '@components/common/PickAddress/Popup/Popup';
import { Form } from '@components/common/PickAddress/Form/Form';

type PickAddressProps = {};

export const PickAddress: FC<PickAddressProps> = (): ReactElement => {
  const [detection, setDetection] = useState<boolean>(true);
  const [place, setPlace] = useState<PlaceType>();

  useEffect(() => {
    console.log('%cPickAddress', 'color: lightgreen', place);
  }, [place]);

  return (
    <>
      <Popup
        text='Escriba para buscar la dirección o arrastre un pin en el mapa.'
        dismiss={{
          text: '¡No volver a mostrar!',
          storage: {
            key: 'dismissMapAlertInfo',
            value: 'true',
          },
        }}
      />
      {!detection && (
        <Popup
          text='Habilite la detección de geolocalización.'
          delay={0}
          hasClose={false}
        />
      )}
      <Map center={place?.coords as any} onSelectPlace={setPlace} />
      <Form onCheckDetection={setDetection} onSelectPlace={setPlace} />
    </>
  );
};
