// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyResults_root__2M43m {
  width: 100%;
  height: 100%;
  text-align: center;
}
.EmptyResults_root__2M43m h6 {
  font-weight: var(--regular);
  font-size: 14px;
  color: var(--color-manatee);
}
.EmptyResults_root__mobile__AHRe8 h6 {
  font-size: 14px;
}
.EmptyResults_root__desktop__izYHe h6 {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/EmptyResults/EmptyResults.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAAF;AACE;EACE,2BAAA;EACA,eAAA;EACA,2BAAA;AACJ;AAEI;EACE,eAAA;AAAN;AAII;EACE,eAAA;AAFN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  h6 {\n    font-weight: var(--regular);\n    font-size: 14px;\n    color: var(--color-manatee);\n  }\n  &__mobile {\n    h6 {\n      font-size: 14px;\n    }\n  }\n  &__desktop {\n    h6 {\n      font-size: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `EmptyResults_root__2M43m`,
	"root__mobile": `EmptyResults_root__mobile__AHRe8`,
	"root__desktop": `EmptyResults_root__desktop__izYHe`
};
export default ___CSS_LOADER_EXPORT___;
