import { localStorageUtil, sessionStorageUtil } from '@libs/utils/storageUtils';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { removeTokens, setTokens } from '@store/features/auth/authSlice';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import { Mutex } from 'async-mutex';
import qs from 'qs';

const baseUrl = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SUFFIX}/auth`;

interface ITokensResponse {
  access: string;
  refresh: string;
}

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
});

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  const { dispatch } = api;
  const localAccessToken = localStorageUtil.getItem('accessToken');
  const sessionAccessToken = sessionStorageUtil.getItem('accessToken');

  const localRefreshToken = localStorageUtil.getItem('refreshToken');
  const sessionRefreshToken = sessionStorageUtil.getItem('refreshToken');

  const accessToken = localAccessToken || sessionAccessToken;
  const refreshToken = localRefreshToken || sessionRefreshToken;

  if (result.error) {
    const {
      error: { status },
    } = result;
    const resStatuses = [400, 401, 404];
    const isError = resStatuses.includes(status as number);

    if (isError && accessToken && refreshToken?.length > 0) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          const res: any = await baseQuery(
            {
              url: '/refresh/',
              method: 'POST',
              body: qs.stringify({ refresh_token: refreshToken }),
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `${accessToken}`,
              },
            },
            api,
            extraOptions
          );

          if (res?.data?.data?.access && res?.data?.data?.refresh) {
            dispatch(
              setTokens({
                access: res.data.data.access,
                refresh: res.data.data.refresh,
              })
            );

            if (localAccessToken) {
              localStorageUtil.setItem('accessToken', res.data.data.access);
              localStorageUtil.setItem('refreshToken', res.data.data.refresh);
            }

            if (sessionAccessToken) {
              sessionStorageUtil.setItem('accessToken', res.data.data.access);
              sessionStorageUtil.setItem('refreshToken', res.data.data.refresh);
            }

            await baseQuery(args, api, extraOptions);
            // window.location.reload();
          } else {
            localStorageUtil.removeItem('accessToken');
            localStorageUtil.removeItem('refreshToken');
            sessionStorageUtil.removeItem('accessToken');
            sessionStorageUtil.removeItem('refreshToken');

            dispatch(removeTokens());

            window.location.href = '/auth/login/';
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }

  return result;
};

export default customFetchBase;
