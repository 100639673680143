// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialNetworks_headline__segn7 {
  text-align: center;
  margin: 0 0 25px;
}
.SocialNetworks_headline__segn7 h6 {
  font-weight: var(--regular);
  font-size: 14px;
  color: var(--color-text);
  margin: 0;
}

.SocialNetworks_list__IQWgT {
  margin: 0 -8px;
}
.SocialNetworks_list__item__SDyz8 {
  width: 100%;
  height: 58px;
  padding: 0 8px;
}
.SocialNetworks_list__item__max_width__ZODG9 {
  width: 78px;
  margin: 0 10px;
  padding: 0;
}
.SocialNetworks_list__item__inner__8oucZ {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  border: 2px solid var(--color-mystic);
  border-radius: 12px;
}
.SocialNetworks_list__item__inner__8oucZ svg {
  max-height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/SocialNetworks/SocialNetworks.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,gBAAA;AAAF;AACE;EACE,2BAAA;EACA,eAAA;EACA,wBAAA;EACA,SAAA;AACJ;;AAGA;EACE,cAAA;AAAF;AACE;EACE,WAAA;EACA,YAAA;EACA,cAAA;AACJ;AAAI;EACE,WAAA;EACA,cAAA;EACA,UAAA;AAEN;AAAI;EACE,WAAA;EACA,YAAA;EACA,oCAAA;EACA,qCAAA;EACA,mBAAA;AAEN;AADM;EACE,gBAAA;AAGR","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.headline {\n  text-align: center;\n  margin: 0 0 25px;\n  h6 {\n    font-weight: var(--regular);\n    font-size: 14px;\n    color: var(--color-text);\n    margin: 0;\n  }\n}\n\n.list {\n  margin: 0 -8px;\n  &__item {\n    width: 100%;\n    height: 58px;\n    padding: 0 8px;\n    &__max_width {\n      width: 78px;\n      margin: 0 10px;\n      padding: 0;\n    }\n    &__inner {\n      width: 100%;\n      height: 100%;\n      background-color: var(--color-white);\n      border: 2px solid var(--color-mystic);\n      border-radius: 12px;\n      svg {\n        max-height: 32px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": `SocialNetworks_headline__segn7`,
	"list": `SocialNetworks_list__IQWgT`,
	"list__item": `SocialNetworks_list__item__SDyz8`,
	"list__item__max_width": `SocialNetworks_list__item__max_width__ZODG9`,
	"list__item__inner": `SocialNetworks_list__item__inner__8oucZ`
};
export default ___CSS_LOADER_EXPORT___;
