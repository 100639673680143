const prefix = 'savarx.pwa.';

export const useLocalStorage = () => {
  function setStorageItem(key: string, value: string) {
    localStorage.setItem(prefix + key, value);
  }

  function getStorageItem(key: string) {
    return localStorage.getItem(prefix + key);
  }

  function removeStorageItem(key: string) {
    localStorage.removeItem(prefix + key);
  }

  return {
    setStorageItem,
    getStorageItem,
    removeStorageItem,
  };
};
