import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import { RootState } from '@store/store';
import { IAddress } from './types';

const BASE_URL = process.env.REACT_APP_API_URL as string;
const uri = {
  list: {
    uri: '/',
    method: 'GET',
  },
  create: {
    uri: '/',
    method: 'POST',
  },
};

/**
 * Slice / Api
 */
export const addressesService = createApi({
  tagTypes: ['Addresses'],
  reducerPath: 'addressesService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/addresses`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    fetchAddresses: builder.query<IAddress[], any>({
      query: (args) => {
        return {
          url: uri.list.uri,
          method: uri.list.method,
        };
      },
    }),
    createAddress: builder.mutation<IAddress, any>({
      query: (args) => {
        return {
          url: uri.create.uri,
          method: uri.create.method,
          // body: {
          //   data: args,
          // },
        };
      },
    }),
  }),
});

export const { useFetchAddressesQuery, useCreateAddressMutation } =
  addressesService;

/**
 * Selectors
 */
// const selectSelf =
//   statusesService.endpoints.fetchStatuses.select(null);
const selectSelf = (state: RootState) => state;
