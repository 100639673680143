// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HttpLoader_root__CQUTu {
  pointer-events: none;
  opacity: 0;
}
.HttpLoader_root__active__AgMEy {
  pointer-events: all;
  opacity: 1;
}
.HttpLoader_root__inner__bWcHN {
  width: 98px;
  height: 98px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/HttpLoader/HttpLoader.module.scss"],"names":[],"mappings":"AACA;EACE,oBAAA;EACA,UAAA;AAAF;AAEE;EACE,mBAAA;EACA,UAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  pointer-events: none;\n  opacity: 0;\n\n  &__active {\n    pointer-events: all;\n    opacity: 1;\n  }\n\n  &__inner {\n    width: 98px;\n    height: 98px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `HttpLoader_root__CQUTu`,
	"root__active": `HttpLoader_root__active__AgMEy`,
	"root__inner": `HttpLoader_root__inner__bWcHN`
};
export default ___CSS_LOADER_EXPORT___;
