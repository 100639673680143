import styles from '@components/common/SideBar/Menu/Menu.module.scss';
import { Icon } from '@components/ui';
import { classNames } from '@libs/utils';
import { IMenuItem } from '@store/services/menus/types';
import { useAppSelector } from '@store/store';
import { FC, ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';

type MenuProps = {
  list: IMenuItem[];
};

export const Menu: FC<MenuProps> = ({ list }): ReactElement => {
  const { pathname } = useAppSelector((state) => state.router.location);
  const memoizedUri = useMemo((): string => {
    const pathParts = pathname.split('/');
    const lastPart = pathParts[1];
    return '/' + lastPart || '/';
  }, [pathname]);

  return list && list?.length > 0 ? (
    <ul className={styles.root}>
      {list.map(({ id, name, uri, icon_name }, index) => (
        <li
          key={id}
          className={classNames(
            styles.root__item,
            uri === memoizedUri ? styles.root__item__active : ''
          )}
        >
          <Link
            to={uri}
            className={classNames(
              'fx-align-center',
              'fx-nowrap',
              styles.root__item__inner
            )}
          >
            {icon_name && (
              <Icon
                name={icon_name}
                width={24}
                height={24}
                color={uri === memoizedUri ? 'primary' : 'grey'}
              />
            )}
            <span
              className={classNames(
                styles.root__item__text,
                uri === memoizedUri ? styles.root__item__text__active : ''
              )}
            >
              {name}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );
};
