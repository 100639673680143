import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { IUser } from '../api/types';

export interface IMetaState {
  pageTitle: string;
}

const initialState: IMetaState = {
  pageTitle: '',
};

export const metaSlice = createSlice({
  initialState,
  name: 'metaSlice',
  reducers: {
    setPageTitle: (state, action: PayloadAction<string>) => {
      state.pageTitle = action.payload;
    },
  },
});

export default metaSlice.reducer;

export const { setPageTitle } = metaSlice.actions;
