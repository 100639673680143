// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditPackage_root__x9bZv {
  padding: 0 15px;
}
.EditPackage_root__inner__1agpA {
  width: 100%;
  padding: 30px 20px;
}
.EditPackage_root__headline__0GxnH {
  width: 100%;
  text-align: left;
  margin: 0 0 15px;
}
.EditPackage_root__headline__0GxnH h4 {
  font-size: 24px;
  color: var(--color-mirage);
}
.EditPackage_root__headline__0GxnH h4 span {
  color: var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/EditPackage/EditPackage.module.scss"],"names":[],"mappings":"AACA;EACE,eAAA;AAAF;AACE;EACE,WAAA;EACA,kBAAA;AACJ;AACE;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAAI;EACE,eAAA;EACA,0BAAA;AAEN;AADM;EACE,2BAAA;AAGR","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  padding: 0 15px;\n  &__inner {\n    width: 100%;\n    padding: 30px 20px;\n  }\n  &__headline {\n    width: 100%;\n    text-align: left;\n    margin: 0 0 15px;\n    h4 {\n      font-size: 24px;\n      color: var(--color-mirage);\n      span {\n        color: var(--color-primary);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `EditPackage_root__x9bZv`,
	"root__inner": `EditPackage_root__inner__1agpA`,
	"root__headline": `EditPackage_root__headline__0GxnH`
};
export default ___CSS_LOADER_EXPORT___;
