import styles from '@components/common/FaqGroup/FaqGroup.module.scss';
import { FaqItem } from '@components/common/FaqGroup/FaqItem/FaqItem';
import { IFaqItem } from '@store/services/faqs/types';
import { FC, ReactElement } from 'react';

type FaqGroupProps = {
  list: IFaqItem[] | undefined;
};

export const FaqGroup: FC<FaqGroupProps> = ({ list }): ReactElement => (
  <div className={styles.root}>
    {list && list?.length > 0 ? (
      list.map(({ id, question, answer }, index) => (
        <FaqItem key={id} name={question} text={answer} />
      ))
    ) : (
      <></>
    )}
  </div>
);
