import {
  createRouterMiddleware,
  ReduxRouterSelector,
} from '@lagunovsky/redux-react-router';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { store } from '@store/store';
import { createBrowserHistory } from 'history';

type State = ReturnType<typeof store.getState>;

export const routerHistory = createBrowserHistory();
export const routerMiddleware = createRouterMiddleware(routerHistory);
export const routerSelector: ReduxRouterSelector<State> = (state) =>
  state.router;

const selectSelf = (state: State) => state;
export const selectRouterPathname = createDraftSafeSelector(
  selectSelf,
  (state) => state.router.location
);
