import { FC, ReactElement } from 'react';
import Svg from 'react-inlinesvg';
import { classNames } from '@libs/utils';
import styles from '@components/ui/Icon/Icon.module.scss';

type IconProps = {
  name: string;
  width?: number;
  height?: number;
  unit?: 'px' | 'percentage';
  classList?: string;
  style?: {};
  color?: string;
  onClick?: () => void;
};

export const Icon: FC<IconProps> = ({
  name,
  width = 24,
  height = 24,
  unit = 'px',
  classList = '',
  style = {},
  color = '',
  onClick,
}): ReactElement => (
  <span
    className={classNames(classList, styles.root)}
    style={{
      ...style,
      ...{
        width: width ? `${width}${unit}` : 'auto',
        height: height ? `${height}${unit}` : 'auto',
      },
    }}
    onClick={onClick}
  >
    <Svg
      className={classNames(
        styles.root__inner,
        styles[`root__inner__${color}`]
      )}
      src={`/assets/icons/icon-${name}.svg`}
    />
  </span>
);
