import styles from '@components/common/AddressBook/Map/Map.module.scss';
import { useNavigator } from '@hooks/useNavigator';
import { PlaceType } from '@interfaces/map.interface';
import { theme } from '@libs/map/map';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { FC, ReactElement, useCallback, useState } from 'react';

type MapProps = {
  center?: google.maps.LatLng;
  zoom?: number;
  onSelectPlace: (place: PlaceType) => void;
};

export const Map: FC<MapProps> = ({
  center = { lat: -12.046373, lng: -77.042755 },
  zoom = 12,
  onSelectPlace,
}): ReactElement => {
  const [map, setMap] = useState<google.maps.Map | null>();
  const { getAddress, setFieldValue } = useNavigator();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC2dSaHMoRmFncykyFghoLozdWO_MNq1wM',
    region: 'PE',
    language: 'es_PE',
    libraries: ['places'],
  });

  // const onLoad = useCallback((map: any) => {
  //   map.fitBounds(new window.google.maps.LatLngBounds(center));
  //   setMap(map);
  // }, []);

  const onUnmount = useCallback((map: any) => {
    setMap(null);
  }, []);

  async function onDragEnd(event: google.maps.MapMouseEvent) {
    const { latLng } = event;
    const place = {
      address: '',
      coords: {
        lat: latLng?.lat(),
        lng: latLng?.lng(),
      },
    };

    const address = await getAddress(
      place.coords.lat as number,
      place.coords.lng as number
    );

    if (address && address?.length > 0) {
      place.address = address;

      onSelectPlace(place);
      setFieldValue(address);
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.root__inner}>
        {isLoaded && (
          <GoogleMap
            options={{
              disableDefaultUI: true,
              styles: theme,
            }}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={center}
            zoom={zoom}
            // onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <MarkerF
              icon='/assets/icons/icon-x0015.svg'
              position={center}
              draggable={true}
              onDragEnd={onDragEnd}
            />
          </GoogleMap>
        )}
      </div>
    </div>
  );
};
