import {
  createRouterReducer,
  ReduxRouterSelector,
} from '@lagunovsky/redux-react-router';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authSlice from '@store/features/auth/authSlice';
import metaSlice from '@store/features/meta/meta.slice';
import packagesSlice from '@store/features/packages/packages.slice';
import { routerHistory, routerMiddleware } from '@store/features/router';
import { addressesService } from '@store/services/addresses/addressesService';
import { authService } from '@store/services/auth/authService';
import { categoriesService } from '@store/services/categories/categoriesService';
import { faqsService } from '@store/services/faqs/faqsService';
import { formsService } from '@store/services/forms/formsService';
import { menusService } from '@store/services/menus/menusService';
import { otpService } from '@store/services/otp/otpService';
import { packagesService } from '@store/services/packages/packagesService';
import { pagesService } from '@store/services/pages/pagesService';
import { settingsService } from '@store/services/settings/settingsService';
import { statusesService } from '@store/services/statuses/statusesService';
import { usersService } from '@store/services/users/usersService';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [usersService.reducerPath]: usersService.reducer,
    [statusesService.reducerPath]: statusesService.reducer,
    [categoriesService.reducerPath]: categoriesService.reducer,
    [packagesService.reducerPath]: packagesService.reducer,
    [addressesService.reducerPath]: addressesService.reducer,
    [menusService.reducerPath]: menusService.reducer,
    [pagesService.reducerPath]: pagesService.reducer,
    [faqsService.reducerPath]: faqsService.reducer,
    [formsService.reducerPath]: formsService.reducer,
    [otpService.reducerPath]: otpService.reducer,
    [settingsService.reducerPath]: settingsService.reducer,
    router: createRouterReducer(routerHistory),
    auth: authSlice,
    packages: packagesSlice,
    meta: metaSlice,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({})
      .prepend(routerMiddleware)
      .concat([
        authService.middleware,
        usersService.middleware,
        statusesService.middleware,
        categoriesService.middleware,
        packagesService.middleware,
        addressesService.middleware,
        menusService.middleware,
        pagesService.middleware,
        faqsService.middleware,
        formsService.middleware,
        otpService.middleware,
        settingsService.middleware,
      ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useRouterSelector: ReduxRouterSelector<RootState> = (state) =>
  state.router;

setupListeners(store.dispatch);
