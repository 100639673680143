import { useFetchCategoriesQuery } from '@store/services/categories/categoriesService';
import { useAppDispatch, useAppSelector } from '@store/store';
import { useEffect, useMemo } from 'react';

export const usePackageCategorySlug = () => {
  const { data, isLoading, isSuccess, isError } = useFetchCategoriesQuery({
    refetchOnMountOrArgChange: true,
  });
  const dispatch = useAppDispatch();
  const { pathname } = useAppSelector((state) => state.router.location);
  const routerSlug = useMemo(() => {
    const arrayPathname = pathname.split('/').filter((item) => item?.length);
    const slug = arrayPathname[arrayPathname.length - 1] || undefined;
    return slug !== 'packages' ? slug : 'all';
  }, [pathname]);

  useEffect(() => {
    // const category = data?.find((category) => category.slug == routerSlug);
    // if (category) {
    //   dispatch(
    //     setPackagesCategory({
    //       name: category.name,
    //       slug: category.slug,
    //     })
    //   );
    // }
  }, [routerSlug, isLoading, isSuccess, isError]);

  return { slug: routerSlug, data, isLoading, isSuccess, isError };
};
