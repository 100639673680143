import styles from '@components/common/FormFeedbackPopup/FormFeedbackPopup.module.scss';
import { Icon, Portal } from '@components/ui';
import { classNames } from '@libs/utils';
import { motion } from 'framer-motion';
import { FC, ReactElement, useEffect, useRef } from 'react';

type FormFeedbackPopupProps = {
  children: ReactElement | ReactElement[];
  isOpened: boolean;
  onClose: () => void;
};

const animations = {
  overlay: {
    initial: { opacity: 0 },
    in: { opacity: 1 },
    out: { opacity: 0 },
  },
  content: {
    initial: { opacity: 0, scale: 0.8 },
    in: { opacity: 1, scale: 1 },
    out: { opacity: 0, scale: 0.8 },
  },
};

export const FormFeedbackPopup: FC<FormFeedbackPopupProps> = ({
  children,
  isOpened,
  onClose,
}): ReactElement => {
  const nodeRef = useRef(null);

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === 'Escape' ? onClose() : null;
    document.body.addEventListener('keydown', closeOnEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onClose]);

  return (
    <Portal wrapperId='react-portal-modal-container'>
      <motion.div
        ref={nodeRef}
        unselectable='on'
        className={classNames(styles.root, isOpened ? styles.root__active : '')}
      >
        <motion.div
          initial={animations.overlay.initial}
          animate={isOpened ? animations.overlay.in : animations.overlay.out}
          transition={{ duration: 0.2 }}
          className={styles.overlay}
        />
        <motion.div
          initial={animations.content.initial}
          animate={isOpened ? animations.content.in : animations.content.out}
          transition={{ duration: 0.2 }}
          className={styles.content}
        >
          <button onClick={onClose} className={styles.close}>
            <Icon name='x0033' width={24} height={24} />
          </button>
          <div>{children}</div>
        </motion.div>
      </motion.div>
    </Portal>
  );
};
