// import styles from '@components/common/TopBar/Tabs/Tabs.module.scss';
import styles from '@components/common/TopBar/Tabs/Tabs.module.scss';
import { usePackageCategorySlug } from '@hooks/usePackageCategorySlug';
import { classNames } from '@libs/utils';
import { selectSelectedCategory } from '@store/features/packages/packages.slice';
import { useFetchCategoriesQuery } from '@store/services/categories/categoriesService';
import { useAppSelector } from '@store/store';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

type TabsPackagesProps = {};

export const TabsPackages: FC<TabsPackagesProps> = (): ReactElement => {
  usePackageCategorySlug();
  const selectedCategory = useAppSelector(selectSelectedCategory);
  const { data, isLoading, isSuccess } = useFetchCategoriesQuery({
    refetchOnMountOrArgChange: true,
  });

  return (
    <ul className={classNames('fx-align-center', styles.root)}>
      {!isLoading &&
        isSuccess &&
        data.map(
          (category) =>
            category && (
              <li key={category.id} className={styles.root__item}>
                <Link
                  to={
                    category.slug !== 'all'
                      ? `/packages/category/${category.slug}`
                      : '/packages'
                  }
                  className={classNames(
                    styles.root__item__element,
                    selectedCategory.code === category.code
                      ? styles.root__item__element__active
                      : ''
                  )}
                >
                  {category.name}
                </Link>
              </li>
            )
        )}
    </ul>
  );
};
