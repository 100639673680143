import styles from '@components/common/TopBar/Headline/Headline.module.scss';
import { FC, ReactElement } from 'react';

type HeadlineProps = {
  headline?: string;
  description?: string;
};

export const Headline: FC<HeadlineProps> = ({
  headline,
  description,
}): ReactElement => {
  return headline || description ? (
    <div className={styles.root}>
      {headline && headline?.length > 0 && <h2>{headline}</h2>}
      {description && description?.length > 0 && <p>{description}</p>}
    </div>
  ) : (
    <></>
  );
};
