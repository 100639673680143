// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BottomHelpBar_root__-zmaB {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  padding: 5px 30px 5px 260px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1030;
}
.BottomHelpBar_root__-zmaB p {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/BottomHelpBar/BottomHelpBar.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,oCAAA;EACA,6BAAA;EACA,2BAAA;EACA,eAAA;EACA,OAAA;EACA,SAAA;EACA,aAAA;AAAF;AAEE;EACE,kBAAA;AAAJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  background-color: var(--color-white);\n  box-shadow: var(--box-shadow);\n  padding: 5px 30px 5px 260px;\n  position: fixed;\n  left: 0;\n  bottom: 0;\n  z-index: 1030;\n\n  p {\n    margin-right: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `BottomHelpBar_root__-zmaB`
};
export default ___CSS_LOADER_EXPORT___;
