import { FC, ReactElement, SVGProps } from 'react';
import { Link } from 'react-router-dom';

export const LogoBrand: FC<SVGProps<SVGSVGElement>> = (props): ReactElement => {
  return (
    <div
      className='fx-content-center'
      style={{ width: '100%', padding: '20px 30px 0 30px' }}
    >
      <Link to='/' style={{ display: 'inline-block' }}>
        <svg
          width={112}
          height={30}
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path
            d='M27.506 13.472h4.973c.658 0 .974-.187.974-.666 0-.267-.158-.587-.316-.72L29.401 9.1c-1.158-.933-1.816-2.345-1.816-3.917 0-2.932 2.29-4.877 6.473-4.877h5.5V5.61h-4.5c-.605 0-.92.133-.92.48 0 .32.21.506.552.8l2.526 1.998c1.736 1.386 3.026 2.425 3.026 4.85 0 3.145-2.447 5.197-6.736 5.197h-6v-5.463zM53.015 18.948h5.92L55.62 4.264C54.962 1.36 53.699 0 51.067 0c-2.657 0-3.92 1.333-4.578 4.264l-3.342 14.684h5.947l.537-3.172h2.836l.548 3.172zm-2.658-7.462c.053-.373.316-1.945.71-4.664.343 2.532.553 4.105.658 4.664h-1.368zM72.459 14.99c-.658 2.932-1.92 4.265-4.578 4.265-2.632 0-3.895-1.36-4.552-4.264L60.013.307h5.92l1.29 7.462c.105.56.316 2.132.658 4.663.395-2.718.658-4.29.71-4.663L69.854.307h5.947L72.459 14.99zM86.743 18.948h5.92L89.348 4.264C88.69 1.36 87.428 0 84.796 0c-2.658 0-3.92 1.333-4.579 4.264l-3.341 14.684h5.946l.537-3.172h2.836l.548 3.172zm-2.658-7.462c.053-.373.316-1.945.71-4.664.343 2.532.553 4.105.659 4.664h-1.369zM108.078 11.252a6.192 6.192 0 002.083-4.648c0-3.41-2.728-6.172-6.094-6.172h-1.608c-1.211.002-6.283.044-6.283.044v18.428h5.462V5.144l.665.001h.002c1.439 0 2.605 1.182 2.605 2.638 0 .959-.504 1.798-1.259 2.26-.088.054-.541.32-.611.392a1.17 1.17 0 00-.218 1.322l.045.1 3.244 6.985.096.062h5.784l.009-.016-3.942-7.634'
            fill='#5376F1'
          />
          <path
            fill='url(#prefix__paint0_linear_711_5023)'
            d='M27.378 20.361h44.799v1.209H27.378z'
          />
          <path
            d='M72.14 25.7h-3.36c-.794 0-1.135-.15-1.066-.613.013-.082.04-.177.083-.286h3.887c.941 0 1.372-.545 1.697-1.362.15-.381.26-.844.313-1.198.006-.04.008-.075.012-.114l-.557-1.456c-.514-.323-1.362-.418-2.52-.418-3.092 0-4.007.272-5.158 3.377-.271.735-.38 1.198-.456 1.702-.233 1.552.462 2.22 2.183 2.22h2.863l.28-.25 1.799-1.603zm-2.177-3.595c.376 0 1.07.04 1.006.463-.004.027-.022.15-.044.204-.065.163-.145.34-.575.34H68.4c.323-.898.756-1.007 1.563-1.007z'
            fill='#5376F1'
          />
          <path
            d='M78.548 20.335l-1.823 1.852-.49-1.852H73.37l.045.117.128.335.332.866.165.433.674 1.762-2.156 1.92-.258.23-.257.23-1.487 1.324H73.743l2.054-2.043.582 2.043h1.478l.683-1.9-.733-1.804 2.045-1.854.099-.276c.234-.648.58-1.103 1.039-1.383h-2.442z'
            fill='#5376F1'
          />
          <path
            d='M87.3 21.613c-.25-.786-.927-1.278-2.146-1.278h-2.877c-.037 0-.07.006-.106.007-.09.003-.181.006-.266.018-.114.015-.221.04-.323.07-.545.16-.918.528-1.178 1.067-.05.106-.097.218-.14.336l-.008.022-.12.332-1.24 3.457-.083.232-.084.233-.517 1.443-.06.166-.06.167-.711 1.982h2.623l.83-2.315h1.99c.966 0 1.702-.164 2.285-.486l1.385-3.849c.235-.656.502-1.182.805-1.604zm-2.67 1.514c-.037.245-.14.667-.237.952-.252.695-.732 1.567-1.66 1.567h-1.21l.985-2.724c.19-.545.371-.68.909-.68h.484c.672 0 .803.38.728.884zM92.95 20.335h-1.575c-1.738 0-2.885.285-3.72 1.352-.044.055-.087.109-.129.169-.047.067-.092.14-.138.212a6.69 6.69 0 00-.59 1.262l-1.23 3.417-.106.296-.088.244-.095.265h2.637l1.24-3.432c.405-1.134 1.001-1.795 2.047-1.887.502-1 1.038-1.575 1.746-1.898z'
            fill='#5376F1'
          />
          <path
            d='M97.978 22.695c.026-.18.078-.375.168-.635.185-.518.41-.926.692-1.239-.478-.445-1.396-.568-2.717-.568-1.182 0-2.045.041-2.726.264a3.04 3.04 0 00-.41.172c-.566.286-1.001.755-1.408 1.525-.028.053-.055.11-.083.167-.028.056-.056.11-.084.169-.149.316-.296.672-.447 1.08-.272.735-.38 1.198-.456 1.702-.233 1.553.46 2.22 2.182 2.22h3.603l.04-.114.624-1.738H94.27c-.792 0-1.133-.15-1.064-.613.012-.081.04-.177.083-.286h3.887c.59 0 .98-.215 1.268-.576-.363-.335-.575-.804-.466-1.53zm-1.561.077c-.065.164-.145.34-.576.34h-1.95c.323-.898.757-1.007 1.563-1.007.377 0 1.07.04 1.007.463-.004.027-.023.15-.044.204z'
            fill='#5376F1'
          />
          <path
            d='M101.359 22.187h2.824a7.44 7.44 0 01.041-.127c.304-.852.717-1.408 1.308-1.725h-4.501c-.745 0-1.305.147-1.739.509a2.128 2.128 0 00-.243.24c-.235.274-.429.63-.593 1.089-.07.204-.126.395-.152.572-.082.548.042.914.329 1.198a1.529 1.529 0 00.262.212c.176.115.385.22.63.32l1.228.503c.28.11.784.245.749.477-.02.136-.185.164-.279.164h-3.887l-.03.08-.663 1.853h4.962c.292 0 .552-.02.789-.065l.017-.048.693-1.934.079-.22h1.11c.004-.024.012-.05.016-.075.161-1.076-.437-1.484-1.423-1.907l-1.455-.612c-.379-.163-.581-.163-.55-.368.021-.136.37-.136.478-.136z'
            fill='#5376F1'
          />
          <path
            d='M107.437 22.187h3.873l.667-1.852h-4.868c-.333 0-.629.03-.893.094a2.442 2.442 0 00-.399.136c-.581.26-.982.764-1.283 1.608a.069.069 0 00-.004.014c-.02.057-.035.111-.052.166a2.97 2.97 0 00-.048.167c-.019.076-.038.152-.049.225-.142.953.321 1.362 1.221 1.73l1.229.503c.28.11.784.245.749.477-.02.136-.185.164-.279.164h-3.887l-.633 1.766-.06.167H107.683c1.345 0 2.065-.408 2.551-1.77a3.2 3.2 0 00.153-.572c.161-1.076-.437-1.484-1.423-1.907l-1.454-.612c-.379-.163-.581-.163-.551-.368.021-.136.37-.136.478-.136zM6.502 27.67l.085-.001h-.17l.085.002zM8.454 27.67l.085-.001h-.17l.085.002zM3.146 3.765c-.655.559-1.257 1.14-1.755 1.777l-.074.098-.1.134c-.143.202-.275.41-.403.62A5.896 5.896 0 000 9.42c0 1.994.596 3.842 1.681 5.343a8.507 8.507 0 001.15 1.294c.07.065.162.103.256.111.032.003.064.004.094.004.33 0 .578-.158.714-.378l-.027-.031-.074-.083-.031-.036a8.884 8.884 0 01-.555-.685c-1.141-1.578-1.745-3.494-1.745-5.54 0-1.151.281-2.196.863-3.2.145-.24.281-.449.419-.643l.1-.136.05-.065.03-.04c.463-.593 1.036-1.173 1.801-1.826a14.262 14.262 0 011.597-1.189A13.6 13.6 0 019.973.828a13.515 13.515 0 00-5.275 1.78c-.56.358-1.073.748-1.552 1.157z'
            fill='#5376F1'
          />
          <path
            d='M11.101.929a13.408 13.408 0 00-4.615 1.68c-.559.357-1.073.748-1.551 1.156-.656.56-1.257 1.14-1.756 1.777-.024.033-.05.065-.073.098l-.1.135c-.143.202-.276.41-.403.62a5.893 5.893 0 00-.814 3.026c0 1.995.596 3.843 1.68 5.344.167.229.347.447.535.658l.1.115.088.098.002.002c.025.027.053.051.078.077.109.112.218.223.332.33a.483.483 0 00.366.129c.373 0 .64-.205.76-.472l-.046-.051-.024-.027-.041-.047a8.9 8.9 0 01-.494-.616 9.047 9.047 0 01-1.1-2.031l-.012-.032-.047-.123a8.034 8.034 0 01-.046-.129l-.006-.017a9.724 9.724 0 01-.533-3.208c0-1.151.28-2.196.862-3.2a10.392 10.392 0 01.52-.78l.049-.065.03-.04c.464-.592 1.036-1.172 1.801-1.825A14.263 14.263 0 018.24 2.32 13.669 13.669 0 0111.973.81c-.294.031-.585.07-.872.12z'
            fill='#5376F1'
          />
          <path
            d='M21.217 1.937A13.888 13.888 0 0016.186.74a14.94 14.94 0 00-.646-.02h-.168a14.267 14.267 0 00-.691.023c-.059.003-.119.005-.177.009l-.044.002-.168.014-.175.015-.104.01a14.391 14.391 0 00-1.007.139c-1.681.29-3.239.868-4.594 1.677A14.06 14.06 0 006.86 3.765c-.656.56-1.257 1.141-1.755 1.778l-.074.097-.1.135c-.143.202-.276.41-.403.62a5.896 5.896 0 00-.814 3.027 9.41 9.41 0 00.565 3.238c.018.05.038.1.057.149a8.69 8.69 0 001.059 1.956c.125.174.26.341.397.505l.119.133c.034.04.068.079.103.117.03.032.058.065.087.098.005.006.01.013.017.019.114.12.232.238.353.353a.66.66 0 00.491.182c.56-.038.867-.548.733-1.001a3.61 3.61 0 01-.138-.78c-.006-.081-.013-.162-.013-.244 0-.555.13-1.079.353-1.548a3.543 3.543 0 012.162-1.872 3.473 3.473 0 012.591.229.652.652 0 00.295.071.6.6 0 00.396-.146l8.136-7.045c.623-.54.488-1.56-.26-1.899zM14.516.749L14.44.744l.032.007.044-.002zM19 24.522c.655-.559 1.256-1.14 1.755-1.777.024-.033.05-.065.073-.098l.1-.134c.143-.202.276-.41.403-.62.507-.875.814-1.86.814-3.027 0-1.994-.596-3.843-1.68-5.344a8.533 8.533 0 00-1.15-1.294.435.435 0 00-.257-.11 1.174 1.174 0 00-.094-.005.814.814 0 00-.713.379l.027.031.073.082.032.037c.218.245.399.469.555.684 1.141 1.579 1.744 3.494 1.744 5.54a6.219 6.219 0 01-.862 3.2c-.145.24-.282.45-.42.643l-.1.136-.05.066-.03.04c-.463.592-1.035 1.172-1.8 1.825-.53.451-1.05.839-1.598 1.189a13.592 13.592 0 01-3.65 1.493 13.519 13.519 0 005.276-1.78c.56-.357 1.073-.747 1.552-1.156z'
            fill='#5376F1'
          />
          <path
            d='M11.048 27.358a13.416 13.416 0 004.615-1.68c.56-.357 1.073-.747 1.552-1.156.655-.56 1.257-1.14 1.755-1.777l.074-.098.099-.134c.143-.203.276-.41.404-.62.506-.875.814-1.86.814-3.027 0-1.994-.596-3.843-1.681-5.344a8.51 8.51 0 00-.534-.658l-.101-.115-.088-.098s0-.002-.002-.002c-.025-.027-.052-.05-.077-.077a9.028 9.028 0 00-.332-.329.483.483 0 00-.293-.127 1.135 1.135 0 00-.073-.003.802.802 0 00-.76.472l.046.051.023.027.042.046a8.758 8.758 0 01.494.617c.448.62.818 1.303 1.1 2.031l.012.032.047.123.046.129.006.017a9.736 9.736 0 01.533 3.208 6.22 6.22 0 01-.862 3.2c-.145.24-.282.45-.42.644l-.1.135a9.368 9.368 0 01-.049.066l-.03.04c-.464.592-1.036 1.172-1.801 1.825-.53.452-1.05.839-1.597 1.189a13.68 13.68 0 01-3.733 1.514c.294-.032.584-.072.871-.12z'
            fill='#5376F1'
          />
          <path
            d='M.935 26.35a13.89 13.89 0 005.03 1.198c.215.01.43.019.647.02h.167a29.591 29.591 0 00.691-.023c.06-.003.119-.005.178-.009l.044-.002.167-.015.176-.014.103-.01a13.236 13.236 0 001.008-.139 13.42 13.42 0 004.594-1.677c.56-.357 1.073-.748 1.552-1.156.655-.56 1.257-1.141 1.755-1.778l.074-.098.1-.134c.142-.202.276-.41.403-.62.506-.875.814-1.86.814-3.027 0-1.087-.179-2.13-.515-3.097l-.05-.141c-.018-.05-.038-.1-.057-.149a8.696 8.696 0 00-1.06-1.957 8.564 8.564 0 00-.397-.504c-.038-.045-.078-.09-.118-.134a6.33 6.33 0 00-.104-.116l-.086-.098c-.005-.006-.01-.013-.017-.019a9.057 9.057 0 00-.353-.353.661.661 0 00-.491-.182c-.561.037-.867.547-.733 1 .073.25.12.511.138.781.006.08.012.162.012.244 0 .555-.128 1.079-.352 1.548a3.544 3.544 0 01-2.163 1.872c-.32.097-.66.15-1.01.15a3.485 3.485 0 01-1.58-.38.653.653 0 00-.296-.07.6.6 0 00-.395.146L.675 24.452c-.623.54-.488 1.56.26 1.898z'
            fill='#5376F1'
          />
          <defs>
            <linearGradient
              id='prefix__paint0_linear_711_5023'
              x1={72.177}
              y1={20.741}
              x2={27.793}
              y2={20.741}
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#5376F1' />
              <stop offset={1} stopColor='#5376F1' stopOpacity={0} />
            </linearGradient>
          </defs>
        </svg>
      </Link>
    </div>
  );
};
