import styles from '@components/common/InstallPrompt/InstallPrompt.module.scss';
import { Button, Icon } from '@components/ui';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { classNames, delayFn } from '@libs/utils';
import { FC, ReactElement, useEffect, useState } from 'react';
import { usePWAInstall } from 'react-use-pwa-install';

type InstallPromptProps = {};

export const InstallPrompt: FC<InstallPromptProps> = (): ReactElement => {
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const { setStorageItem, getStorageItem } = useLocalStorage();
  const install = usePWAInstall();

  useEffect(() => {
    install !== null && delayFn(() => setShowPrompt(true), 500);
  }, [install]);

  useEffect(() => {
    const storagePrompt = getStorageItem('installPrompt');

    setHasMounted(storagePrompt !== 'false');
  }, [install]);

  function onInstall() {
    if (install !== null) {
      install();
      onClose(true);
    }
  }

  function onClose(forever: boolean, event?: MouseEvent) {
    typeof event !== 'undefined' && event.preventDefault();

    setShowPrompt(false);

    if (forever) {
      setStorageItem('installPrompt', 'false');
      setTimeout(() => setHasMounted(false), 300);
    }
  }

  return hasMounted && install !== null ? (
    <div
      className={classNames(
        'fx-align-center',
        'fx-content-center',
        styles.root,
        showPrompt ? styles.root__active : ''
      )}
    >
      <div
        className={classNames(
          styles.root__overlay,
          showPrompt ? styles.root__overlay__active : ''
        )}
      />
      <div
        className={classNames(
          styles.root__content,
          showPrompt ? styles.root__content__active : ''
        )}
      >
        <button
          type='button'
          className={styles.root__content__close}
          onClick={() => onClose(false)}
        >
          <Icon name='x0030' width={12} height={12} />
        </button>
        <div className={styles.root__content__text}>
          <h3>Savar Express</h3>
          <p>
            Para instalar la aplicación Savar Express, haga clic en el botón
            "Instalar".
          </p>
        </div>
        <div className={styles.root__actions}>
          <Button type='button' text='Instalar' onClick={onInstall} />
          <span
            className={styles.root__actions__dismis}
            onClick={(e: any) => onClose(true, e)}
          >
            ¡No volver a mostrar!
          </span>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
