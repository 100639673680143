// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListMenu_root__zggy7 {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.ListMenu_root__item__DRplO {
  width: 100%;
  position: relative;
}
.ListMenu_root__item__DRplO:last-child:after {
  display: none;
}
.ListMenu_root__item__inner__Yst5K {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
}
.ListMenu_root__item__DRplO:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--color-mystic);
  position: absolute;
  top: 100%;
  left: 0;
}
.ListMenu_root__item__text__DaZmi {
  width: calc(100% - 48px);
  font-size: 16px;
  color: var(--color-mirage);
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/ListMenu/ListMenu.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,oCAAA;EACA,6BAAA;EACA,mCAAA;AAAF;AACE;EACE,WAAA;EACA,kBAAA;AACJ;AACM;EACE,aAAA;AACR;AAEI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,eAAA;AAAN;AAEI;EACE,WAAA;EACA,cAAA;EACA,WAAA;EACA,WAAA;EACA,qCAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;AAAN;AAEI;EACE,wBAAA;EACA,eAAA;EACA,0BAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;EACA,gBAAA;AAAN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  background-color: var(--color-white);\n  box-shadow: var(--box-shadow);\n  border-radius: var(--border-radius);\n  &__item {\n    width: 100%;\n    position: relative;\n    &:last-child {\n      &:after {\n        display: none;\n      }\n    }\n    &__inner {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      padding: 15px;\n      cursor: pointer;\n    }\n    &:after {\n      content: '';\n      display: block;\n      width: 100%;\n      height: 1px;\n      background-color: var(--color-mystic);\n      position: absolute;\n      top: 100%;\n      left: 0;\n    }\n    &__text {\n      width: calc(100% - 48px);\n      font-size: 16px;\n      color: var(--color-mirage);\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      pointer-events: none;\n      overflow: hidden;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ListMenu_root__zggy7`,
	"root__item": `ListMenu_root__item__DRplO`,
	"root__item__inner": `ListMenu_root__item__inner__Yst5K`,
	"root__item__text": `ListMenu_root__item__text__DaZmi`
};
export default ___CSS_LOADER_EXPORT___;
