// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Icon_root__IQddD {
  display: inline-block;
}
.Icon_root__inner__AIopS {
  width: 100%;
  height: auto;
}
.Icon_root__inner__error__PG-Yf [fill*="#"] {
  stroke: var(--color-error);
}
.Icon_root__inner__error__PG-Yf [stroke*="#"] {
  stroke: var(--color-error);
}
.Icon_root__inner__primary__vyVcX [fill*="#"] {
  stroke: var(--color-primary);
}
.Icon_root__inner__primary__vyVcX [stroke*="#"] {
  stroke: var(--color-primary);
}
.Icon_root__inner__AIopS * {
  transition: var(--def-transition-300ms);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/ui/Icon/Icon.module.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;AAAF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AACM;EACE,0BAAA;AACR;AACM;EACE,0BAAA;AACR;AAGM;EACE,4BAAA;AADR;AAGM;EACE,4BAAA;AADR;AAII;EACE,uCAAA;AAFN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  display: inline-block;\n  &__inner {\n    width: 100%;\n    height: auto;\n    &__error {\n      [fill*='#'] {\n        stroke: var(--color-error);\n      }\n      [stroke*='#'] {\n        stroke: var(--color-error);\n      }\n    }\n    &__primary {\n      [fill*='#'] {\n        stroke: var(--color-primary);\n      }\n      [stroke*='#'] {\n        stroke: var(--color-primary);\n      }\n    }\n    * {\n      transition: var(--def-transition-300ms);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Icon_root__IQddD`,
	"root__inner": `Icon_root__inner__AIopS`,
	"root__inner__error": `Icon_root__inner__error__PG-Yf`,
	"root__inner__primary": `Icon_root__inner__primary__vyVcX`
};
export default ___CSS_LOADER_EXPORT___;
