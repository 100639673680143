import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '@store/services/auth/types';
import { RootState } from '@store/store';

export interface ITokens {
  access: string | undefined;
  refresh: string | undefined;
}

export interface IUserState {
  tokensExist: boolean;
  tokens: ITokens;
  profile: IUser | null;
}

const initialState: IUserState = {
  tokensExist: false,
  tokens: {
    access: undefined,
    refresh: undefined,
  },
  profile: null,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    logout: () => initialState,
    setTokens: (state, action: PayloadAction<ITokens>) => {
      state.tokensExist = true;
      state.tokens.access = action.payload.access;
      state.tokens.refresh = action.payload.refresh;
    },
    setAccessToken: (state, action: PayloadAction<{ access: string }>) => {
      state.tokens.access = action.payload.access;
    },
    setRefreshToken: (state, action: PayloadAction<{ refresh: string }>) => {
      state.tokens.refresh = action.payload.refresh;
    },
    removeTokens: (state) => {
      state.tokensExist = false;
      state.tokens.access = undefined;
      state.tokens.refresh = undefined;
    },
    setAuthProfile: (state, action: PayloadAction<IUser | null>) => {
      state.profile = action.payload;
    },
  },
});

export default authSlice.reducer;

export const {
  logout,
  setTokens,
  setAccessToken,
  setRefreshToken,
  removeTokens,
  setAuthProfile,
} = authSlice.actions;

// Selector to select the profile
export const selectAuthProfile = createSelector(
  (state: RootState) => state?.auth?.profile,
  (profile) => profile
);
