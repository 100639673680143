import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import { IStatus } from '@store/services/statuses/types';

const BASE_URL = process.env.REACT_APP_API_URL as string;
const uri = {
  list: '/',
};

/**
 * Slise / Api
 */
export const statusesService = createApi({
  tagTypes: ['Statuses'],
  reducerPath: 'statusesService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/statuses`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    fetchStatuses: builder.query<IStatus[], any>({
      query: (args) => {
        return {
          url: uri.list,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useFetchStatusesQuery } = statusesService;

/**
 * Selectors
 */
// const selectSelf =
//   statusesService.endpoints.fetchStatuses.select(null);
