import { FC, ReactElement, useState } from 'react';
import { motion } from 'framer-motion';
import { TextContent } from '@components/common/TextContent/TextContent';
import styles from '@components/common/FaqGroup/FaqItem/FaqItem.module.scss';

type FaqItemProps = {
  name: string;
  text: string;
};

const variantsIcon = {
  open: { x: '-50%', y: '-50%', rotate: 90 },
  closed: { x: '-50%', y: '-50%' },
};

const variantsBody = {
  open: { height: 'auto' },
  closed: { height: 0 },
};

export const FaqItem: FC<FaqItemProps> = ({ name, text }): ReactElement => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <div className={styles.root}>
      <button
        type='button'
        className={styles.root__head}
        onClick={() => setCollapsed(!collapsed)}
      >
        <span className={styles.root__head__text}>{name}</span>
        <div className={styles.root__head__icon}>
          <div></div>
          <motion.div
            initial={{ x: '-50%', y: '-50%', rotate: 0 }}
            animate={collapsed ? 'open' : 'closed'}
            variants={variantsIcon}
          ></motion.div>
        </div>
      </button>
      <motion.div
        initial={{ height: 0 }}
        animate={collapsed ? 'open' : 'closed'}
        variants={variantsBody}
        className={styles.root__body}
      >
        <div className={styles.root__body__inner}>
          <TextContent text={text} />
        </div>
      </motion.div>
    </div>
  );
};
