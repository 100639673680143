// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteAddressDialog_root__hCvfq {
  padding: 0 15px;
}
.DeleteAddressDialog_root__inner__YRbqW {
  width: 100%;
  padding: 30px 20px;
}
.DeleteAddressDialog_root__description__D7zEc {
  width: 100%;
  text-align: center;
  margin: 0 0 15px;
}
.DeleteAddressDialog_root__description__D7zEc h4 {
  font-size: 20px;
  color: var(--color-mirage);
  margin: 0 0 5px;
}
.DeleteAddressDialog_root__description__D7zEc h4 span {
  color: var(--color-primary);
}
.DeleteAddressDialog_root__description__D7zEc p {
  font-size: 16px;
  color: var(--color-gray);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/DeleteAddressDialog/DeleteAddressDialog.module.scss"],"names":[],"mappings":"AACA;EACE,eAAA;AAAF;AACE;EACE,WAAA;EACA,kBAAA;AACJ;AACE;EACE,WAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AAAI;EACE,eAAA;EACA,0BAAA;EACA,eAAA;AAEN;AADM;EACE,2BAAA;AAGR;AAAI;EACE,eAAA;EACA,wBAAA;AAEN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  padding: 0 15px;\n  &__inner {\n    width: 100%;\n    padding: 30px 20px;\n  }\n  &__description {\n    width: 100%;\n    text-align: center;\n    margin: 0 0 15px;\n    h4 {\n      font-size: 20px;\n      color: var(--color-mirage);\n      margin: 0 0 5px;\n      span {\n        color: var(--color-primary);\n      }\n    }\n    p {\n      font-size: 16px;\n      color: var(--color-gray);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `DeleteAddressDialog_root__hCvfq`,
	"root__inner": `DeleteAddressDialog_root__inner__YRbqW`,
	"root__description": `DeleteAddressDialog_root__description__D7zEc`
};
export default ___CSS_LOADER_EXPORT___;
