import styles from '@components/common/SocialNetworks/SocialNetworks.module.scss';
import { Icon } from '@components/ui';
import { classNames } from '@libs/utils';
import { FC, ReactElement } from 'react';

type SocialNetworksProps = {
  headline?: string;
  classList?: string;
  maxWidth?: boolean;
  list: {
    name: string;
    href: string;
    icon: {
      name: string;
      width: number;
      height: number;
    };
  }[];
};

export const SocialNetworks: FC<SocialNetworksProps> = ({
  headline,
  classList = '',
  maxWidth = false,
  list,
}): ReactElement => (
  <div className={classNames(classList, styles.root)}>
    {headline && headline?.length > 0 && (
      <div className={styles.headline}>
        <h6>{headline}</h6>
      </div>
    )}
    <ul
      className={classNames(
        maxWidth ? 'fx-content-center' : 'fx-content-between',
        'fx-align-center',
        'fx-nowrap',
        styles.list
      )}
    >
      {list &&
        list?.length > 0 &&
        list.map(({ name, href, icon }) => (
          <li
            key={name}
            className={classNames(
              styles.list__item,
              maxWidth ? styles.list__item__max_width : ''
            )}
          >
            <a
              href={href}
              className={classNames(
                'fx-align-center',
                'fx-content-center',
                styles.list__item__inner
              )}
              target='_blank'
            >
              <Icon name={icon.name} width={icon.width} height={icon.height} />
            </a>
          </li>
        ))}
    </ul>
  </div>
);
