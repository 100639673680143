// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Map_root__mFOPS {
  width: 100%;
  height: 100%;
  padding: 0 15px 15px;
}
.Map_root__inner__nbrMG {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/AddressBook/Map/Map.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,oBAAA;AAAF;AACE;EACE,WAAA;EACA,YAAA;EACA,mCAAA;EACA,gBAAA;AACJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  height: 100%;\n  padding: 0 15px 15px;\n  &__inner {\n    width: 100%;\n    height: 100%;\n    border-radius: var(--border-radius);\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Map_root__mFOPS`,
	"root__inner": `Map_root__inner__nbrMG`
};
export default ___CSS_LOADER_EXPORT___;
