import styles from '@components/common/SideBar/CustomerSupport/CustomerSupport.module.scss';
import { Icon } from '@components/ui';
import { classNames } from '@libs/utils';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

type CustomerSupportProps = {};

const pulse = keyframes`
  from {
    transform: scale(1);
  }
  10%,
  20% {
    transform: scale(0.9) rotate(-5deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(5deg);
  }
  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-5deg);
  }
  to {
    transform: scale(1);
  }
`;

const animation = () =>
  css`
    ${pulse} 2s infinite;
  `;

const AnimatedIcon = styled.span`
  display: inline-block;
  width: 26px;
  height: 25px;
  animation: ${animation};
`;

export const CustomerSupport: FC<CustomerSupportProps> = (): ReactElement => {
  return (
    <div className={styles.root}>
      <Link
        to='/info/customer-support'
        className={classNames('fx', 'fx-align-center', styles.root__link)}
      >
        <span className={styles.root__link__icon}>
          <AnimatedIcon>
            <Icon name='x0034' width={25} height={24} />
          </AnimatedIcon>
        </span>
        <span className={styles.root__link__text}>
          Centro de atención al cliente
        </span>
      </Link>
    </div>
  );
};
