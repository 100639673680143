import styles from '@components/common/PackageStatus/PackageStatus.module.scss';
import { Icon } from '@components/ui';
import { IPackageStatus } from '@interfaces/package-status.interface';
import { classNames } from '@libs/utils';
import { getOrderStatusIcon } from '@services/packageService';
import { FC, Fragment, ReactElement } from 'react';

type PackageStatusProps = {
  type: 'icon' | 'text';
  value: IPackageStatus | undefined;
  classList?: string;
  size?: 'lg' | 'md' | 'sm';
};

export const PackageStatus: FC<PackageStatusProps> = ({
  type,
  value,
  classList = '',
  size = 'lg',
}): ReactElement => {
  if (value) {
    switch (type) {
      case 'icon':
        return (
          <div
            className={classNames(
              styles.root__status__icon,
              styles[`root__status__icon__${size}`],
              styles[`root__status__icon__${value.slug}`],
              classList
            )}
          >
            <Icon name={getOrderStatusIcon(value.slug)} />
          </div>
        );

      case 'text':
        return (
          <span
            className={classNames(
              styles.root__status__text,
              styles[`root__status__text__${value.slug}`],
              classList
            )}
          >
            {value.text}
          </span>
        );

      default:
        return <Fragment />;
    }
  }

  return <Fragment />;
};
