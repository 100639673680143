// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PackageNotFound_root__566wk {
  width: 100%;
  height: 100%;
}
.PackageNotFound_root__description__T3DG- {
  margin: 10px 0 0;
}
.PackageNotFound_root__description__T3DG- p {
  color: var(--color-gray);
  margin: 0;
}
.PackageNotFound_root__description__T3DG- a {
  display: inline-block;
  font-weight: var(--medium);
  color: var(--color-primary);
  text-decoration: underline;
  margin: 5px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/PackageNotFound/PackageNotFound.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;AAAF;AACE;EACE,gBAAA;AACJ;AAAI;EACE,wBAAA;EACA,SAAA;AAEN;AAAI;EACE,qBAAA;EACA,0BAAA;EACA,2BAAA;EACA,0BAAA;EACA,eAAA;AAEN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  height: 100%;\n  &__description {\n    margin: 10px 0 0;\n    p {\n      color: var(--color-gray);\n      margin: 0;\n    }\n    a {\n      display: inline-block;\n      font-weight: var(--medium);\n      color: var(--color-primary);\n      text-decoration: underline;\n      margin: 5px 0 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PackageNotFound_root__566wk`,
	"root__description": `PackageNotFound_root__description__T3DG-`
};
export default ___CSS_LOADER_EXPORT___;
