// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerifyProfile_root__dn-5- {
  width: 100%;
  text-align: center;
  background-color: var(--color-primary);
  border-radius: var(--border-radius);
  padding: 20px 15px;
}
.VerifyProfile_root__dn-5- h4 {
  font-size: 20px;
  color: var(--color-white);
  margin: 0 0 15px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/VerifyProfile/VerifyProfile.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,kBAAA;EACA,sCAAA;EACA,mCAAA;EACA,kBAAA;AAAF;AACE;EACE,eAAA;EACA,yBAAA;EACA,gBAAA;AACJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  text-align: center;\n  background-color: var(--color-primary);\n  border-radius: var(--border-radius);\n  padding: 20px 15px;\n  h4 {\n    font-size: 20px;\n    color: var(--color-white);\n    margin: 0 0 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `VerifyProfile_root__dn-5-`
};
export default ___CSS_LOADER_EXPORT___;
