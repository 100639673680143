// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_root__OnvCF {
  width: 100%;
}

.Form_link__jJxCj {
  font-weight: var(--semi-bold);
  color: var(--color-primary);
}
.Form_link__jJxCj:hover {
  text-decoration: underline;
}
.Form_link__forgot_password__2R3eV {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/EditPackage/Form/Form.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;AAAF;;AAGA;EACE,6BAAA;EACA,2BAAA;AAAF;AACE;EACE,0BAAA;AACJ;AACE;EACE,eAAA;AACJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n}\n\n.link {\n  font-weight: var(--semi-bold);\n  color: var(--color-primary);\n  &:hover {\n    text-decoration: underline;\n  }\n  &__forgot_password {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Form_root__OnvCF`,
	"link": `Form_link__jJxCj`,
	"link__forgot_password": `Form_link__forgot_password__2R3eV`
};
export default ___CSS_LOADER_EXPORT___;
