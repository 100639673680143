import styles from '@components/ui/Button/Button.module.scss';
import { classNames } from '@libs/utils';
import { FC, ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';

type ButtonProps = {
  type: 'internal' | 'external' | 'button' | 'submit';
  text: string;
  href?: string;
  target?: '_self' | '_blank';
  style?: {
    fill?: boolean;
    stroke?: boolean;
    shadow?: boolean;
    color?: string;
    size?: 'sm' | 'md' | 'lg';
    auto?: boolean;
  };
  classList?: string;
  onClick?: () => void;
};

const defaultStyle = {
  fill: true,
  stroke: false,
  shadow: true,
  color: 'primary',
  size: 'md',
  auto: false,
};

export const Button: FC<ButtonProps> = ({
  type,
  text,
  href = '/',
  target = '_self',
  style,
  classList = '',
  onClick,
}): ReactElement => {
  const buttonClasses = useMemo(() => {
    let { auto, fill, stroke, shadow, color, size } = {
      ...defaultStyle,
      ...style,
    };

    const classArray = [];

    if (auto) {
      classArray.push(styles.auto);
    }

    if (stroke) {
      fill = false;
      shadow = false;
    }

    if (fill) {
      stroke = false;
    }

    if (fill) {
      classArray.push(styles.fill);
    }

    if (stroke) {
      classArray.push(styles.stroke);
    }

    if (shadow) {
      classArray.push(styles.shadow);
    }

    classArray.push(styles[`${color}`]);
    classArray.push(styles[`size_${size}`]);

    return classArray.filter((v) => v);
  }, []);

  switch (type) {
    case 'internal': {
      return (
        <Link
          to={href}
          className={classNames(classList, styles.root, ...buttonClasses)}
        >
          <span>{text}</span>
        </Link>
      );
    }

    case 'external': {
      return (
        <a
          href={href}
          target={target}
          className={classNames(classList, styles.root, ...buttonClasses)}
        >
          <span>{text}</span>
        </a>
      );
    }

    case 'submit':
    case 'button': {
      return (
        <button
          type={type}
          className={classNames(classList, styles.root, ...buttonClasses)}
          onClick={onClick}
        >
          <span>{text}</span>
        </button>
      );
    }

    default: {
      return <></>;
    }
  }
};
