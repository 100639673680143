import { SuspenseLoader } from '@components/common';
import LayoutContextProvider from '@contexts/LayoutContext';
import { useSetAuthTokens } from '@hooks/useSetAuthTokens';
import { useSetCssVars } from '@hooks/useSetCssVars';
import '@styles/app.scss';
import { lazy, Suspense, useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { Route, Routes } from 'react-router-dom';

ReactGA4.initialize(process.env.REACT_APP_GA_KEY as string);

// define lazy load modules
const Auth = lazy(() => import('@auth/Auth'));
const Dashboard = lazy(() => import('@dashboard/Dashboard'));

export default function App() {
  useSetCssVars();
  useSetAuthTokens();

  const disableContextMenu = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      document.addEventListener('contextmenu', disableContextMenu);

      return () => {
        document.removeEventListener('contextmenu', disableContextMenu);
      };
    }
  }, []);

  return (
    <LayoutContextProvider>
      <Routes>
        <Route
          path='auth/*'
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <Auth />
            </Suspense>
          }
        />
        <Route
          path='information/*'
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path='/*'
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <Dashboard />
            </Suspense>
          }
        />
      </Routes>
    </LayoutContextProvider>
  );
}
