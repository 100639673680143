import styles from '@components/common/HttpLoader/HttpLoader.module.scss';
import { LayoutContext } from '@contexts/LayoutContext';
import { classNames } from '@libs/utils';
import { FC, ReactElement, useContext, useMemo } from 'react';
import { Oval } from 'react-loader-spinner';

type HttpLoaderProps = {
  active?: boolean;
};

export const HttpLoader: FC<HttpLoaderProps> = ({ active }): ReactElement => {
  const { httpLoader } = useContext(LayoutContext);
  const isActive = useMemo(
    () => (typeof active === 'undefined' ? httpLoader : active),
    [httpLoader, active]
  );

  return (
    <div
      className={classNames(
        'fx-align-center',
        'fx-content-center',
        'popup',
        styles.root,
        isActive ? styles.root__active : '',
        isActive ? 'popup__active' : ''
      )}
    >
      <div
        className={classNames(
          'fx-align-center',
          'fx-content-center',
          'popup__inner',
          styles.root__inner
        )}
      >
        <Oval
          height={38}
          width={38}
          color='var(--color-primary)'
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor='var(--color-primary)'
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
      </div>
    </div>
  );
};
