import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import { IFaqItem } from '@store/services/faqs/types';

const BASE_URL =
  `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SUFFIX}` as string;

/**
 * Slise / Api
 */
export const faqsService = createApi({
  tagTypes: ['FAQs'],
  reducerPath: 'faqsService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/faqs/group/`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    fetchFaqGroup: builder.query<IFaqItem[], { slug: string | undefined }>({
      query: (args) => {
        return {
          url: args.slug + '/',
          method: 'GET',
        };
      },
      transformResponse: (result: any, meta, arg) => result?.data || [],
    }),
  }),
});

export const { useFetchFaqGroupQuery } = faqsService;
