// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Headline_root__U6S4B {
  width: 100%;
  padding: 10px 0 15px;
}
.Headline_root__U6S4B h2 {
  font-size: 32px;
  color: var(--color-mirage);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/TopBar/Headline/Headline.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,oBAAA;AAAF;AACE;EACE,eAAA;EACA,0BAAA;AACJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  padding: 10px 0 15px;\n  h2 {\n    font-size: 32px;\n    color: var(--color-mirage);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Headline_root__U6S4B`
};
export default ___CSS_LOADER_EXPORT___;
