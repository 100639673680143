import { PACKAGE_ORDER, PACKAGE_TYPES } from '@libs/constants';
import { getPackageStatusByType } from '@libs/utils';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import {
  IEditPackage,
  IGroups,
  IPackage,
  IPackageDetails,
  IPackageDetailsParams,
  IPackageDetailsResponse,
  IPackagesParams,
  IRoadmapItem,
  IScore,
  ISearchPackage,
} from '@store/services/packages/types';
import qs from 'qs';

const BASE_URL =
  `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SUFFIX}` as string;
const uri = {
  list: '/list/',
  item: '/item/',
  group: '/group/',
  scores: '/scores/',
  search: '/search/',
  edit: '/edit/',
};

/**
 * Slise / Api
 */
export const packagesService = createApi({
  tagTypes: ['Packages'],
  reducerPath: 'packagesService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/packages`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    fetchPackages: builder.query<IPackage[], IPackagesParams>({
      query: ({ type, quantity, offset }) => {
        const params = `?c=${type}&q=${quantity}&o=${offset}`;

        return {
          url: uri.list + params,
          method: 'GET',
        };
      },
      transformResponse: (
        result: any,
        meta,
        arg
      ): IPackage[] | Promise<IPackage[]> => result?.data || null,
    }),
    fetchPackage: builder.query<IPackageDetails, IPackageDetailsParams>({
      query: ({ code }) => {
        return {
          url: uri.item + `?c=${code}`,
          method: 'GET',
        };
      },
      transformResponse: (
        result: IPackageDetailsResponse
      ): IPackageDetails | Promise<IPackageDetails> | any => {
        let data: IPackageDetails = {
          package: result.data.result,
          roadmap: [],
        };

        if (result?.data?.roadmap && Array.isArray(result?.data?.roadmap)) {
          data.roadmap = result.data.roadmap.map((item: IRoadmapItem) => {
            const imageIds: number[] | [] =
              item.Fotos?.length > 0
                ? item.Fotos.split(';').map((id) => parseInt(id.trim()))
                : [];
            return { ...item, imageIds };
          });
        }

        return data;
      },
    }),
    fetchPackagesGroup: builder.query<IGroups | null, any>({
      query: () => {
        return {
          url: uri.group,
          method: 'GET',
        };
      },
      transformResponse: (result: any, meta, arg): IGroups | null => {
        if (!result?.data) return null;

        const { data } = result;
        const order = [
          'C::created',
          'OR::on_route',
          'D::delivered',
          'F::failed',
        ];
        const filteredAndOrderedData = order.reduce((result, state) => {
          (result as any)[state] = data[state] || [];
          return result;
        }, {});

        return filteredAndOrderedData as IGroups;
      },
    }),
    fetchPackagesScores: builder.query<any, any>({
      query: () => {
        return {
          url: uri.scores,
          method: 'GET',
        };
      },
      transformResponse: (result: any, meta, arg): IScore[] | [] => {
        const data = result?.data;
        const response = [];

        if (data) {
          for (const prop in data) {
            const properties = prop.split('_');
            const symbol = properties[1];
            const status = getPackageStatusByType(symbol);
            const slug = status.slug;

            response.push({
              symbol,
              slug,
              name: PACKAGE_TYPES[symbol],
              order: PACKAGE_ORDER[symbol],
              value: data[prop],
            });
          }
        }

        return response.sort((a, b) => a.order - b.order);
      },
    }),
    searchPackages: builder.mutation<ISearchPackage[], { code: string }>({
      query: (args) => {
        return {
          url: uri.search,
          method: 'GET',
          params: {
            q: args.code,
          },
        };
      },
      transformResponse: (result: any, meta, arg): ISearchPackage[] => {
        const list = result.data.map((item: any) => {
          return {
            status: item.CodEstadoPaquete,
            code: item.CodPaquete,
          };
        });

        return (
          (Array.from(
            new Map(list.map((obj: any) => [obj.code, obj])).values()
          ) as ISearchPackage[]) || []
        );
      },
    }),
    editPackage: builder.mutation<IEditPackage, any>({
      query(data) {
        return {
          url: uri.edit,
          method: 'POST',
          body: qs.stringify(data),
        };
      },
    }),
  }),
});

export const {
  useFetchPackagesQuery,
  useFetchPackageQuery,
  useFetchPackagesGroupQuery,
  useFetchPackagesScoresQuery,
  useSearchPackagesMutation,
  useEditPackageMutation,
} = packagesService;
