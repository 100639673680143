import { localStorageUtil, sessionStorageUtil } from '@libs/utils/storageUtils';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query';

export default async function customPrepareHeaders(
  headers: Headers,
  {
    getState,
  }: Pick<BaseQueryApi, 'getState' | 'type' | 'extra' | 'endpoint' | 'forced'>
) {
  const token =
    localStorageUtil.getItem('accessToken') ||
    sessionStorageUtil.getItem('accessToken');

  headers.set('Content-Type', 'application/x-www-form-urlencoded'); // 'application/x-www-form-urlencoded;charset=UTF-8'

  token && headers.set('Authorization', token);

  return headers;
}
