import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '@store/middlewares/customFetchBase';
import {
  IGenericResponse,
  IUser,
  IUserFormError,
  IUserTokens,
} from '@store/services/auth/types';
import qs from 'qs';

const uri = {
  register: '/register/',
  login: '/login/',
  verify: '/verify/',
};

export interface IUserLoginArgs {
  status: number;
  data: { user: IUser; tokens: IUserTokens };
  errors: IUserFormError;
}

export const authService = createApi({
  tagTypes: ['Auth'],
  reducerPath: 'authService',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    registerUser: builder.mutation<IGenericResponse, any>({
      query(data) {
        return {
          url: uri.register,
          method: 'POST',
          body: qs.stringify(data),
        };
      },
      transformResponse: (result: any) => result,
      transformErrorResponse: (result: any) => result.data,
    }),
    loginUser: builder.mutation<IUserLoginArgs, any>({
      query(args) {
        return {
          url: uri.login,
          method: 'POST',
          body: qs.stringify(args),
        };
      },
      transformResponse: (result: any) => result?.data || {},
      transformErrorResponse: (result: any) => result?.data || {},
    }),
    verifyUser: builder.mutation<
      IGenericResponse,
      { otpEmail: string; otpSms: string }
    >({
      query({ otpEmail, otpSms }) {
        return {
          url: `${uri.verify}?otp_email=${otpEmail}&otp_sms=${otpSms}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useVerifyUserMutation,
} = authService;
