import styles from '@components/common/PackageGroup/PackageGroup.module.scss';
import { PackageItem } from '@components/common/PackageGroup/PackageItem/PackageItem';
import { classNames } from '@libs/utils';
import { IPackage } from '@store/services/packages/types';
import parse from 'html-react-parser';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

type PackageGroupProps = {
  headline?: string;
  useHtmlHeadline?: boolean;
  type?: string;
  action?: {
    href: string;
    text: string;
  };
  list: IPackage[];
};

export const PackageGroup: FC<PackageGroupProps> = ({
  headline,
  useHtmlHeadline = false,
  action,
  type,
  list,
}): ReactElement => (
  <div className={styles.root}>
    {headline && (
      <div
        className={classNames(
          'fx',
          'fx-nowrap',
          'fx-align-center',
          'fx-content-between',
          styles.root__head
        )}
      >
        <h4 className={styles[`root__head__${type}`]}>
          {useHtmlHeadline ? parse(headline) : headline}
        </h4>
        {action && (
          <Link
            to={action.href}
            className={classNames(
              styles.root__head__action,
              !list || list?.length === 0
                ? styles.root__head__action__disabled
                : ''
            )}
          >
            {action.text}
          </Link>
        )}
      </div>
    )}
    {list && list?.length > 0 ? (
      list.map((props: IPackage) => (
        <PackageItem key={props.CodPaquete} {...props} />
      ))
    ) : (
      <div className={styles.root__body__not_found}>
        <p>No se encontraron paquetes en esta categoría.</p>
      </div>
    )}
  </div>
);
