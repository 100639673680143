// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PackageStatus_root__status__icon__mPLJj {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;
}
.PackageStatus_root__status__icon__lg__v0vCF {
  width: 48px;
  height: 48px;
}
.PackageStatus_root__status__icon__md__BPIHI {
  width: 40px;
  height: 40px;
}
.PackageStatus_root__status__icon__sm__tFLIA {
  width: 36px;
  height: 36px;
}
.PackageStatus_root__status__icon__created__lvc93 {
  background-color: rgba(155, 81, 224, 0.2);
}
.PackageStatus_root__status__icon__on_route__ozLa- {
  background-color: rgba(236, 202, 22, 0.2);
}
.PackageStatus_root__status__icon__delivered__L\\+qHH {
  background-color: rgba(39, 170, 18, 0.2);
}
.PackageStatus_root__status__icon__failed__YDk74 {
  background-color: rgba(213, 78, 59, 0.2);
}
.PackageStatus_root__status__text__SWuqs {
  display: inline-block;
  font-weight: var(--bold);
  font-size: 14px;
}
.PackageStatus_root__status__text__created__Uz-uH {
  color: var(--color-medium-purple);
}
.PackageStatus_root__status__text__on_route__d18-m {
  color: var(--color-gold-tips);
}
.PackageStatus_root__status__text__delivered__\\+FXXD {
  color: var(--color-la-palma);
}
.PackageStatus_root__status__text__failed__5Owtp {
  color: var(--color-valencia);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/PackageStatus/PackageStatus.module.scss"],"names":[],"mappings":"AAGI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;AAFN;AAGM;EACE,WAAA;EACA,YAAA;AADR;AAGM;EACE,WAAA;EACA,YAAA;AADR;AAGM;EACE,WAAA;EACA,YAAA;AADR;AAGM;EACE,yCAAA;AADR;AAGM;EACE,yCAAA;AADR;AAGM;EACE,wCAAA;AADR;AAGM;EACE,wCAAA;AADR;AAII;EACE,qBAAA;EACA,wBAAA;EACA,eAAA;AAFN;AAGM;EACE,iCAAA;AADR;AAGM;EACE,6BAAA;AADR;AAGM;EACE,4BAAA;AADR;AAGM;EACE,4BAAA;AADR","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  &__status {\n    &__icon {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      border-radius: 6px;\n      overflow: hidden;\n      &__lg {\n        width: 48px;\n        height: 48px;\n      }\n      &__md {\n        width: 40px;\n        height: 40px;\n      }\n      &__sm {\n        width: 36px;\n        height: 36px;\n      }\n      &__created {\n        background-color: set-color(--color-medium-purple, 0.2);\n      }\n      &__on_route {\n        background-color: set-color(--color-gold-tips, 0.2);\n      }\n      &__delivered {\n        background-color: set-color(--color-la-palma, 0.2);\n      }\n      &__failed {\n        background-color: set-color(--color-valencia, 0.2);\n      }\n    }\n    &__text {\n      display: inline-block;\n      font-weight: var(--bold);\n      font-size: 14px;\n      &__created {\n        color: var(--color-medium-purple);\n      }\n      &__on_route {\n        color: var(--color-gold-tips);\n      }\n      &__delivered {\n        color: var(--color-la-palma);\n      }\n      &__failed {\n        color: var(--color-valencia);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root__status__icon": `PackageStatus_root__status__icon__mPLJj`,
	"root__status__icon__lg": `PackageStatus_root__status__icon__lg__v0vCF`,
	"root__status__icon__md": `PackageStatus_root__status__icon__md__BPIHI`,
	"root__status__icon__sm": `PackageStatus_root__status__icon__sm__tFLIA`,
	"root__status__icon__created": `PackageStatus_root__status__icon__created__lvc93`,
	"root__status__icon__on_route": `PackageStatus_root__status__icon__on_route__ozLa-`,
	"root__status__icon__delivered": `PackageStatus_root__status__icon__delivered__L+qHH`,
	"root__status__icon__failed": `PackageStatus_root__status__icon__failed__YDk74`,
	"root__status__text": `PackageStatus_root__status__text__SWuqs`,
	"root__status__text__created": `PackageStatus_root__status__text__created__Uz-uH`,
	"root__status__text__on_route": `PackageStatus_root__status__text__on_route__d18-m`,
	"root__status__text__delivered": `PackageStatus_root__status__text__delivered__+FXXD`,
	"root__status__text__failed": `PackageStatus_root__status__text__failed__5Owtp`
};
export default ___CSS_LOADER_EXPORT___;
