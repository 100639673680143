// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PackageItem_root__ZsYtC {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin: 0 0 15px;
  padding: 15px;
  cursor: pointer;
  position: relative;
}
.PackageItem_root__icon__4VvtW {
  pointer-events: none;
  transform: translate(-7px, -50%);
  position: absolute;
  top: 50%;
  right: 0;
}
.PackageItem_root__meta__iCdA- {
  width: calc(100% - 48px);
  padding: 0 40px 0 15px;
}
.PackageItem_root__meta__iCdA- h3 {
  font-weight: var(--semi-bold);
  font-size: 16px;
  color: var(--color-mirage);
}
.PackageItem_root__meta__iCdA- div {
  line-height: 1.2;
}
.PackageItem_root__meta__status__lpUMV, .PackageItem_root__meta__date__kzpvH {
  display: inline-block;
  font-size: 12px;
}
.PackageItem_root__meta__status__lpUMV {
  margin: 0 10px 0 0;
}
.PackageItem_root__meta__date__kzpvH {
  color: var(--color-manatee);
}
.PackageItem_root__meta__edit__DNObi {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 5px 0 0;
}
.PackageItem_root__meta__edit__DNObi span,
.PackageItem_root__meta__edit__DNObi i {
  pointer-events: none;
}
.PackageItem_root__meta__edit__DNObi i {
  display: inline-block;
  font-weight: var(--medium);
  font-style: normal;
  font-size: 12px;
  color: var(--color-primary);
  margin: 0 0 0 5px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/PackageGroup/PackageItem/PackageItem.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,oCAAA;EACA,6BAAA;EACA,mCAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;AAAF;AACE;EACE,oBAAA;EACA,gCAAA;EACA,kBAAA;EACA,QAAA;EACA,QAAA;AACJ;AACE;EACE,wBAAA;EACA,sBAAA;AACJ;AAAI;EACE,6BAAA;EACA,eAAA;EACA,0BAAA;AAEN;AAAI;EACE,gBAAA;AAEN;AAAI;EAEE,qBAAA;EACA,eAAA;AACN;AACI;EACE,kBAAA;AACN;AACI;EACE,2BAAA;AACN;AACI;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,gBAAA;AACN;AAAM;;EAEE,oBAAA;AAER;AAAM;EACE,qBAAA;EACA,0BAAA;EACA,kBAAA;EACA,eAAA;EACA,2BAAA;EACA,iBAAA;AAER","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  background-color: var(--color-white);\n  box-shadow: var(--box-shadow);\n  border-radius: var(--border-radius);\n  margin: 0 0 15px;\n  padding: 15px;\n  cursor: pointer;\n  position: relative;\n  &__icon {\n    pointer-events: none;\n    transform: translate(-7px, -50%);\n    position: absolute;\n    top: 50%;\n    right: 0;\n  }\n  &__meta {\n    width: calc(100% - 48px);\n    padding: 0 40px 0 15px;\n    h3 {\n      font-weight: var(--semi-bold);\n      font-size: 16px;\n      color: var(--color-mirage);\n    }\n    div {\n      line-height: 1.2;\n    }\n    &__status,\n    &__date {\n      display: inline-block;\n      font-size: 12px;\n    }\n    &__status {\n      margin: 0 10px 0 0;\n    }\n    &__date {\n      color: var(--color-manatee);\n    }\n    &__edit {\n      display: flex;\n      flex-flow: row nowrap;\n      align-items: center;\n      padding: 5px 0 0;\n      span,\n      i {\n        pointer-events: none;\n      }\n      i {\n        display: inline-block;\n        font-weight: var(--medium);\n        font-style: normal;\n        font-size: 12px;\n        color: var(--color-primary);\n        margin: 0 0 0 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PackageItem_root__ZsYtC`,
	"root__icon": `PackageItem_root__icon__4VvtW`,
	"root__meta": `PackageItem_root__meta__iCdA-`,
	"root__meta__status": `PackageItem_root__meta__status__lpUMV`,
	"root__meta__date": `PackageItem_root__meta__date__kzpvH`,
	"root__meta__edit": `PackageItem_root__meta__edit__DNObi`
};
export default ___CSS_LOADER_EXPORT___;
