import { useEnv } from '@hooks/useEnv';
import { useEffect, useLayoutEffect, useState } from 'react';
import Geocode from 'react-geocode';

export const useNavigator = () => {
  const [field, setField] = useState<HTMLInputElement | undefined>();
  const [navigatorExist, setNavigatorExist] = useState<boolean>(false);
  const geoApiKey = useEnv('geo_api_key');

  useEffect(() => {
    setNavigatorExist('geolocation' in navigator);
  }, [navigator]);

  useEffect(() => {
    if (geoApiKey) {
      Geocode.setApiKey(geoApiKey);
      Geocode.setLanguage('es_PE');
    }
  }, [geoApiKey]);

  useLayoutEffect(() => {
    const fieldElement = document.getElementById('address') as HTMLInputElement;
    fieldElement && setField(fieldElement);
  }, []);

  function getPlace(
    successCallback: (place: any) => any,
    errorCallback: (error: GeolocationPositionError) => any,
    delay: number = 2000
  ) {
    new Promise((resolve) => {
      setTimeout(
        () =>
          resolve(
            navigator.geolocation.getCurrentPosition(
              async (position: GeolocationPosition) => {
                const { coords } = position;

                try {
                  const address = await getAddress(
                    coords.latitude,
                    coords.longitude
                  );

                  if (address && address?.length > 0) {
                    if (field) {
                      field.value = address;
                    }

                    successCallback({
                      address,
                      coords: {
                        lat: coords.latitude,
                        lng: coords.longitude,
                      },
                    });
                  }
                } catch (err) {
                  return err;
                }
              },
              (error: GeolocationPositionError) => errorCallback(error)
            )
          ),
        delay
      );
    });
  }

  function getAddress(lat: number, lng: number): Promise<string | undefined> {
    return Geocode.fromLatLng(lat.toString(), lng.toString()).then(
      (res) => {
        console.log(res.results);

        return res.results[0].formatted_address;
      },
      (err) => err
    );
  }

  function setFieldValue(address: string) {
    if (field) {
      field.value = address;
    }
  }

  return { navigatorExist, getPlace, getAddress, setFieldValue };
};
