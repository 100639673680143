import { SearchForm } from '@components/common';
import Headline from '@components/common/TopBar/Headline';
import { TabsProfile } from '@components/common/TopBar/Tabs';
import { TabsInfo } from '@components/common/TopBar/Tabs/TabsInfo';
import { TabsPackages } from '@components/common/TopBar/Tabs/TabsPackages';
import styles from '@components/common/TopBar/TopBar.module.scss';
import { usePageTitle } from '@hooks/usePageTitle';
import { useSetTopBarParams } from '@hooks/useSetTopBarParams';
import { useAppSelector } from '@store/store';
import { FC, ReactElement, useRef } from 'react';

type TopBarProps = {};

export const TopBar: FC<TopBarProps> = (): ReactElement => {
  const { pathname } = useAppSelector((state) => state.router.location);
  const { pageTitle } = useAppSelector((state) => state.meta);
  const ref = useRef(null);
  const { title } = usePageTitle();

  useSetTopBarParams(ref, []);

  return (
    <nav ref={ref} className={styles.root}>
      <div className={styles.root__inner}>
        <SearchForm />
        <Headline headline={pathname.includes('info') ? title : pageTitle} />
        {pathname &&
          pathname?.includes('packages') &&
          !pathname?.includes('packages/view') && <TabsPackages />}
        {pathname && pathname?.includes('profile') && <TabsProfile />}
        {pathname && pathname?.includes('info') && <TabsInfo />}
      </div>
    </nav>
  );
};
