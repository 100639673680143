import { Locations } from '@components/common';
import styles from '@components/common/AddressBook/AddressBook.module.scss';
import { Form } from '@components/common/AddressBook/Form/Form';
import { Map } from '@components/common/AddressBook/Map/Map';
import { PlaceType } from '@interfaces/map.interface';
import { classNames } from '@libs/utils';
import { useFetchAddressesQuery } from '@store/services/addresses/addressesService';
import { FC, ReactElement, useEffect, useState } from 'react';

type AddressBookProps = {};

export const AddressBook: FC<AddressBookProps> = (): ReactElement => {
  const [detection, setDetection] = useState<boolean>(true);
  const [place, setPlace] = useState<PlaceType>();
  const { data: locations, isSuccess } = useFetchAddressesQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    console.log('%cAddressBook', 'color: lightgreen', place);
  }, [place]);

  return (
    <div className={classNames('fx', styles.root)}>
      <div>
        {isSuccess && locations?.length > 0 && (
          <Locations list={locations} isSimple={true} disableLink={true} />
        )}
      </div>
      <div className='fx-column'>
        <Form onCheckDetection={setDetection} onSelectPlace={setPlace} />
        <Map center={place?.coords as any} onSelectPlace={setPlace} />
      </div>
    </div>
  );
};
