import styles from '@components/common/Scores/Scores.module.scss';
import { Icon } from '@components/ui';
import { classNames } from '@libs/utils';
import { useFetchPackagesScoresQuery } from '@store/services/packages/packagesService';
import { IScore } from '@store/services/packages/types';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

type ScoresProps = {};

export const Scores: FC<ScoresProps> = ({}): any => {
  const navigate = useNavigate();
  const { data, isSuccess } = useFetchPackagesScoresQuery(
    {
      q: 100,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      refetchOnFocus: true,
    },
    { skip: false }
  );

  const onClick = (slug: string) => {
    navigate(`/packages/category/${slug}`);
  };

  return (
    isSuccess && (
      <div className={classNames('fx', styles.root)}>
        {data.map(({ symbol, slug, name, value }: IScore) => (
          <div
            key={symbol}
            className={classNames(
              styles.root__item,
              styles[`root__item__${isMobile ? 'mobile' : 'desktop'}`]
            )}
            onClick={() => onClick(slug)}
            data-slug={slug}
            data-value={value}
          >
            <div
              className={classNames(
                'fx-column',
                'fx-align-center',
                styles.root__item__inner
              )}
            >
              <div
                className={classNames(
                  styles.root__item__icon,
                  styles[`root__item__icon__${slug}`]
                )}
              >
                <Icon name='x0008' />
              </div>
              <h3 style={{ textAlign: isMobile ? 'center' : 'left' }}>
                {name}
              </h3>
              <h4 className={styles[`root__item__score__${slug}`]}>{value}</h4>
              <div className='col-x12 fx-align-center fx-content-between'></div>
            </div>
          </div>
        ))}
      </div>
    )
  );
};
