import { FC, ReactElement, useMemo } from 'react';
import {
  CustomView as Template,
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from 'react-device-detect';

type CustomViewProps = {
  children: ReactElement | ReactElement[];
  type: 'mobile' | 'tablet' | 'desktop';
  strict?: boolean;
};

export const CustomView: FC<CustomViewProps> = ({
  children,
  type,
  strict = false,
}): ReactElement => {
  const condition = useMemo(() => {
    if (strict) {
      switch (type) {
        case 'mobile':
          return isMobile;
        case 'tablet':
          return isTablet;
        case 'desktop':
          return isDesktop;
      }
    } else {
      switch (type) {
        case 'mobile':
          return isMobileOnly;
        case 'tablet':
          return isTablet;
        case 'desktop':
          return isDesktop;
      }
    }
  }, [type, strict]);

  return (
    <Template condition={condition} renderWithFragment={true}>
      {children}
    </Template>
  );
};
