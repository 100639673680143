export const getOrderStatusIcon = (status: string): string => {
  if (status) {
    switch (status) {
      case 'created':
        return 'x0021';
      case 'on_route':
        return 'x0022';
      case 'failed':
        return 'x0023';
      case 'delivered':
        return 'x0024';
    }
  }

  return '';
};

export const getPackagesType = (type: string) => {
  switch (type) {
    case 'active':
      return 'Activos';
    case 'history':
      return 'Historia';
    case 'archive':
      return 'Archivo';

    default:
      return '';
  }
};
