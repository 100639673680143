import { IApiResponse } from '@interfaces/common.interface';
import { localStorageUtil } from '@libs/utils/storageUtils';
import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '@store/middlewares/customFetchBase';
import {
  IGenericResponse,
  IUpdateProfile,
  IUser,
} from '@store/services/users/types';
import qs from 'qs';

const uri = {
  profile: '/profile/',
  logout: '/logout/',
  update: '/profile/',
  changePassword: '/change-password/',
};

/**
 * Slice / Api
 */
export const usersService = createApi({
  tagTypes: ['Users'],
  reducerPath: 'usersService',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMe: builder.query<IUser, null>({
      query() {
        const localToken = localStorageUtil.getItem('accessToken');
        const sessionToken = localStorageUtil.getItem('accessToken');
        const token = localToken || sessionToken;

        if (typeof token === 'undefined' || token?.length === 0) {
          return {
            url: uri.logout,
          };
        }

        return {
          url: uri.profile,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      transformResponse: (result: IApiResponse) => result?.data || null,
    }),
    logout: builder.mutation<{ refresh: string }, any>({
      query(data) {
        return {
          url: uri.logout,
          method: 'POST',
          body: {
            refresh: data.refresh,
          },
        };
      },
    }),
    changePassword: builder.mutation<IGenericResponse, { password: string }>({
      query(data) {
        return {
          url: uri.changePassword,
          method: 'POST',
          body: qs.stringify(data),
        };
      },
    }),
    updateProfile: builder.mutation<IGenericResponse, IUpdateProfile>({
      query(data) {
        return {
          url: uri.update,
          method: 'PUT',
          body: qs.stringify(data),
        };
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useLogoutMutation,
  useChangePasswordMutation,
  useUpdateProfileMutation,
} = usersService;

/**
 * Selectors
 */
const selectUser = usersService.endpoints.getMe.select(null);

export const selectProfile = createSelector(selectUser, (res) => res.data);
export const selectProfileIsActive = createSelector(
  selectUser,
  (res) => res.data?.is_active
);
