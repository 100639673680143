import styles from '@components/common/CheckForUpdates/CheckForUpdates.module.scss';
import { Button } from '@components/ui';
import { classNames, delayFn } from '@libs/utils';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as serviceWorker from '../../../../serviceWorkerRegistration';

export const CheckForUpdates: FC = () => {
  const navigate = useNavigate();
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [worker, setWorker] = useState<ServiceWorker | null>(null);

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setHasMounted(true);
    delayFn(() => {
      setActive(true);
      setWorker(registration.waiting);
    }, 500);
  };

  const reload = () => {
    worker?.postMessage({ type: 'SKIP_WAITING' });
    navigate(0);
  };

  return hasMounted ? (
    <div
      className={classNames(
        'fx-align-center',
        'fx-content-center',
        styles.root,
        active ? styles.root__active : ''
      )}
    >
      <div
        className={classNames(
          styles.root__overlay,
          active ? styles.root__overlay__active : ''
        )}
      />
      <div
        className={classNames(
          styles.root__content,
          active ? styles.root__content__active : ''
        )}
      >
        <div className={styles.root__content__text}>
          <h3>¡Una nueva version esta disponible!</h3>
          <p>Haga clic en el botón de abajo para actualizar la aplicación.</p>
        </div>
        <Button type='button' text='Recargar' onClick={reload} />
      </div>
    </div>
  ) : (
    <></>
  );
};
