// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Headline_root__MaYi3 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.Headline_root__alignment_start__vF\\+gO {
  justify-content: flex-start;
}
.Headline_root__alignment_center__E8NbT {
  justify-content: center;
}
.Headline_root__alignment_end__JigTK {
  justify-content: flex-end;
}
.Headline_root__title__xzY4d {
  font-weight: var(--extra-bold);
  line-height: 1.1;
}
.Headline_root__title__size_x3__px9rc {
  font-size: 26px;
  margin: 0 0 5px;
}
.Headline_root__title__xzY4d span {
  color: var(--color-primary);
}
.Headline_root__description__Fb0oy * {
  font-size: 16px;
  color: var(--color-gray);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/ui/Headline/Headline.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;AAAF;AAEI;EACE,2BAAA;AAAN;AAEI;EACE,uBAAA;AAAN;AAEI;EACE,yBAAA;AAAN;AAGE;EACE,8BAAA;EACA,gBAAA;AADJ;AAGM;EACE,eAAA;EACA,eAAA;AADR;AAII;EACE,2BAAA;AAFN;AAMI;EACE,eAAA;EACA,wBAAA;AAJN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: flex-start;\n  &__alignment {\n    &_start {\n      justify-content: flex-start;\n    }\n    &_center {\n      justify-content: center;\n    }\n    &_end {\n      justify-content: flex-end;\n    }\n  }\n  &__title {\n    font-weight: var(--extra-bold);\n    line-height: 1.1;\n    &__size {\n      &_x3 {\n        font-size: 26px;\n        margin: 0 0 5px;\n      }\n    }\n    span {\n      color: var(--color-primary);\n    }\n  }\n  &__description {\n    * {\n      font-size: 16px;\n      color: var(--color-gray);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Headline_root__MaYi3`,
	"root__alignment_start": `Headline_root__alignment_start__vF+gO`,
	"root__alignment_center": `Headline_root__alignment_center__E8NbT`,
	"root__alignment_end": `Headline_root__alignment_end__JigTK`,
	"root__title": `Headline_root__title__xzY4d`,
	"root__title__size_x3": `Headline_root__title__size_x3__px9rc`,
	"root__description": `Headline_root__description__Fb0oy`
};
export default ___CSS_LOADER_EXPORT___;
