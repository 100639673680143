// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_root__FMupy {
  width: 100%;
}
.Tabs_root__item__Pd\\+5\\+ {
  display: block;
  margin: 0 10px;
}
.Tabs_root__item__Pd\\+5\\+:first-child {
  margin-left: 0;
}
.Tabs_root__item__Pd\\+5\\+:last-child {
  margin-right: 0;
}
.Tabs_root__item__element__Ctik\\+ {
  display: block;
  font-weight: var(--medium);
  font-size: 14px;
  color: var(--color-gray);
  transition: var(--def-transition-300ms);
  padding: 0 0 5px;
  overflow: hidden;
  position: relative;
}
.Tabs_root__item__element__Ctik\\+:hover {
  color: var(--color-primary);
}
.Tabs_root__item__element__Ctik\\+:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--color-primary);
  border-radius: 8px;
  transition: transform var(--def-transition-300ms);
  position: absolute;
  top: 100%;
  left: 0;
}
.Tabs_root__item__element__active__Vagzm {
  color: var(--color-primary);
}
.Tabs_root__item__element__active__Vagzm:after {
  transform: translateY(-2px);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/TopBar/Tabs/Tabs.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;AAAF;AACE;EACE,cAAA;EACA,cAAA;AACJ;AAAI;EACE,cAAA;AAEN;AAAI;EACE,eAAA;AAEN;AAAI;EACE,cAAA;EACA,0BAAA;EACA,eAAA;EACA,wBAAA;EACA,uCAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AAEN;AADM;EACE,2BAAA;AAGR;AADM;EACE,WAAA;EACA,WAAA;EACA,WAAA;EACA,sCAAA;EACA,kBAAA;EACA,iDAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;AAGR;AADM;EACE,2BAAA;AAGR;AAFQ;EACE,2BAAA;AAIV","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  &__item {\n    display: block;\n    margin: 0 10px;\n    &:first-child {\n      margin-left: 0;\n    }\n    &:last-child {\n      margin-right: 0;\n    }\n    &__element {\n      display: block;\n      font-weight: var(--medium);\n      font-size: 14px;\n      color: var(--color-gray);\n      transition: var(--def-transition-300ms);\n      padding: 0 0 5px;\n      overflow: hidden;\n      position: relative;\n      &:hover {\n        color: var(--color-primary);\n      }\n      &:after {\n        content: '';\n        width: 100%;\n        height: 2px;\n        background-color: var(--color-primary);\n        border-radius: 8px;\n        transition: transform var(--def-transition-300ms);\n        position: absolute;\n        top: 100%;\n        left: 0;\n      }\n      &__active {\n        color: var(--color-primary);\n        &:after {\n          transform: translateY(-2px);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Tabs_root__FMupy`,
	"root__item": `Tabs_root__item__Pd+5+`,
	"root__item__element": `Tabs_root__item__element__Ctik+`,
	"root__item__element__active": `Tabs_root__item__element__active__Vagzm`
};
export default ___CSS_LOADER_EXPORT___;
