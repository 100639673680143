import {
  IconOffline,
  IconOnline,
} from '@components/common/NetworkStatus/NetworkIcons';
import styles from '@components/common/NetworkStatus/NetworkStatus.module.scss';
import { usePrevious } from '@hooks/usePrevious';
import { classNames, delayFn, isDefined } from '@libs/utils';
import { FC, ReactElement, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';

type NetworkStatusProps = {
  online: boolean;
};

const variantsOverlay = {
  visible: { opacity: 1 },
  invisible: { opacity: 0 },
};

const variantsBody = {
  visible: { y: 0, opacity: 1, scale: 1 },
  invisible: { y: 15, opacity: 0, scale: 0.9 },
};

export const NetworkStatus: FC<NetworkStatusProps> = ({
  online,
}): ReactElement | any => {
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const prevStatus = usePrevious(online) as boolean | undefined;

  useEffect(() => {
    if (isDefined(prevStatus)) {
      // show status: Online
      if (prevStatus && !online) {
        setHasMounted(true);
        delayFn(() => setActive(true), 500);
      }

      // show status: Online
      if (!prevStatus && online) {
        setHasMounted(true);
        delayFn(() => {
          setActive(true);
          delayFn(() => {
            setActive(false);
            delayFn(() => setHasMounted(true), 500);
          }, 3000);
        }, 500);
      }
    }
  }, [online, prevStatus]);

  // const onClose = () => {
  //   if (!online) {
  //     return;
  //   }

  //   setActive(false);
  //   delayFn(() => setHasMounted(false), 1000);
  // };

  return (
    hasMounted && (
      <div
        className={classNames(
          'fx-align-start',
          'fx-content-center',
          !isDesktop ? 'fx-align-start' : 'fx-align-center',
          styles.root,
          active ? styles.root__active : ''
        )}
      >
        <div
          className={classNames(
            styles.root__overlay,
            isDesktop ? styles.root__overlay__desktop : ''
          )}
        />
        <div
          className={classNames(
            'fx-align-center',
            styles.root__content,
            isDesktop ? styles.root__content__desktop : ''
          )}
        >
          <div
            className={classNames(
              styles.root__content__left,
              isDesktop ? styles.root__content__left__desktop : ''
            )}
          >
            {online ? (
              <IconOnline
                classList={styles.root__content__icon}
                style={{
                  width: isDesktop ? 48 : 28,
                  height: isDesktop ? 48 : 28,
                }}
              />
            ) : (
              <IconOffline
                classList={styles.root__content__icon}
                style={{
                  width: isDesktop ? 48 : 28,
                  height: isDesktop ? 48 : 28,
                }}
              />
            )}
          </div>
          <div
            className={classNames(
              styles.root__content__right,
              isDesktop ? styles.root__content__right__desktop : ''
            )}
          >
            {online ? (
              <p>
                <b>¡Estás en línea!</b> <br />
                Se establece la conexión.
              </p>
            ) : (
              <p>
                <b>¡Estas desconectado!</b> <br />
                Comprueba tu conexión a Internet.
              </p>
            )}
          </div>
        </div>
      </div>
    )
  );
};
