const routesSchema = {
  auth: {
    login: '/auth/login',
  },
  root: {
    home: '/',
    faq: '/info/faq',
    privacyPolicy: '/info/privacy-policy',
    termsOfUse: '/info/terms-of-use',
  },
  packages: {
    root: '/packages',
    active: '/packages/category/active',
    archive: '/packages/category/archive',
    history: '/packages/category/history',
    view: '/packages/view',
    roadmap: '/packages/roadmap',
    search: '/packages/search',
  },
  profile: {
    root: '/profile',
    addressBook: '/profile/address-book',
  },
};

export default routesSchema;
