import { Icon } from '@components/ui';
import styles from '@components/ui/FormField/FormField.module.scss';
import { classNames } from '@libs/utils';
import {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  ReactElement,
  RefObject,
} from 'react';
import { Oval } from 'react-loader-spinner';

type FormFieldProps = {
  type:
    | 'text'
    | 'email'
    | 'password'
    | 'number'
    | 'textarea'
    | 'select'
    | 'checkbox'
    | 'radio'
    | 'hidden';
  fieldName: string;
  ref?: RefObject<any>;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string | JSX.Element;
  label?: string | JSX.Element;
  icon?: string;
  width?: 25 | 50 | 75 | 100;
  readOnly?: boolean;
  value: string | number;
  isLoading?: boolean;
  onChange?: (value: any) => void | undefined;
  onBlur?: (value: any) => void | undefined;
};

export const FormField: ForwardRefExoticComponent<any> = forwardRef(
  (
    {
      type,
      fieldName,
      required,
      disabled,
      className = '',
      label,
      placeholder,
      icon,
      width = 100,
      readOnly = false,
      value,
      isLoading = false,
      onChange,
      onBlur,
    }: FormFieldProps,
    ref
  ): ReactElement => {
    switch (type) {
      case 'text':
      case 'email':
      case 'password':
      case 'number': {
        return (
          <div
            className={classNames(styles.root, styles[`root__width_${width}`])}
          >
            <div className={styles.root__inner}>
              {label && (
                <label htmlFor={fieldName} className={styles.field__label}>
                  {label}
                </label>
              )}
              <div className={styles.field_wrapper}>
                {icon &&
                  icon?.length > 0 &&
                  (isLoading ? (
                    <div className={styles.field__loader}>
                      {/* <img src='/assets/icons/loader-ios.gif' alt='' /> */}
                      <Oval
                        height={24}
                        width={24}
                        color='var(--color-primary)'
                        wrapperStyle={{}}
                        wrapperClass=''
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor='var(--color-primary)'
                        strokeWidth={6}
                        strokeWidthSecondary={6}
                      />
                    </div>
                  ) : (
                    <div className={styles.field__icon}>
                      <Icon name={icon} />
                    </div>
                  ))}
                <input
                  id={fieldName}
                  ref={ref as ForwardedRef<any>}
                  type={type}
                  placeholder={placeholder as string}
                  disabled={disabled}
                  required={required}
                  value={value}
                  readOnly={readOnly}
                  autoComplete='off'
                  className={classNames(
                    className,
                    styles.field,
                    styles[`field__${type}`],
                    icon && icon?.length > 0 ? styles.field__has_icon : ''
                  )}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </div>
            </div>
          </div>
        );
      }

      default:
        return <></>;
    }
  }
);

// export const FormField: FC<FormControlProps> = ({
//   ref,
//   type,
//   fieldName,
//   required,
//   disabled,
//   className = '',
//   label,
//   placeholder,
//   icon,
//   width = 100,
//   readOnly = false,
//   defaultValue = '',
//   onChange,
//   onBlur,
// }): ReactElement => {
//   switch (type) {
//     case 'text':
//     case 'email':
//     case 'password':
//     case 'number': {
//       return (
//         <div
//           className={classNames(styles.root, styles[`root__width_${width}`])}
//         >
//           <div className={styles.root__inner}>
//             {label && (
//               <label htmlFor={fieldName} className={styles.field__label}>
//                 {label}
//               </label>
//             )}
//             <div className={styles.field_wrapper}>
//               {icon && icon?.length > 0 && (
//                 <div className={styles.field__icon}>
//                   <Icon name={icon} />
//                 </div>
//               )}
//               <input
//                 id={fieldName}
//                 ref={ref}
//                 type={type}
//                 placeholder={placeholder as string}
//                 disabled={disabled}
//                 required={required}
//                 defaultValue={defaultValue}
//                 readOnly={readOnly}
//                 autoComplete='off'
//                 className={classNames(
//                   className,
//                   styles.field,
//                   styles[`field__${type}`],
//                   icon && icon?.length > 0 ? styles.field__has_icon : ''
//                 )}
//                 onChange={onChange}
//                 onBlur={onBlur}
//               />
//             </div>
//           </div>
//         </div>
//       );
//     }

//     default:
//       return <></>;
//   }
// };
