import { FormFeedbackPopup, HttpLoader } from '@components/common';
import styles from '@components/common/UserInfoList/UserInfoList.module.scss';
import { Button, Icon } from '@components/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { classNames, get18YearsAgo } from '@libs/utils';
import updateValidator from '@libs/validators/update.validator';
import {
  selectProfile,
  useUpdateProfileMutation,
} from '@store/services/users/usersService';
import { useAppSelector } from '@store/store';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { useForm } from 'react-hook-form';

type UserInfoListProps = {
  disabled?: boolean;
  onSetDisabled?: (value: boolean) => void;
};

type FormDataType = {
  fullname: string;
  email: string;
  phone: string;
  document_type: string;
  document_number: string;
  birthday: string;
};

const fields = [
  { name: 'Nombre y Apellido', property: 'fullname', type: 'text' },
  { name: 'Correo Electrónico', property: 'email', type: 'email' },
  { name: 'Teléfono', property: 'phone', type: 'text', defaultDisabled: true },
  {
    name: 'Tipo de Documento',
    property: 'document_type',
    type: 'select',
  },
  {
    name: 'Número de Documento',
    property: 'document_number',
    type: 'text',
  },
  {
    name: 'Fecha de nacimiento',
    property: 'birthday',
    type: 'date',
  },
];

const documentTypes = ['DNI', 'CAREX', 'PASSPORT'];

export const UserInfoList: FC<UserInfoListProps> = ({
  disabled = true,
  onSetDisabled,
}): ReactElement => {
  const [feedbackOpened, setFeedbackOpened] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const profile = useAppSelector((state) => selectProfile(state) as any);
  const [birthdate, setBirthdate] = useState<any>();
  const datePickerMaxDate = useMemo((): Date => get18YearsAgo(), []);
  const [updateProfile, { data, isLoading, isSuccess, isError }] =
    useUpdateProfileMutation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(updateValidator()),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: profile,
  });

  watch('document_type');

  useEffect(() => {
    !isLoading && (isSuccess || isError) && setFeedbackOpened(true);
  }, [isLoading, isSuccess, isError]);

  useEffect(() => {
    if (profile && profile.birthday) {
      setBirthdate(new Date(profile.birthday));
    }
  }, [profile]);

  const onSubmit: any = (formData: FormDataType) => {
    const yyyy = birthdate.getFullYear();
    const mm = birthdate.getMonth() + 1;
    const dd = birthdate.getDate();
    const birthday = `${yyyy}-${mm < 10 ? '0' + mm : mm}-${
      dd < 10 ? '0' + dd : dd
    }`;
    const payload = {
      document_type: formData.document_type,
      document_number: formData.document_number,
      fullname: formData.fullname,
      email: formData.email,
      birthday,
    };

    updateProfile(payload);
  };

  return (
    <>
      {profile && (
        <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.root__fieldset}>
            {fields.map(({ name, property, type, defaultDisabled }) => (
              <div key={property} className={styles.root__item}>
                {defaultDisabled ? true : false}
                <div className={styles.root__item__inner}>
                  <h4>{name}:</h4>
                  {(type === 'text' || type === 'email') && (
                    <div className={styles.root__field}>
                      <input
                        {...register(property)}
                        type={type}
                        name={property}
                        disabled={defaultDisabled ? defaultDisabled : disabled}
                      />
                      {errors[property] && (
                        <span className={styles.root__field__error}>
                          {errors[property]?.message as any}
                        </span>
                      )}
                    </div>
                  )}
                  {type === 'select' && (
                    <div
                      className={classNames(
                        styles.root__field,
                        styles.root__select
                      )}
                    >
                      <input
                        {...register(property)}
                        type={type}
                        name={property}
                        disabled={defaultDisabled ? defaultDisabled : disabled}
                        readOnly={true}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                      />
                      {errors[property] && (
                        <span className={styles.root__field__error}>
                          {errors[property]?.message as any}
                        </span>
                      )}
                      <Icon
                        name='x0006'
                        width={12}
                        height={6}
                        classList={classNames(
                          styles.root__select__chevron,
                          isCollapsed
                            ? styles.root__select__chevron__rotated
                            : ''
                        )}
                      />
                      <ul
                        className={classNames(
                          styles.root__select__dropdown,
                          isCollapsed
                            ? styles.root__select__dropdown__collapsed
                            : styles.root__select__dropdown
                        )}
                      >
                        {documentTypes.map((documentType) => (
                          <li
                            key={documentType}
                            onClick={() => {
                              setValue('document_type', documentType);
                              trigger('document_type');
                              trigger('document_number');
                              setIsCollapsed(false);
                            }}
                          >
                            {documentType}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {type === 'date' && (
                    <div
                      className={classNames(
                        styles.root__field,
                        styles.root__date
                      )}
                    >
                      <DateTimePicker
                        className={classNames(
                          'datepicker__field__transformmed',
                          disabled
                            ? 'datepicker__field__transformmed__inactive'
                            : ''
                        )}
                        locale='es-PE'
                        format='y-MM-dd'
                        yearPlaceholder='YYYY'
                        monthPlaceholder='MM'
                        dayPlaceholder='DD'
                        closeWidgets={true}
                        isClockOpen={false}
                        clearIcon={null}
                        calendarIcon={null}
                        maxDate={datePickerMaxDate}
                        value={birthdate}
                        onChange={setBirthdate}
                      />
                      {errors[property] && (
                        <span className={styles.root__field__error}>
                          {errors[property]?.message as any}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          {!disabled && (
            <Button
              type='submit'
              classList={styles.root__submit}
              text='Actualizar'
            />
          )}
        </form>
      )}
      <HttpLoader active={isLoading} />
      <FormFeedbackPopup
        onClose={() => {
          setFeedbackOpened(false);
          typeof onSetDisabled === 'function' && onSetDisabled(true);
        }}
        isOpened={feedbackOpened}
      >
        <div className={styles.popup}>
          {!isLoading && isSuccess && <h6>¡Éxito!</h6>}
          {!isLoading && isError && <h6>Error!</h6>}
          {<p>{data?.message}</p>}
        </div>
      </FormFeedbackPopup>
    </>
  );
};
