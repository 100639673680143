import styles from '@components/common/Locations/Locations.module.scss';
import { Icon } from '@components/ui';
import { classNames } from '@libs/utils';
import { IAddress } from '@store/services/addresses/types';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

type LocationsProps = {
  list: IAddress[];
  isSimple?: boolean;
  disableLink?: boolean;
};

export const Locations: FC<LocationsProps> = ({
  list = [],
  isSimple = false,
  disableLink = false,
}): ReactElement => {
  return (
    <ul
      className={classNames(styles.root, isSimple ? styles.root__simple : '')}
    >
      {list.map(({ id, name, address }) => (
        <li
          key={id}
          className={classNames(
            styles.root__item,
            isSimple ? styles.root__item__simple : ''
          )}
        >
          {disableLink ? (
            <div
              className={classNames(
                styles.root__item__inner,
                styles.root__item__inner__simple
              )}
            >
              <h3
                className={classNames(
                  styles.root__item__name,
                  styles.root__item__name__simple
                )}
              >
                {name}
              </h3>
              <p className={styles.root__item__address}>{address}</p>
            </div>
          ) : (
            <Link to={'edit/' + id} className={styles.root__item__inner}>
              <h3 className={styles.root__item__name}>{name}</h3>
              <p className={styles.root__item__address}>{address}</p>
            </Link>
          )}
          <button type='button' className={styles.root__item__action}>
            <Icon name='x0014' />
          </button>
        </li>
      ))}
    </ul>
  );
};
