const PREFIX = 'savarx.pwa';

const addPrefix = (key: string) => `${PREFIX}.${key}`;

export const localStorageUtil = {
  setItem(key: string, value: any) {
    window.localStorage.setItem(addPrefix(key), JSON.stringify(value));
  },

  getItem(key: string): any {
    const item = window.localStorage.getItem(addPrefix(key));
    if (item) {
      return JSON.parse(item);
    }
    return null;
  },

  removeItem(key: string) {
    window.localStorage.removeItem(addPrefix(key));
  },

  clear() {
    // Clear only the items that start with the prefix
    Object.keys(window.localStorage)
      .filter((itemKey) => itemKey.startsWith(PREFIX))
      .forEach((itemKey) => {
        window.localStorage.removeItem(itemKey);
      });
  },
};

export const sessionStorageUtil = {
  setItem(key: string, value: any) {
    window.sessionStorage.setItem(addPrefix(key), JSON.stringify(value));
  },

  getItem(key: string): any {
    const item = window.sessionStorage.getItem(addPrefix(key));
    if (item) {
      return JSON.parse(item);
    }
    return null;
  },

  removeItem(key: string) {
    window.sessionStorage.removeItem(addPrefix(key));
  },

  clear() {
    // Clear only the items that start with the prefix
    Object.keys(window.sessionStorage)
      .filter((itemKey) => itemKey.startsWith(PREFIX))
      .forEach((itemKey) => {
        window.sessionStorage.removeItem(itemKey);
      });
  },
};
