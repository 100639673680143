import styles from '@components/common/UserCard/UserCard.module.scss';
import routesSchema from '@libs/routes-schema';
import { classNames } from '@libs/utils';
import { localStorageUtil, sessionStorageUtil } from '@libs/utils/storageUtils';
import {
  removeTokens,
  selectAuthProfile,
} from '@store/features/auth/authSlice';
import { useLogoutMutation } from '@store/services/users/usersService';
import {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

type UserCardProps = {
  type: 'mobile' | 'desktop';
  size?: 'xlg' | 'lg' | 'md' | 'sm';
};

export const UserCard: FC<UserCardProps> = ({
  type,
  size = 'md',
}): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [avatarExist, setAvatarExist] = useState<boolean>(false);
  const [logoutUser, { isLoading, isSuccess }] = useLogoutMutation();
  const profile = useSelector(selectAuthProfile);

  const avatarSize = useMemo((): string => {
    switch (size) {
      case 'xlg':
        return '134';

      case 'lg':
        return '114';

      case 'md':
        return '74';

      default:
        return '74';
    }
  }, []);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      localStorageUtil.removeItem('accessToken');
      localStorageUtil.removeItem('refreshToken');
      sessionStorageUtil.removeItem('accessToken');
      sessionStorageUtil.removeItem('refreshToken');
    }
  }, [isLoading, isSuccess]);

  const logout = async () => {
    try {
      const refresh = (localStorageUtil.getItem('refreshToken') ||
        sessionStorageUtil.getItem('refreshToken')) as string;
      if (refresh) {
        logoutUser({ refresh });
        dispatch(removeTokens());
      }
    } catch (error) {
      navigate(0);
    }
  };

  if (!isLoading && isSuccess) {
    return (
      <Navigate to='/auth/login' replace={true} state={{ from: location }} />
    );
  }

  return profile ? (
    <div
      className={classNames(
        'fx',
        'fx-align-center',
        styles.root,
        styles[`root__${type}`]
      )}
    >
      <div className={type === 'mobile' ? styles.side__left : styles.side__top}>
        <div className={classNames(styles.avatar, styles[`avatar__${size}`])}>
          <div
            className={classNames(
              styles.avatar__image,
              !avatarExist ? styles.avatar__image__none : ''
            )}
          >
            {avatarExist ? (
              <img
                className='x-img cover abs center'
                src='/assets/images/avatar.jpg'
                alt='alt'
              />
            ) : (
              <Avatar
                name={profile.fullname || ''}
                size={avatarSize}
                color='var(--color-white)'
                fgColor='var(--color-mirage'
                maxInitials={3}
                style={{
                  fontFamily: 'var(--default-font-family)',
                  fontWeight: 'var(--bold)',
                  color: 'var(--color-mirage)',
                  transform: !profile.is_active ? 'translateY(-4px)' : 'unset',
                }}
              />
            )}
            {!profile.is_active && (
              <span className={styles.avatar__profile_status}>
                Inconfirmado
              </span>
            )}
          </div>
          {/* <button
            className={styles.avatar__edit}
            onClick={() => setAvatarExist(!avatarExist)}
          >
            <Icon name='x0029' width={12} height={13} />
          </button> */}
        </div>
      </div>
      <div
        className={classNames(
          type === 'mobile' ? styles.side__right : styles.side__bottom,
          type === 'mobile' ? styles[`side__right__${size}`] : ''
        )}
      >
        <h3
          className={classNames(styles.fullname, styles[`fullname__${type}`])}
        >
          <Link to='/profile'>{profile?.fullname || ''}</Link>
        </h3>
        <div className={classNames(styles.meta, styles[`meta__${type}`])}>
          <Link
            to={routesSchema.profile.root}
            className={classNames(
              styles.meta__email,
              styles[`meta__email__${type}`]
            )}
          >
            {profile.email}
          </Link>
        </div>
        {!profile.is_active && (
          <Link
            to='/profile/verify'
            className={classNames(styles.link, styles.link__verify)}
          >
            Verificar
          </Link>
        )}
        <button
          type='button'
          className={classNames(styles.link, styles.link__logout)}
          onClick={logout}
        >
          Cerrar sesión
        </button>
        <Link
          to='/profile'
          className={classNames(styles.link, styles.link__profile)}
        >
          Ver perfil
        </Link>
      </div>
    </div>
  ) : (
    <Fragment />
  );
};
