// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckForUpdates_root__5fSRG {
  width: 100%;
  height: 100%;
  padding: 15px;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1090;
}
.CheckForUpdates_root__active__KhxVd {
  pointer-events: all;
}
.CheckForUpdates_root__overlay__UewRV {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms var(--a);
  position: absolute;
  top: 0;
  left: 0;
}
.CheckForUpdates_root__overlay__active__2M2iS {
  background-color: rgba(0, 0, 0, 0.3);
}
.CheckForUpdates_root__content__MZpXK {
  max-width: 420px;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 20px 15px 15px;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms var(--a), transform 300ms var(--a);
  position: relative;
  z-index: 2;
}
.CheckForUpdates_root__content__active__uGZOq {
  opacity: 1;
  transform: scale(1);
}
.CheckForUpdates_root__content__text__aYkif {
  text-align: center;
  margin: 0 0 20px;
}
.CheckForUpdates_root__content__MZpXK h3 {
  font-size: 20px;
  color: var(--color-mirage);
  margin: 0 0 5px;
}
.CheckForUpdates_root__content__MZpXK p {
  font-size: 14px;
  color: var(--color-gray);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/CheckForUpdates/CheckForUpdates.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;AAAF;AACE;EACE,mBAAA;AACJ;AACE;EACE,WAAA;EACA,YAAA;EACA,kCAAA;EACA,2CAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AACJ;AAAI;EACE,oCAAA;AAEN;AACE;EACE,gBAAA;EACA,WAAA;EACA,oCAAA;EACA,6BAAA;EACA,mCAAA;EACA,uBAAA;EACA,UAAA;EACA,qBAAA;EACA,4DAAA;EACA,kBAAA;EACA,UAAA;AACJ;AAAI;EACE,UAAA;EACA,mBAAA;AAEN;AAAI;EACE,kBAAA;EACA,gBAAA;AAEN;AAAI;EACE,eAAA;EACA,0BAAA;EACA,eAAA;AAEN;AAAI;EACE,eAAA;EACA,wBAAA;AAEN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  height: 100%;\n  padding: 15px;\n  pointer-events: none;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 1090;\n  &__active {\n    pointer-events: all;\n  }\n  &__overlay {\n    width: 100%;\n    height: 100%;\n    background-color: set-color(--color-black, 0);\n    transition: background-color 300ms var(--a);\n    position: absolute;\n    top: 0;\n    left: 0;\n    &__active {\n      background-color: set-color(--color-black, 0.3);\n    }\n  }\n  &__content {\n    max-width: 420px;\n    width: 100%;\n    background-color: var(--color-white);\n    box-shadow: var(--box-shadow);\n    border-radius: var(--border-radius);\n    padding: 20px 15px 15px;\n    opacity: 0;\n    transform: scale(0.9);\n    transition: opacity 300ms var(--a), transform 300ms var(--a);\n    position: relative;\n    z-index: 2;\n    &__active {\n      opacity: 1;\n      transform: scale(1);\n    }\n    &__text {\n      text-align: center;\n      margin: 0 0 20px;\n    }\n    h3 {\n      font-size: 20px;\n      color: var(--color-mirage);\n      margin: 0 0 5px;\n    }\n    p {\n      font-size: 14px;\n      color: var(--color-gray);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CheckForUpdates_root__5fSRG`,
	"root__active": `CheckForUpdates_root__active__KhxVd`,
	"root__overlay": `CheckForUpdates_root__overlay__UewRV`,
	"root__overlay__active": `CheckForUpdates_root__overlay__active__2M2iS`,
	"root__content": `CheckForUpdates_root__content__MZpXK`,
	"root__content__active": `CheckForUpdates_root__content__active__uGZOq`,
	"root__content__text": `CheckForUpdates_root__content__text__aYkif`
};
export default ___CSS_LOADER_EXPORT___;
