import styles from '@components/common/DeleteAddressDialog/DeleteAddressDialog.module.scss';
import { Button, ButtonGroup } from '@components/ui';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { classNames, delayFn } from '@libs/utils';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';

type DeleteAddressDialogProps = {};

export const DeleteAddressDialog: FC<DeleteAddressDialogProps> = ():
  | ReactElement
  | any => {
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [addressID, setAddressID] = useState<string | null>(null);
  const { getStorageItem, removeStorageItem } = useLocalStorage();
  const interval = useMemo(
    () =>
      setInterval(() => {
        const addressID = getStorageItem('addressID');
        setAddressID(addressID);
      }, 500),
    []
  );

  useEffect(() => {
    if (addressID && addressID?.length > 0) {
      setHasMounted(true);
      delayFn(() => setShow(true), 500);
    } else {
      setShow(false);
      delayFn(() => setHasMounted(false), 500);
    }
  }, [addressID]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  function onClose() {
    removeStorageItem('addressID');
  }

  return (
    hasMounted && (
      <div
        className={classNames(
          'fx-align-center',
          'fx-content-center',
          'popup',
          styles.root,
          show ? 'popup__active' : ''
        )}
      >
        <div
          className={classNames(
            'fx-align-center',
            'fx-content-center',
            'popup__inner',
            styles.root__inner
          )}
        >
          <div className={styles.root__description}>
            <h4>¿Eliminar esta dirección?</h4>
            <p>Esta operación eliminará la dirección sin recuperarla.</p>
          </div>
          <ButtonGroup style={{ width: '100%' }}>
            <Button type='button' text='Cancelar' onClick={onClose} />
            <Button
              type='button'
              text='Borrar'
              style={{ color: 'sunset-orange' }}
            />
          </ButtonGroup>
        </div>
      </div>
    )
  );
};
