import { useLayoutEffect, useState } from 'react';
import { PlaceType } from '@interfaces/map.interface';

type OptionsType = {
  fieldId: string;
};

export const useMapSearchBox = (
  options: OptionsType = { fieldId: 'search' }
) => {
  const [fieldElement, setFieldElement] = useState<HTMLInputElement>();
  const [place, setPlace] = useState<PlaceType>();

  useLayoutEffect(() => {
    const fieldEl = document.getElementById(
      options.fieldId
    ) as HTMLInputElement;

    fieldEl && setFieldElement(fieldEl);

    window.onload = () => {
      if (window.google.maps.places && fieldElement) {
        const searchBox = new window.google.maps.places.SearchBox(fieldElement);

        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();
          const data = places?.shift();

          if (data) {
            setPlace({
              address: data?.formatted_address,
              coords: {
                lat: data?.geometry?.location?.lat(),
                lng: data?.geometry?.location?.lng(),
              },
            });
          }
        });
      }
    };
  }, [fieldElement?.value]);

  return { searchBoxPlace: place, setSearchBoxPlace: setPlace };
};
