import { FC, ReactElement } from 'react';
import parse from 'html-react-parser';
import { classNames } from '@libs/utils';
import styles from '@components/common/AuthHead/AuthHead.module.scss';

type AuthHeadProps = {
  headline: string;
  description?: string;
  imageSrc?: string;
  imageAlt?: string;
};

export const AuthHead: FC<AuthHeadProps> = ({
  headline,
  description,
  imageSrc = '/assets/images/auth-bg-image.jpg',
  imageAlt,
}): ReactElement => (
  <div className={classNames('fx-align-end', styles.root)}>
    <img
      className='x-img cover abs center'
      src={imageSrc}
      alt={imageAlt && imageAlt?.length > 0 ? imageAlt : headline}
    />
    <div>
      <h1 className={styles.headline}>{parse(headline || '')}</h1>
      {description && description?.length > 0 && (
        <div className={styles.description}>
          <p>{description}</p>
        </div>
      )}
    </div>
  </div>
);
