import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styles from '@components/common/CallToAction/CallToAction.module.scss';
import { classNames } from '@libs/utils';

type CallToActionProps = {
  headline?: string;
  href?: string;
  label?: string;
  classList?: string;
};

export const CallToAction: FC<CallToActionProps> = ({
  headline,
  href,
  label,
  classList = '',
}): ReactElement => (
  <div className={classNames(styles.root, classList)}>
    {headline && (
      <div className={styles.headline}>
        <h6>{headline}</h6>
      </div>
    )}
    {href && label && (
      <Link to={href} className={styles.link}>
        {label}
      </Link>
    )}
  </div>
);
