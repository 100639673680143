// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FaqItem_root__Qb5JL {
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin: 0 0 15px;
}
.FaqItem_root__Qb5JL:last-child {
  margin: 0;
}
.FaqItem_root__head__phsYr {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: var(--color-mirage);
  border-radius: var(--border-radius);
  padding: 15px 5px 15px 15px;
}
.FaqItem_root__head__text__0CkEf {
  display: block;
  max-width: calc(100% - 32px);
  width: 100%;
  font-weight: var(--medium);
  font-size: 16px;
  color: var(--color-mirage);
  text-align: left;
}
.FaqItem_root__head__icon__pwl7F {
  width: 24px;
  height: 24px;
  position: relative;
}
.FaqItem_root__head__icon__pwl7F div {
  background-color: var(--color-cadet-blue);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.FaqItem_root__head__icon__pwl7F div:first-of-type {
  width: 14px;
  height: 2px;
}
.FaqItem_root__head__icon__pwl7F div:last-of-type {
  width: 2px;
  height: 14px;
}
.FaqItem_root__body__Saxei {
  overflow: hidden;
}
.FaqItem_root__body__inner__kGI9A {
  margin-top: -10px;
  padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/FaqGroup/FaqItem/FaqItem.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,oCAAA;EACA,mCAAA;EACA,6BAAA;EACA,gBAAA;AAAF;AACE;EACE,SAAA;AACJ;AACE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,2BAAA;EACA,WAAA;EACA,0BAAA;EACA,mCAAA;EACA,2BAAA;AACJ;AAAI;EACE,cAAA;EACA,4BAAA;EACA,WAAA;EACA,0BAAA;EACA,eAAA;EACA,0BAAA;EACA,gBAAA;AAEN;AAAI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAEN;AADM;EACE,yCAAA;EACA,gCAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;AAGR;AAFQ;EACE,WAAA;EACA,WAAA;AAIV;AAFQ;EACE,UAAA;EACA,YAAA;AAIV;AACE;EACE,gBAAA;AACJ;AAAI;EACE,iBAAA;EACA,aAAA;AAEN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  background-color: var(--color-white);\n  border-radius: var(--border-radius);\n  box-shadow: var(--box-shadow);\n  margin: 0 0 15px;\n  &:last-child {\n    margin: 0;\n  }\n  &__head {\n    display: flex;\n    flex-flow: row nowrap;\n    align-items: center;\n    justify-content: flex-start;\n    width: 100%;\n    color: var(--color-mirage);\n    border-radius: var(--border-radius);\n    padding: 15px 5px 15px 15px;\n    &__text {\n      display: block;\n      max-width: calc(100% - 32px);\n      width: 100%;\n      font-weight: var(--medium);\n      font-size: 16px;\n      color: var(--color-mirage);\n      text-align: left;\n    }\n    &__icon {\n      width: 24px;\n      height: 24px;\n      position: relative;\n      div {\n        background-color: var(--color-cadet-blue);\n        transform: translate(-50%, -50%);\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        &:first-of-type {\n          width: 14px;\n          height: 2px;\n        }\n        &:last-of-type {\n          width: 2px;\n          height: 14px;\n        }\n      }\n    }\n  }\n  &__body {\n    overflow: hidden;\n    &__inner {\n      margin-top: -10px;\n      padding: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FaqItem_root__Qb5JL`,
	"root__head": `FaqItem_root__head__phsYr`,
	"root__head__text": `FaqItem_root__head__text__0CkEf`,
	"root__head__icon": `FaqItem_root__head__icon__pwl7F`,
	"root__body": `FaqItem_root__body__Saxei`,
	"root__body__inner": `FaqItem_root__body__inner__kGI9A`
};
export default ___CSS_LOADER_EXPORT___;
