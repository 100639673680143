import { JSXElementConstructor, ReactElement } from 'react';
import { Link } from 'react-router-dom';

export const getStrToLink = (
  str: string,
  wrap?: 'a' | 'Link'
): ReactElement<any, string | JSXElementConstructor<any>>[] | string => {
  const linkRegex = /\[(.*?)\]\((.*?)\)/g;
  let result: any = [];
  let lastIndex = 0;
  let match;

  while ((match = linkRegex.exec(str)) !== null) {
    const fullMatch = match[0];
    const linkText = match[1];
    const linkAddress = match[2];
    const startIndex = match.index;

    if (startIndex > lastIndex) {
      const plainText = str.substring(lastIndex, startIndex);
      result.push(plainText as any);
    }

    if (wrap === 'a') {
      result.push(
        <a key={fullMatch} href={linkAddress}>
          {linkText}
        </a>
      );
    } else {
      result.push(
        <Link key={fullMatch} to={linkAddress}>
          {linkText}
        </Link>
      );
    }

    lastIndex = startIndex + fullMatch.length;
  }

  if (lastIndex < str.length) {
    const remainingText = str.substring(lastIndex);
    result.push(remainingText);
  }

  return result;
};
