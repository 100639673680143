import { useLayoutEffect, useState } from 'react';

export const useAppState = () => {
  const [installed, setInstalled] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const standalone = window.matchMedia(
        '(display-mode: standalone)'
      ).matches;

      setInstalled(standalone);
    }
  }, [window]);

  return {
    appInstalled: installed,
  };
};
