// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FaqGroup_root__C2q12 {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/FaqGroup/FaqGroup.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;AAAF","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FaqGroup_root__C2q12`
};
export default ___CSS_LOADER_EXPORT___;
