import * as Yup from 'yup';

const updateValidator = () =>
  Yup.object().shape({
    fullname: Yup.string()
      .required('Se requiere campo.')
      .matches(/^[a-zA-Z\s]+$/, 'El valor proporcionado no es válido.')
      .min(
        3,
        'El valor proporcionado debe tener al menos 3 caracteres de longitud.'
      ),
    email: Yup.string()
      .required('Se requiere campo.')
      .email('El valor del campo no es válido.'),
    phone: Yup.string()
      .required('Se requiere campo.')
      .min(6, 'El teléfono debe tener al menos 5 números.')
      .matches(/^[\d\(\)\s\-+]+$/m, 'El número de teléfono no es válido.'),
    document_type: Yup.string()
      .required('Se requiere campo.')
      .matches(/([^\s])/, 'El valor no es válido.')
      .oneOf(
        ['DNI', 'CAREX', 'PASSPORT'],
        'El tipo de documento no es válido.'
      ),
    document_number: Yup.string()
      .required('Se requiere campo.')
      .test('document_number', 'El valor no es válido.', (value, r) => {
        if (r.parent.document_type === 'DNI') {
          return /^\d{8}$/.test(value as string);
        }

        return /^[a-zA-Z0-9]{8}$/.test(value as string);
      }),
    birthday: Yup.date().required('Se requiere campo.'),
  });

export default updateValidator;
