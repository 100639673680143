import { MutableRefObject, useEffect, useState } from 'react';

export const useOutsideClick = (
  ref: MutableRefObject<any>,
  reverse: boolean = false
) => {
  const [outside, setOutside] = useState<boolean>(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      setTimeout(() => {
        const isOutside =
          ref.current && !ref.current.contains(event.target as any);
        setOutside(isOutside);
      }, 200);
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return outside;
};
