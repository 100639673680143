import styles from '@components/common/TextContent/TextContent.module.scss';
import { classNames } from '@libs/utils';
import parse from 'html-react-parser';
import { FC, Fragment, ReactElement } from 'react';

type TextContentProps = {
  text: string | undefined;
  stylizeContainer?: boolean;
};

export const TextContent: FC<TextContentProps> = ({
  text,
  stylizeContainer = false,
}): ReactElement =>
  text && text?.length > 0 ? (
    <div
      className={classNames(
        styles.root,
        stylizeContainer ? styles.root__stylized : ''
      )}
    >
      {parse(text || '')}
    </div>
  ) : (
    <Fragment />
  );
