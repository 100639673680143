// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormFeedbackPopup_root__QdQlk {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1090;
}
.FormFeedbackPopup_root__active__i8Rht {
  pointer-events: all;
}

.FormFeedbackPopup_close__wKG5P {
  transform: translate(-10px, 10px);
  position: absolute;
  top: 0;
  right: 0;
}
.FormFeedbackPopup_close__wKG5P:hover [stroke*="#"] {
  stroke: var(--color-primary);
}
.FormFeedbackPopup_close__wKG5P svg * {
  transition: var(--def-transition-300ms);
}

.FormFeedbackPopup_overlay__gZpkN {
  width: 100%;
  height: 100%;
  background-color: rgba(19, 23, 39, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}

.FormFeedbackPopup_content__HtXNS {
  display: block;
  max-width: 360px;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/FormFeedbackPopup/FormFeedbackPopup.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,oBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;AAAF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,iCAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;AAAF;AAEI;EACE,4BAAA;AAAN;AAII;EACE,uCAAA;AAFN;;AAOA;EACE,WAAA;EACA,YAAA;EACA,uCAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AAJF;;AAOA;EACE,cAAA;EACA,gBAAA;EACA,WAAA;EACA,oCAAA;EACA,mCAAA;EACA,6BAAA;EACA,aAAA;EACA,kBAAA;AAJF","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  padding: 0 15px;\n  pointer-events: none;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 1090;\n  &__active {\n    pointer-events: all;\n  }\n}\n\n.close {\n  transform: translate(-10px, 10px);\n  position: absolute;\n  top: 0;\n  right: 0;\n  &:hover {\n    [stroke*='#'] {\n      stroke: var(--color-primary);\n    }\n  }\n  svg {\n    * {\n      transition: var(--def-transition-300ms);\n    }\n  }\n}\n\n.overlay {\n  width: 100%;\n  height: 100%;\n  background-color: set-color(--color-mirage, 0.6);\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.content {\n  display: block;\n  max-width: 360px;\n  width: 100%;\n  background-color: var(--color-white);\n  border-radius: var(--border-radius);\n  box-shadow: var(--box-shadow);\n  padding: 20px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FormFeedbackPopup_root__QdQlk`,
	"root__active": `FormFeedbackPopup_root__active__i8Rht`,
	"close": `FormFeedbackPopup_close__wKG5P`,
	"overlay": `FormFeedbackPopup_overlay__gZpkN`,
	"content": `FormFeedbackPopup_content__HtXNS`
};
export default ___CSS_LOADER_EXPORT___;
