import styles from '@components/common/PickAddress/Popup/Popup.module.scss';
import { Icon } from '@components/ui';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { classNames, delayFn } from '@libs/utils';
import { FC, ReactElement, useLayoutEffect, useState } from 'react';

type PopupProps = {
  text: string;
  dismiss?:
    | {
        text: string;
        storage: { key: string; value: string };
      }
    | undefined;
  delay?: number;
  hasClose?: boolean;
};

export const Popup: FC<PopupProps> = ({
  text,
  dismiss,
  delay = 1000,
  hasClose = true,
}): ReactElement => {
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const { setStorageItem, getStorageItem } = useLocalStorage();

  useLayoutEffect(() => {
    const shouldMount = getStorageItem(dismiss?.storage.key as string);

    if (!shouldMount) {
      setHasMounted(true);
      setTimeout(() => setActive(true), delay);
      delayFn(() => setActive(true), delay);
    }
  }, []);

  function onDismiss() {
    if (dismiss) {
      const { storage } = dismiss;

      setStorageItem(storage.key, storage.value);
      setActive(false);
      delayFn(() => setHasMounted(false), 500);
    }
  }

  return (
    <>
      {hasMounted && (
        <div
          className={classNames(styles.root, active ? styles.root__active : '')}
        >
          <div
            className={classNames(
              'fx',
              'fx-nowrap',
              'fx-align-center',
              styles.root__inner,
              !hasClose ? styles.root__inner__symetric : ''
            )}
          >
            <Icon name='x0018' />
            <div>
              <p>{text}</p>
              {dismiss && (
                <>
                  &nbsp;
                  <button type='button' onClick={onDismiss}>
                    {dismiss.text}
                  </button>
                </>
              )}
            </div>
            {hasClose && (
              <button
                type='button'
                className={styles.root__close}
                onClick={() => setActive(false)}
              >
                <Icon name='x0019' />
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
