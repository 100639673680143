import styles from '@components/ui/FormGroup/FormGroup.module.scss';
import { classNames } from '@libs/utils';
import { FC, ReactElement } from 'react';

type FormGroupProps = {
  children: ReactElement | ReactElement[];
  classList?: string;
};

export const FormGroup: FC<FormGroupProps> = ({
  children,
  classList = '',
}): ReactElement => (
  <div className={classNames(styles.root, classList)}>{children}</div>
);
