// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columns_cell__XqHBG {
  font-weight: var(--regular);
  font-size: 14px;
  color: var(--color-mirage);
}
.columns_cell__code__tHkgd, .columns_cell__address__4oayd {
  font-weight: var(--semi-bold);
  color: var(--color-primary);
  transition: color var(--def-transition-300ms);
}
.columns_cell__code__tHkgd:hover, .columns_cell__address__4oayd:hover {
  color: var(--color-accent);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/PackageList/columns/columns.module.scss"],"names":[],"mappings":"AACA;EACE,2BAAA;EACA,eAAA;EACA,0BAAA;AAAF;AACE;EAEE,6BAAA;EACA,2BAAA;EACA,6CAAA;AAAJ;AACI;EACE,0BAAA;AACN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.cell {\n  font-weight: var(--regular);\n  font-size: 14px;\n  color: var(--color-mirage);\n  &__code,\n  &__address {\n    font-weight: var(--semi-bold);\n    color: var(--color-primary);\n    transition: color var(--def-transition-300ms);\n    &:hover {\n      color: var(--color-accent);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `columns_cell__XqHBG`,
	"cell__code": `columns_cell__code__tHkgd`,
	"cell__address": `columns_cell__address__4oayd`
};
export default ___CSS_LOADER_EXPORT___;
