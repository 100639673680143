import { STYLE_SHEETS_IDS } from '@libs/constants';
import { setStyleSheetVars } from '@libs/utils';
import { selectRouterPathname } from '@store/features/router';
import { useAppSelector } from '@store/store';
import { useLayoutEffect } from 'react';
import { useAppState } from './useAppState';

type Props = {
  ref: any;
};

export const useSetBottomBarParams = (ref: any, deps: any[]) => {
  const { appInstalled } = useAppState();
  const { pathname } = useAppSelector(selectRouterPathname);

  useLayoutEffect(() => {
    if (ref && ref?.current) {
      const element = ref.current as HTMLElement;

      if (element && element.clientHeight) {
        const vars = {
          bottomNavBarHeight: element.clientHeight + 'px',
        };
        setStyleSheetVars(vars, STYLE_SHEETS_IDS.bottomNavBar);
      }
    }

    return () => {
      const vars = {
        bottomNavBarHeight: '0px',
      };

      setStyleSheetVars(vars, STYLE_SHEETS_IDS.bottomNavBar);
    };
  }, [pathname, ...deps]);

  const isActive = (path: string) => {
    const pathName = getRoutePathName() || '/home';
    const activePathName = path === '/' ? '/home' : path;

    return path.includes(pathName) || pathName === activePathName;
  };

  const getRoutePathName = () => {
    return pathname.split('/').map((p) => p.replace('/', ''))[1];
  };

  return { appInstalled, isActive };
};
