// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonGroup_root__MLUvT {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 -7.5px;
}
.ButtonGroup_root__MLUvT > div {
  width: 100%;
  padding: 0 7.5px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/ui/ButtonGroup/ButtonGroup.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,gBAAA;AAAF;AACE;EACE,WAAA;EACA,gBAAA;AACJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  margin: 0 -7.5px;\n  & > div {\n    width: 100%;\n    padding: 0 7.5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ButtonGroup_root__MLUvT`
};
export default ___CSS_LOADER_EXPORT___;
