import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import {
  IGeneralResponse,
  IPackagesCategory,
} from '@store/services/categories/types';

const BASE_URL =
  `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SUFFIX}` as string;
const uri = {
  list: '/list/',
};

/**
 * Slise / Api
 */
export const categoriesService = createApi({
  tagTypes: ['Categories'],
  reducerPath: 'categoriesService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/categories`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    fetchCategories: builder.query<IPackagesCategory[], any>({
      query: () => {
        return {
          url: uri.list,
          method: 'GET',
        };
      },
      transformResponse: (
        result: IGeneralResponse
      ): IPackagesCategory[] | Promise<IPackagesCategory[]> =>
        result?.data || null,
    }),
  }),
});

export const { useFetchCategoriesQuery } = categoriesService;
