import { getDateTime } from '@libs/utils';
import { useEffect, useState } from 'react';

export const usePackageEstimatedDateTime = (
  estimatedDateTime: string | undefined,
  status: string | undefined
) => {
  const [dateTime, setDateTime] = useState<string | undefined>();
  const [isRescheduling, setIsRescheduling] = useState<boolean>(false);

  useEffect(() => {
    if (estimatedDateTime && status) {
      const dateTimeNow = getDateTime(
        new Date().toISOString(),
        'd MMMM u',
        'es_PE'
      );
      const packageDateTime = getDateTime(
        estimatedDateTime,
        'd MMMM u',
        'es_PE'
      );

      if (dateTimeNow && packageDateTime) {
        const isDelivered = status === 'delivered';
        setIsRescheduling(dateTimeNow > packageDateTime && !isDelivered);
      }
    }
  }, [estimatedDateTime, status]);

  return {
    packageEstimatedDateTime: dateTime,
    packageIsRescheduling: isRescheduling,
    packageStatus: status,
  };
};
