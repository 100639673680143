// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_root__8aobn {
  width: 100%;
  padding: 15px;
}
.Form_root__8aobn .Form_field__a5V3x {
  width: 100%;
  margin: 0 !important;
}
.Form_root__8aobn .Form_field__a5V3x > div {
  padding: 0;
}
.Form_root__8aobn .Form_field__inner__EggI- {
  border-color: var(--color-primary);
}
.Form_root__8aobn .Form_field__a5V3x input {
  padding-top: 14px;
  padding-bottom: 14px;
}
.Form_root__8aobn button[type=button] {
  width: auto;
  margin: 0 0 0 15px;
  padding-left: 30px;
  padding-right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/AddressBook/Form/Form.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,aAAA;AAAF;AACE;EACE,WAAA;EACA,oBAAA;AACJ;AAAI;EACE,UAAA;AAEN;AAAI;EACE,kCAAA;AAEN;AAAI;EACE,iBAAA;EACA,oBAAA;AAEN;AAGI;EACE,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;AADN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  padding: 15px;\n  .field {\n    width: 100%;\n    margin: 0 !important;\n    & > div {\n      padding: 0;\n    }\n    &__inner {\n      border-color: var(--color-primary);\n    }\n    input {\n      padding-top: 14px;\n      padding-bottom: 14px;\n    }\n  }\n\n  button {\n    &[type='button'] {\n      width: auto;\n      margin: 0 0 0 15px;\n      padding-left: 30px;\n      padding-right: 30px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Form_root__8aobn`,
	"field": `Form_field__a5V3x`,
	"field__inner": `Form_field__inner__EggI-`
};
export default ___CSS_LOADER_EXPORT___;
