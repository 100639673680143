import { PackageStatus, SuspenseLoader } from '@components/common';
import styles from '@components/common/PackageDetailsDesktop/PackageDetailsDesktop.module.scss';
import { Icon } from '@components/ui';
import { usePackageEstimatedDateTime } from '@hooks/usePackageEstimatedDateTime';
import { classNames, getPackageStatus, getStatusIconBySlug } from '@libs/utils';
import { setPageTitle } from '@store/features/meta/meta.slice';
import { useFetchPackageQuery } from '@store/services/packages/packagesService';
import { useAppDispatch } from '@store/store';
import {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

type PackageDetailsDesktopProps = {
  code: string;
};

export const PackageDetailsDesktop: FC<PackageDetailsDesktopProps> = ({
  code,
}): ReactElement => {
  const dispatch = useAppDispatch();
  const imageBaseUrl = useMemo(
    (): string => process.env.REACT_APP_SAVARX_IMAGE_BASE_URL || '',
    []
  );
  const [tooltipTimeout, setTooltipTimeout] = useState<any>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { data, isLoading, isSuccess } = useFetchPackageQuery(
    { code },
    { skip: false }
  );
  const { packageIsRescheduling } = usePackageEstimatedDateTime(
    data?.package?.EntregaDate,
    data?.package?.CodPaquete
  );
  const status = getPackageStatus(data?.package.CodEstadoPaquete);
  const roadmap = useMemo(() => {
    if (typeof data?.roadmap !== 'undefined' && Array.isArray(data?.roadmap)) {
      return data.roadmap.slice().reverse();
    }

    return [];
  }, [data]);

  useEffect(() => {
    dispatch(setPageTitle('Entrega: ' + code));
  }, []);

  const toggleTooltip = () => {
    tooltipTimeout && clearTimeout(tooltipTimeout);

    setShowTooltip(true);

    setTooltipTimeout(
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500)
    );
  };

  if (isLoading) {
    return <SuspenseLoader position='absolute' />;
  }

  return data?.package ? (
    <div className={styles.root}>
      <div className={classNames('fx-align-center', styles.root__head)}>
        <PackageStatus type='icon' value={status} />
        <h1 className={styles.root__head__code}>{data?.package.CodPaquete}</h1>
        <button type='button' className={styles.root__head__copy}>
          {showTooltip && (
            <div className={styles.root__head__copy__tooltip}>Copiado</div>
          )}
          <CopyToClipboard text={code} onCopy={toggleTooltip}>
            <Icon name='x0026' width={24} height={24} />
          </CopyToClipboard>
        </button>
      </div>
      <div className={classNames('fx', styles.root__meta)}>
        <div className={styles.root__meta__col}>
          <div>
            <h3>Estado de entrega:</h3>
            <p>
              <span
                className={classNames(
                  styles.root__meta__col__text__bold,
                  styles[`root__meta__col__text__${status?.slug}`]
                )}
              >
                {status?.text || 'Pendiente...'}
              </span>
            </p>
          </div>
        </div>
        {/* <div className={styles.root__meta__col}>
          <div>
            {status?.slug === 'delivered' ? (
              <h3>Entregado en:</h3>
            ) : (
              <h3>Entrega estimada:</h3>
            )}
            <p>
              {packageIsRescheduling ? (
                <span className={styles.root__meta__col__reschedule}>
                  <b>Reprogramando...</b>
                  <p>Contactar con el Centro de</p>
                  <Link to='/info/customer-support'> Atención al Cliente</Link>
                </span>
              ) : data.package.EntregaDate !== '-' ? (
                data.package.EntregaDate
              ) : (
                <span className={styles.root__meta__col__pending}>
                  No está definido
                </span>
              )}
            </p>
          </div>
        </div> */}
        <div className={styles.root__meta__col}>
          <div>
            <h3>Cliente:</h3>
            <p>{data.package.Owner || 'Aprobatorio'}</p>
          </div>
        </div>
        <div className={styles.root__meta__col}>
          <div>
            <h3>Dirección de despacho:</h3>
            <p className={styles.root__meta__col__direction}>
              {data.package.Address !== '-'
                ? data.package.Address
                : 'Aún no asignado'}
            </p>
          </div>
        </div>
      </div>
      {roadmap && roadmap?.length > 0 && (
        <div className={styles.root__roadmap}>
          <ul className={styles.root__roadmap__list}>
            {roadmap.map((roadmapItem, index) => {
              const status = getPackageStatus(parseInt(roadmapItem.CodEstado));
              const iconName = getStatusIconBySlug(status?.slug);

              return (
                <li
                  key={roadmapItem + '__' + index}
                  className={styles.root__roadmap__list__item}
                >
                  <div className={styles.root__roadmap__list__item__bullet}>
                    <div
                      className={classNames(
                        styles.root__roadmap__list__item__bullet__inner,
                        roadmapItem?.imageIds &&
                          roadmapItem?.imageIds?.length > 0
                          ? styles.root__roadmap__list__item__bullet__inner__xlg
                          : '',
                        iconName && iconName?.length > 0
                          ? styles.root__roadmap__list__item__bullet__inner__lg
                          : '',
                        status?.slug
                          ? styles[
                              `root__roadmap__list__item__bullet__inner__${status?.slug}`
                            ]
                          : ''
                      )}
                    >
                      {iconName && iconName?.length > 0 && (
                        <Icon name={iconName} />
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      styles.root__roadmap__list__item__meta,
                      iconName && iconName?.length > 0
                        ? styles.root__roadmap__list__item__meta__lg
                        : '',
                      index === data.roadmap.length - 1
                        ? styles[
                            `root__roadmap__list__item__meta__${status?.slug}`
                          ]
                        : ''
                    )}
                  >
                    <b>{roadmapItem.EstadoPaquete}</b>
                    {roadmapItem?.MessagePaquete &&
                      roadmapItem?.MessagePaquete?.length > 0 &&
                      roadmapItem?.MessagePaquete !== '-' && (
                        <p>{roadmapItem.MessagePaquete}</p>
                      )}
                    <small>{roadmapItem.Fecha}</small>
                    {roadmapItem?.imageIds &&
                      roadmapItem?.imageIds?.length > 0 && (
                        <PhotoProvider maskOpacity={0.9} speed={() => 400}>
                          <ul
                            className={styles.root__roadmap__list__item__bills}
                          >
                            {roadmapItem.imageIds.map((id: number) => (
                              <PhotoView key={id} src={imageBaseUrl + id}>
                                <li>
                                  <img
                                    src={imageBaseUrl + id}
                                    alt={id.toString()}
                                  />
                                </li>
                              </PhotoView>
                            ))}
                          </ul>
                        </PhotoProvider>
                      )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  ) : (
    <Fragment />
  );
};
