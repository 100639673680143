const customStyles = {
  rows: {
    style: {
      marginBottom: 10,
      borderRadius: 'var(--border-radius)',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingTop: '8px',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingBottom: '8px',
    },
  },
};

export default customStyles;
