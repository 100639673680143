import { EmptyResults, SuspenseLoader } from '@components/common';
import columns from '@components/common/PackageList/columns/columns';
import customStyles from '@components/common/PackageList/columns/styles';
import { selectSelectedCategory } from '@store/features/packages/packages.slice';
import { useFetchPackagesQuery } from '@store/services/packages/packagesService';
import { useAppSelector } from '@store/store';
import { FC, ReactElement, useState } from 'react';
import DataTable from 'react-data-table-component';

type PackageListProps = {};

export const PackageList: FC<PackageListProps> = (): ReactElement => {
  const [offset, setOffset] = useState<number>(0);
  const { code, type } = useAppSelector(selectSelectedCategory);
  const { data, isLoading, isSuccess, isError } = useFetchPackagesQuery(
    {
      type,
      offset,
      quantity: 100,
    },
    { skip: code === -1 }
  );

  return (
    <>
      {isLoading && <SuspenseLoader position='absolute' />}
      {isError && (
        <EmptyResults
          isEmpty={true}
          desktop={true}
          message='Error al cargar. Inténtalo de nuevo.'
        />
      )}
      {isSuccess && data?.length === 0 && (
        <EmptyResults
          isEmpty={true}
          desktop={true}
          message='¡No se encontraron paquetes!'
        />
      )}
      {isSuccess && data?.length > 0 && (
        <DataTable
          columns={columns}
          data={data as any}
          customStyles={customStyles}
          fixedHeader={true}
          fixedHeaderScrollHeight='100%'
          responsive={true}
        />
      )}
    </>
  );
};
