import styles from '@components/common/AddressBook/Form/Form.module.scss';
import { Button, FormField, FormGroup } from '@components/ui';
import { useMapSearchBox } from '@hooks/useMapSearchBox';
import { useNavigator } from '@hooks/useNavigator';
import { PlaceType } from '@interfaces/map.interface';
import { classNames } from '@libs/utils';
import { FC, ReactElement, useEffect, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type FormProps = {
  onCheckDetection: (status: boolean) => void;
  onSelectPlace: (place: PlaceType) => void;
};

export const Form: FC<FormProps> = ({
  onCheckDetection,
  onSelectPlace,
}): ReactElement => {
  const [active, setActive] = useState<boolean>(false);
  const [isDetecting, setIsDetecting] = useState<boolean>(false);
  const [placeValue, setPlaceValue] = useState<PlaceType | undefined>();
  const { control, setValue } = useForm();
  const { getPlace } = useNavigator();
  const { searchBoxPlace } = useMapSearchBox({ fieldId: 'address' });

  console.log(placeValue);

  useEffect(() => {
    if (searchBoxPlace) {
      setPlaceValue(searchBoxPlace);
      onSelectPlace(searchBoxPlace);
    }
  }, [searchBoxPlace]);

  useLayoutEffect(() => {
    setTimeout(() => setActive(true), 1000);
  }, []);

  const triggerGetGeoPosition = () => {
    setIsDetecting(true);
    getPlace(
      (place: PlaceType) => {
        console.log(place);
        onSelectPlace(place);
        onCheckDetection(true);
        setPlaceValue(place);
        setIsDetecting(false);
      },
      (error: any) => {
        setIsDetecting(false);
        error?.code === 1 && onCheckDetection(false);
      },
      2000
    );
  };

  function handleAddPlace() {
    console.log(placeValue);
  }

  return (
    <div
      className={classNames('fx', 'fx-nowrap', 'fx-align-center', styles.root)}
    >
      <FormGroup classList={styles.field}>
        <FormField
          control={control}
          type='text'
          fieldName='address'
          placeholder='Escriba para buscar la dirección...'
          validation='off'
          icon='x0016'
          className={styles.field__inner}
          defaultValue={placeValue?.address}
          disabled={isDetecting}
          setValue={setValue}
        />
      </FormGroup>
      <Button type='button' text='Guardar' onClick={handleAddPlace} />
    </div>
  );
};
