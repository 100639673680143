import styles from '@components/common/BottomBar/BottomBar.module.scss';
import { Icon } from '@components/ui';
import { useSetBottomBarParams } from '@hooks/useSetBottomBarParams';
import { classNames } from '@libs/utils';
import { useFetchMenuQuery } from '@store/services/menus/menusService';
import { FC, Fragment, ReactElement, useRef } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { Link } from 'react-router-dom';

type BottomBarProps = {};

export const BottomBar: FC<BottomBarProps> = (): ReactElement => {
  const ref = useRef(null);
  const { data, isSuccess, isError } = useFetchMenuQuery({
    slug: 'bottom-nav-menu',
  });
  const { appInstalled, isActive } = useSetBottomBarParams(ref, [
    data,
    isSuccess,
    isError,
  ]);

  if (isError) {
    return <Fragment />;
  }

  return (
    <nav
      ref={ref}
      className={classNames(
        styles.root,
        appInstalled && isIOS ? styles.root__ios : '',
        appInstalled && isAndroid ? styles.root__android : '',
        !appInstalled ? styles.root__web : ''
      )}
    >
      {isSuccess && data?.items?.length > 0 && (
        <ul className='fx-content-between fx-align-center'>
          {data.items.map(({ id, name, uri, icon_name }) => (
            <li key={id}>
              <Link
                to={uri}
                className={classNames(
                  'fx-column',
                  'fx-content-center',
                  'fx-align-center',
                  styles.link,
                  isActive(uri) ? styles.link__active : ''
                )}
              >
                {icon_name && (
                  <Icon
                    name={icon_name}
                    width={24}
                    height={24}
                    color={isActive(uri) ? 'primary' : 'grey'}
                  />
                )}
                <span className={styles.link__text}>{name}</span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};
