// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToolBar_root__X3XFW {
  width: 100%;
  background-color: var(--color-whisper);
  padding: 10px 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
}

.ToolBar_side__left__mvCLJ {
  width: 32px;
}
.ToolBar_side__center__QzzhB {
  width: calc(100% - 64px);
  text-align: center;
}
.ToolBar_side__right__vVgNy {
  width: 32px;
}

.ToolBar_title__re4AI {
  display: block;
  max-width: 90%;
  width: 100%;
  font-weight: var(--medium);
  font-size: 16px;
  color: var(--color-mirage);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
}

.ToolBar_action__7jwq7 {
  display: inline-block;
  width: 32px;
  height: 24px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/ToolBar/ToolBar.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EAEA,sCAAA;EAEA,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;AAFF;;AAME;EACE,WAAA;AAHJ;AAKE;EACE,wBAAA;EACA,kBAAA;AAHJ;AAKE;EACE,WAAA;AAHJ;;AAOA;EACE,cAAA;EACA,cAAA;EACA,WAAA;EACA,0BAAA;EACA,eAAA;EACA,0BAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AAJF;;AAOA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAJF","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  // background-color: var(--color-white);\n  background-color: var(--color-whisper);\n  // border-radius: 0 0 var(--border-radius) var(--border-radius);\n  padding: 10px 15px;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 1030;\n}\n\n.side {\n  &__left {\n    width: 32px;\n  }\n  &__center {\n    width: calc(100% - 64px);\n    text-align: center;\n  }\n  &__right {\n    width: 32px;\n  }\n}\n\n.title {\n  display: block;\n  max-width: 90%;\n  width: 100%;\n  font-weight: var(--medium);\n  font-size: 16px;\n  color: var(--color-mirage);\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  margin: 0 auto;\n}\n\n.action {\n  display: inline-block;\n  width: 32px;\n  height: 24px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ToolBar_root__X3XFW`,
	"side__left": `ToolBar_side__left__mvCLJ`,
	"side__center": `ToolBar_side__center__QzzhB`,
	"side__right": `ToolBar_side__right__vVgNy`,
	"title": `ToolBar_title__re4AI`,
	"action": `ToolBar_action__7jwq7`
};
export default ___CSS_LOADER_EXPORT___;
