import styles from '@components/common/EditPackage/EditPackage.module.scss';
import Form from '@components/common/EditPackage/Form';
import { Icon } from '@components/ui';
import { LayoutContext } from '@contexts/LayoutContext';
import { classNames, delayFn } from '@libs/utils';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';

type EditPackageProps = {};

export const EditPackage: FC<EditPackageProps> = (): ReactElement => {
  const { packageCode, setPackageCode } = useContext(LayoutContext);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (packageCode && packageCode?.length > 0) {
      setShowPopup(true);
    }
  }, [packageCode]);

  function onClose() {
    setShowPopup(false);
    delayFn(() => {
      setPackageCode(undefined);
    }, 600);
  }

  return (
    packageCode &&
    packageCode?.length > 0 && (
      <div
        className={classNames(
          'fx-align-center',
          'fx-content-center',
          'popup',
          styles.root,
          showPopup ? 'popup__active' : ''
        )}
      >
        <div
          className={classNames(
            'fx-align-center',
            'fx-content-center',
            'popup__inner',
            styles.root__inner
          )}
        >
          <button
            type='button'
            className='popup__inner__close'
            onClick={() => onClose()}
          >
            <Icon name='x0030' width={12} height={12} />
          </button>
          <div className={styles.root__headline}>
            <h4>
              Editar <span>{packageCode}</span>
            </h4>
          </div>
          <Form code={packageCode} />
        </div>
      </div>
    )
  );
};
