import styles from '@components/common/ListMenu/ListMenu.module.scss';
import { Icon } from '@components/ui';
import { IMenuItem } from '@store/services/menus/types';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

type ListMenuProps = {
  list: IMenuItem[];
};

export const ListMenu: FC<ListMenuProps> = ({ list }): ReactElement =>
  list && list?.length > 0 ? (
    <ul className={styles.root}>
      {list.map(({ id, uri, name, icon, action }, index) => (
        <li key={id} className={styles.root__item}>
          {uri && uri?.length > 0 ? (
            <Link to={uri} className={styles.root__item__inner}>
              <span className={styles.root__item__text}>{name}</span>
              {uri && <Icon name='x0012' width={24} height={24} />}
            </Link>
          ) : (
            <button
              type='button'
              className={styles.root__item__inner}
              onClick={action}
            >
              <span className={styles.root__item__text}>{name}</span>
              {icon && (
                <Icon
                  name={icon.name || ''}
                  width={icon?.width}
                  height={icon?.height}
                />
              )}
            </button>
          )}
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );
