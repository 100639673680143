// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popup_root__f-xig {
  width: 100%;
  padding: 0 15px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(0);
  transition: opacity var(--def-transition-300ms), transform var(--def-transition-300ms);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
}
.Popup_root__active__WaOUX {
  pointer-events: all;
  opacity: 1;
  transform: translateY(15px);
}
.Popup_root__inner__BD5id {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 25px 25px 25px 15px;
  position: relative;
}
.Popup_root__inner__symetric__XFV3r {
  padding: 25px 15px;
}
.Popup_root__inner__BD5id > div:last-of-type {
  width: calc(100% - 24px);
  padding: 0 0 0 15px;
}
.Popup_root__inner__BD5id p,
.Popup_root__inner__BD5id button {
  display: inline;
  font-size: 14px;
}
.Popup_root__inner__BD5id button {
  font-weight: var(--medium);
  color: var(--color-primary);
  text-decoration: underline;
}
.Popup_root__close__D8q4z {
  transform: translate(-5px, 5px);
  position: absolute;
  top: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/PickAddress/Popup/Popup.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,eAAA;EACA,oBAAA;EACA,UAAA;EACA,wBAAA;EACA,sFAAA;EAEA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;AADF;AAEE;EACE,mBAAA;EACA,UAAA;EACA,2BAAA;AAAJ;AAEE;EACE,WAAA;EACA,oCAAA;EACA,6BAAA;EACA,mCAAA;EACA,4BAAA;EACA,kBAAA;AAAJ;AACI;EACE,kBAAA;AACN;AAEM;EACE,wBAAA;EACA,mBAAA;AAAR;AAGI;;EAEE,eAAA;EACA,eAAA;AADN;AAGI;EACE,0BAAA;EACA,2BAAA;EACA,0BAAA;AADN;AAIE;EACE,+BAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;AAFJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  padding: 0 15px;\n  pointer-events: none;\n  opacity: 0;\n  transform: translateY(0);\n  transition: opacity var(--def-transition-300ms),\n    transform var(--def-transition-300ms);\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 15;\n  &__active {\n    pointer-events: all;\n    opacity: 1;\n    transform: translateY(15px);\n  }\n  &__inner {\n    width: 100%;\n    background-color: var(--color-white);\n    box-shadow: var(--box-shadow);\n    border-radius: var(--border-radius);\n    padding: 25px 25px 25px 15px;\n    position: relative;\n    &__symetric {\n      padding: 25px 15px;\n    }\n    & > div {\n      &:last-of-type {\n        width: calc(100% - 24px);\n        padding: 0 0 0 15px;\n      }\n    }\n    p,\n    button {\n      display: inline;\n      font-size: 14px;\n    }\n    button {\n      font-weight: var(--medium);\n      color: var(--color-primary);\n      text-decoration: underline;\n    }\n  }\n  &__close {\n    transform: translate(-5px, 5px);\n    position: absolute;\n    top: 0;\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Popup_root__f-xig`,
	"root__active": `Popup_root__active__WaOUX`,
	"root__inner": `Popup_root__inner__BD5id`,
	"root__inner__symetric": `Popup_root__inner__symetric__XFV3r`,
	"root__close": `Popup_root__close__D8q4z`
};
export default ___CSS_LOADER_EXPORT___;
