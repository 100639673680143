// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CallToAction_root__dop8F {
  text-align: center;
}

.CallToAction_headline__Xzz-i h6 {
  font-weight: var(--regular);
  font-size: 14px;
  color: var(--color-text);
  margin: 0;
}
.CallToAction_headline__Xzz-i + a {
  margin-top: 5px;
}

.CallToAction_link__TNRnZ {
  display: inline-block;
  font-weight: var(--semi-bold);
  font-size: 14px;
  color: var(--color-primary);
}
.CallToAction_link__TNRnZ:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/CallToAction/CallToAction.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;;AAIE;EACE,2BAAA;EACA,eAAA;EACA,wBAAA;EACA,SAAA;AADJ;AAGE;EACE,eAAA;AADJ;;AAKA;EACE,qBAAA;EACA,6BAAA;EACA,eAAA;EACA,2BAAA;AAFF;AAGE;EACE,0BAAA;AADJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  text-align: center;\n}\n\n.headline {\n  h6 {\n    font-weight: var(--regular);\n    font-size: 14px;\n    color: var(--color-text);\n    margin: 0;\n  }\n  & + a {\n    margin-top: 5px;\n  }\n}\n\n.link {\n  display: inline-block;\n  font-weight: var(--semi-bold);\n  font-size: 14px;\n  color: var(--color-primary);\n  &:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CallToAction_root__dop8F`,
	"headline": `CallToAction_headline__Xzz-i`,
	"link": `CallToAction_link__TNRnZ`
};
export default ___CSS_LOADER_EXPORT___;
