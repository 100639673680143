export const PACKAGE_STATUSES = {
  all: 'all',
  active: 'active',
  history: 'history',
  archive: 'archive',
};

export const STYLE_SHEETS_IDS = {
  common: 'common',
  topNavBar: 'topNavBar',
  toolBar: 'toolBar',
  bottomNavBar: 'bottomNavBar',
};

export const PACKAGE_TYPES: { [key: string]: string } = {
  A: 'Todos',
  D: 'Entregado',
  OR: 'En Ruta',
  F: 'Fallido',
  C: 'Programado',
};

export const PACKAGE_ORDER: { [key: string]: number } = {
  A: 0,
  D: 3,
  OR: 2,
  F: 4,
  C: 1,
};
