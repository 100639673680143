// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_root__tOHOl {
  padding: 15px 15px 25px;
}

.Footer_root__tOHOl,
.Footer_list__kxat8 {
  width: 100%;
}

.Footer_list__item__iiq-W {
  font-weight: var(--semi-bold);
  font-size: 12px;
  color: var(--color-manatee);
  transition: color var(--def-transition-300ms);
}
.Footer_list__item__iiq-W:hover {
  color: var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/Footer/Footer.module.scss"],"names":[],"mappings":"AACA;EACE,uBAAA;AAAF;;AAGA;;EAEE,WAAA;AAAF;;AAIE;EACE,6BAAA;EACA,eAAA;EACA,2BAAA;EACA,6CAAA;AADJ;AAEI;EACE,2BAAA;AAAN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  padding: 15px 15px 25px;\n}\n\n.root,\n.list {\n  width: 100%;\n}\n\n.list {\n  &__item {\n    font-weight: var(--semi-bold);\n    font-size: 12px;\n    color: var(--color-manatee);\n    transition: color var(--def-transition-300ms);\n    &:hover {\n      color: var(--color-primary);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Footer_root__tOHOl`,
	"list": `Footer_list__kxat8`,
	"list__item": `Footer_list__item__iiq-W`
};
export default ___CSS_LOADER_EXPORT___;
