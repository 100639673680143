// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddressBook_root__Sep5w {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 15px;
}
.AddressBook_root__Sep5w > div {
  width: 100%;
}
.AddressBook_root__Sep5w > div:first-of-type {
  max-width: 380px;
}
.AddressBook_root__Sep5w > div:last-of-type {
  max-width: calc(100% - 380px);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/AddressBook/AddressBook.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,oCAAA;EACA,6BAAA;EACA,mCAAA;EACA,aAAA;AAAF;AACE;EACE,WAAA;AACJ;AAAI;EACE,gBAAA;AAEN;AAAI;EACE,6BAAA;AAEN","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  height: 100%;\n  background-color: var(--color-white);\n  box-shadow: var(--box-shadow);\n  border-radius: var(--border-radius);\n  padding: 15px;\n  & > div {\n    width: 100%;\n    &:first-of-type {\n      max-width: 380px;\n    }\n    &:last-of-type {\n      max-width: calc(100% - 380px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AddressBook_root__Sep5w`
};
export default ___CSS_LOADER_EXPORT___;
