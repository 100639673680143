import { PackageStatus } from '@components/common';
import styles from '@components/common/PackageGroup/PackageItem/PackageItem.module.scss';
import { Icon } from '@components/ui';
import { LayoutContext } from '@contexts/LayoutContext';
import { IPackageStatus } from '@interfaces/package-status.interface';
import routesSchema from '@libs/routes-schema';
import { classNames, getDateTime, getPackageStatus } from '@libs/utils';
import { IPackage } from '@store/services/packages/types';
import {
  FC,
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

interface PackageItemProps extends IPackage {
  createdDate?: string;
}

export const PackageItem: FC<PackageItemProps> = ({
  CodPaquete,
  CodEstadoPaquete,
  EntregaDate,
}): ReactElement => {
  const editRef = useRef<any>();
  const navigate = useNavigate();
  const { setPackageCode } = useContext(LayoutContext);
  const [packageStatus, setPackageStatus] = useState<IPackageStatus>();
  const [dateTime, setDateTime] = useState<string | undefined>();

  useEffect(() => {
    if (CodEstadoPaquete === 3) {
      const datetime =
        getDateTime(EntregaDate) || 'Fecha de entrega: está programando';
      datetime && setDateTime(datetime);
    }

    const statusObj = getPackageStatus(CodEstadoPaquete);
    statusObj && statusObj.slug?.length > 0 && setPackageStatus(statusObj);
  }, []);

  function onClick(e: MouseEvent<HTMLDivElement>) {
    if (editRef?.current && e.target === editRef.current) {
      return;
    }

    navigate(`${routesSchema.packages.view}/${CodPaquete}`);
  }

  return (
    <div
      className={classNames('fx', 'fx-nowrap', styles.root)}
      onClick={onClick}
    >
      <PackageStatus type='icon' value={packageStatus} />
      <div className={styles.root__meta}>
        <h3>{CodPaquete}</h3>
        <div>
          <PackageStatus
            type='text'
            value={packageStatus}
            classList={styles.root__meta__status}
          />
          <span className={styles.root__meta__date}>
            {EntregaDate && EntregaDate?.length > 1
              ? dateTime?.replace('am', 'AM').replace('pm', 'PM')
              : dateTime}
            {}
          </span>
        </div>
        {packageStatus?.slug && packageStatus.slug === 'created' && (
          <button
            ref={editRef}
            type='button'
            className={styles.root__meta__edit}
            onClick={() => setPackageCode(CodPaquete)}
          >
            <Icon name='x0025' width={16} height={16} />
            <i>Editar entrega</i>
          </button>
        )}
      </div>
      <Icon name='x0012' classList={styles.root__icon} />
    </div>
  );
};
