import styles from '@components/ui/Headline/Headline.module.scss';
import { classNames } from '@libs/utils';
import parse from 'html-react-parser';
import { FC, ReactElement, useMemo } from 'react';

type HeadlineProps = {
  title?: string;
  description?: string;
  hierarchy?: 'x1' | 'x2' | 'x3' | 'x4' | 'x5' | 'x6';
  alignment?: 'start' | 'center' | 'end';
  offset?: {
    top?: number | boolean;
    right?: number | boolean;
    bottom?: number | boolean;
    left?: number | boolean;
  };
};

export const Headline: FC<HeadlineProps> = ({
  title,
  description,
  hierarchy = 'x3',
  alignment = 'left',
  offset = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}): ReactElement => {
  const margin = useMemo(() => {
    const keys = offset as any;
    let result = {} as any;

    for (const value in keys) {
      const v = keys[value + ''];
      const firstChar = value.charAt(0).toLocaleUpperCase();
      const key = firstChar + value.substring(1);

      if (typeof v == 'boolean') {
        result['margin' + key] = Boolean(v) ? 15 : 0;
      }

      if (typeof v == 'number') {
        result['margin' + key] = v;
      }
    }

    return result;
  }, [offset]);
  const tag = useMemo(() => {
    switch (hierarchy) {
      case 'x1':
        return <h1>{parse(title || '')}</h1>;
      case 'x2':
        return <h2>{parse(title || '')}</h2>;
      case 'x3':
        return <h3>{parse(title || '')}</h3>;
      case 'x4':
        return <h4>{parse(title || '')}</h4>;
      case 'x5':
        return <h5>{parse(title || '')}</h5>;
      case 'x6':
        return <h6>{parse(title || '')}</h6>;
    }
  }, [hierarchy]);

  return (
    <div
      className={classNames(
        styles.root,
        styles[`root__alignment_${alignment}`]
      )}
      style={margin}
    >
      {title && title?.length > 0 && (
        <div
          className={classNames(
            styles.root__title,
            styles[`root__title__size_${hierarchy}`]
          )}
        >
          {tag}
        </div>
      )}
      {description && description?.length > 0 && (
        <div className={styles.root__description}>{parse(description)}</div>
      )}
    </div>
  );
};
