import styles from '@components/ui/FormControlRow/FormControlRow.module.scss';
import { classNames } from '@libs/utils';
import { FC, ReactElement } from 'react';

type FormControlRowProps = {
  children: ReactElement | ReactElement[];
  classList?: string;
};

export const FormControlRow: FC<FormControlRowProps> = ({
  children,
  classList = '',
}): ReactElement => {
  return <div className={classNames(styles.root, classList)}>{children}</div>;
};
