import styles from '@components/common/BottomHelpBar/BottomHelpBar.module.scss';
import { Button } from '@components/ui';
import { classNames } from '@libs/utils';
import { FC, ReactElement } from 'react';

type BottomHelpBarProps = {};

export const BottomHelpBar: FC<BottomHelpBarProps> = (): ReactElement => (
  <div className={styles.root}>
    <div
      className={classNames(
        'fx-align-center',
        'fx-content-end',
        styles.root__inner
      )}
    >
      <p>¿Necesitas apoyo?</p>
      <Button
        type='internal'
        href='/info/customer-support'
        text='¡Escribenos!'
        style={{ size: 'sm', auto: true }}
      />
    </div>
  </div>
);
