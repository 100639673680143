import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import { IGenericResponse } from '@store/services/forms/types';
import qs from 'qs';

const BASE_URL =
  `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SUFFIX}` as string;

const uri = {
  support: 'support/',
};

export const formsService = createApi({
  tagTypes: ['Forms'],
  reducerPath: 'formsService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/forms`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    sendCustomerSupportMessage: builder.mutation<IGenericResponse, any>({
      query(data) {
        return {
          url: uri.support,
          method: 'POST',
          body: qs.stringify(data),
        };
      },
    }),
  }),
});

export const { useSendCustomerSupportMessageMutation } = formsService;
