import styles from '@components/common/Footer/Footer.module.scss';
import { LayoutContext } from '@contexts/LayoutContext';
import { classNames } from '@libs/utils';
import { FC, Fragment, ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';

type FooterProps = {};

export const Footer: FC<FooterProps> = ({}): ReactElement => {
  const { useFooter } = useContext(LayoutContext);

  if (!useFooter) return <Fragment />;

  return (
    <footer className={styles.root}>
      <ul className={classNames('fx-content-between', styles.list)}>
        <li>
          <Link to='/auth/privacy-policies' className={styles.list__item}>
            Política de Privacidad
          </Link>
        </li>
        <li>
          <Link to='/auth/terms-and-conditions' className={styles.list__item}>
            Términos y Condeciones
          </Link>
        </li>
      </ul>
    </footer>
  );
};
