import { CSSProperties, FC, ReactElement } from 'react';
import { classNames } from '@libs/utils';
import styles from '@components/ui/ButtonGroup/ButtonGroup.module.scss';

type ButtonGroupProps = {
  children: ReactElement[];
  classList?: string;
  style?: CSSProperties;
};

export const ButtonGroup: FC<ButtonGroupProps> = ({
  children,
  classList = '',
  style,
}): ReactElement => (
  <div className={classNames(styles.root, classList)} style={style}>
    {children.map((child, index) => (
      <div key={index}>{child}</div>
    ))}
  </div>
);
