import styles from '@components/common/VerifyProfile/VerifyProfile.module.scss';
import { Button } from '@components/ui';
import { selectProfileIsActive } from '@store/services/users/usersService';
import { useAppSelector } from '@store/store';
import { FC, Fragment, ReactElement } from 'react';

type VerifyProfileProps = {};

export const VerifyProfile: FC<VerifyProfileProps> = ({}): ReactElement => {
  const isActive = useAppSelector((state) => selectProfileIsActive(state));

  return !isActive ? (
    <div className={styles.root}>
      <div className={styles.root__inner}>
        <h4>¡Necesitas verificar tu cuenta!</h4>
        <Button
          type='internal'
          href='/profile/verify'
          text='¡Verificación completa ahora!'
          style={{ color: 'primary-white' }}
        />
      </div>
    </div>
  ) : (
    <Fragment />
  );
};
