import NetworkContextProvider from '@contexts/NetworkContext';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { routerHistory, routerSelector } from '@store/features/router';
import { store } from '@store/store';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('SavarX_PWA') as HTMLElement
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <ReduxRouter history={routerHistory} routerSelector={routerSelector}>
        <NetworkContextProvider>
          <App />
        </NetworkContextProvider>
      </ReduxRouter>
    </Provider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
