import { CheckForUpdates } from '@components/common';
import { createContext, FC, ReactElement, useState } from 'react';

type LayoutContextProviderProps = {
  children: ReactElement | ReactElement[];
};

export const LayoutContext = createContext<any>({});

const LayoutContextProvider: FC<LayoutContextProviderProps> = ({
  children,
}): ReactElement => {
  // Http Loader
  const [httpLoader, setHttpLoader] = useState<boolean>(false);

  // Footer
  const [useFooter, setUseFooter] = useState<boolean>(true);

  // popup: Edit Package
  const [packageCode, setPackageCode] = useState<string | undefined>();

  // popup: Delete Address
  const [addressPopup, setAddressPopup] = useState<string | undefined>();

  return (
    <LayoutContext.Provider
      value={{
        // loader: Http
        httpLoader,
        setHttpLoader,

        // footer
        useFooter,
        setUseFooter,

        // popup: Edit Package
        packageCode,
        setPackageCode,

        // popup: Delete Address
        addressPopup,
        setAddressPopup,
      }}
    >
      {children}
      <CheckForUpdates />
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;
