import { STYLE_SHEETS_IDS } from '@libs/constants';
import { setStyleSheetVars } from '@libs/utils';
import { selectRouterPathname } from '@store/features/router';
import { useAppSelector } from '@store/store';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

export const useSetTopBarParams = (ref: any, deps: any[]) => {
  const interval = useMemo(() => 100, []);
  const timeout = useMemo(() => 10000, []);
  const [height, setHeight] = useState<number>(0);
  const location = useAppSelector(selectRouterPathname);

  useEffect(() => {
    const intervalId = setInterval(setElementHeight, interval);
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, timeout);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [interval, location]);

  useLayoutEffect(() => {
    setStyleSheetVars(
      { topNavBarHeight: height + 'px' },
      STYLE_SHEETS_IDS.topNavBar
    );

    return () => {
      setStyleSheetVars({ topNavBarHeight: '0px' }, STYLE_SHEETS_IDS.topNavBar);
    };
  }, [height]);

  useLayoutEffect(() => {
    setElementHeight();
  }, [location, ...deps]);

  const setElementHeight = () => {
    if (ref && ref?.current) {
      const element = ref.current as HTMLElement;

      if (element && element.clientHeight) {
        setHeight(element.clientHeight);
      }
    }
  };

  return null;
};
