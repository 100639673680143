import { FC, ReactElement } from 'react';

type PropsType = {
  classList?: string;
  style?: object;
};

const styles = { display: 'inline-block', width: 28, height: 28 };

export const IconOffline: FC<PropsType> = ({
  classList = '',
  style = {},
}): ReactElement => (
  <span className={classList} style={{ ...styles, ...style }}>
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.17284 9.4321C3.54321 10.5185 3 11.2677 3 13.5552C3 16.3052 5.2 18.2219 7.88889 18.2219H14.4074M20.9259 15.4074C21.6356 14.8057 21.613 14.0973 21.4691 13.2346C21.2333 11.8201 19.9617 10.6119 18.2099 10.5185C17.8477 6.78768 15.7519 4.88855 12.7778 4.88855C11.712 4.88855 11.6914 4.88856 10.6049 5.08641'
        stroke='#f65843'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.7778 20L4.77783 4'
        stroke='#f65843'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  </span>
);

export const IconOnline: FC<PropsType> = ({
  classList,
  style = {},
}): ReactElement => (
  <span className={classList} style={{ ...styles, ...style }}>
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.2 10.85C17.8481 7.21244 15.3896 5 12.5 5C9.76875 5 8.00967 6.95812 7.43333 8.9C5.05833 9.14375 3 10.6708 3 13.45C3 16.1312 5.1375 18 7.75 18H18.0417C20.2187 18 22 16.8852 22 14.425C22 11.9948 19.9021 10.941 18.2 10.85Z'
        stroke='#27aa12'
        strokeWidth='2'
        strokeLinejoin='round'
      />
    </svg>
  </span>
);
