import { EmptyResults, SuspenseLoader } from '@components/common';
import styles from '@components/common/PackageRoadmap/PackageRoadmap.module.scss';
import { Icon } from '@components/ui';
import { classNames, getPackageStatus, getStatusIconBySlug } from '@libs/utils';
import { setPageTitle } from '@store/features/meta/meta.slice';
import { useFetchPackageQuery } from '@store/services/packages/packagesService';
import { useAppDispatch } from '@store/store';
import { FC, ReactElement, useEffect, useMemo } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

type PackageRoadmapProps = {
  code: string;
};

type PackageType = {
  code: string;
  status: string;
  date: string;
};

export const PackageRoadmap: FC<PackageRoadmapProps> = ({
  code,
}): ReactElement => {
  const dispatch = useAppDispatch();
  const imageBaseUrl = useMemo(
    (): string => process.env.REACT_APP_SAVARX_IMAGE_BASE_URL || '',
    []
  );

  useEffect(() => {
    dispatch(setPageTitle('Entrega: ' + code));
  }, []);

  const { data, isLoading, isSuccess, isError } = useFetchPackageQuery(
    { code },
    { skip: false }
  );

  const roadmap = useMemo(() => {
    if (typeof data?.roadmap !== 'undefined' && Array.isArray(data?.roadmap)) {
      return data.roadmap.slice().reverse();
    }

    return [];
  }, [data]);

  if (isLoading) {
    return <SuspenseLoader position='absolute' />;
  }

  if (isSuccess && data && data?.roadmap && roadmap && roadmap?.length > 0) {
    return (
      <div className={styles.root}>
        <ul className={styles.root__roadmap__list}>
          {roadmap.map((roadmapItem, index) => {
            const status = getPackageStatus(parseInt(roadmapItem.CodEstado));
            const iconName = getStatusIconBySlug(status?.slug);

            return (
              <li
                key={roadmapItem + '__' + index}
                className={styles.root__roadmap__list__item}
              >
                <div className={styles.root__roadmap__list__item__bullet}>
                  <div
                    className={classNames(
                      styles.root__roadmap__list__item__bullet__inner,
                      iconName && iconName?.length > 0
                        ? styles.root__roadmap__list__item__bullet__inner__lg
                        : '',
                      roadmapItem?.imageIds && roadmapItem?.imageIds?.length > 0
                        ? styles.root__roadmap__list__item__bullet__inner__xlg
                        : '',
                      status?.slug
                        ? styles[
                            `root__roadmap__list__item__bullet__inner__${status?.slug}`
                          ]
                        : ''
                    )}
                  >
                    {iconName && iconName?.length > 0 && (
                      <Icon name={iconName} />
                    )}
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.root__roadmap__list__item__meta,
                    iconName && iconName?.length > 0
                      ? styles.root__roadmap__list__item__meta__lg
                      : '',
                    index === data.roadmap.length - 1
                      ? styles[
                          `root__roadmap__list__item__meta__${status?.slug}`
                        ]
                      : ''
                  )}
                >
                  <b>{roadmapItem.EstadoPaquete}</b>
                  {roadmapItem?.MessagePaquete &&
                    roadmapItem?.MessagePaquete?.length > 0 &&
                    roadmapItem?.MessagePaquete !== '-' && (
                      <p>{roadmapItem.MessagePaquete}</p>
                    )}
                  <small>{roadmapItem.Fecha}</small>
                  {roadmapItem?.imageIds &&
                    roadmapItem?.imageIds?.length > 0 && (
                      <PhotoProvider maskOpacity={0.9} speed={() => 400}>
                        <ul className={styles.root__roadmap__list__item__bills}>
                          {roadmapItem.imageIds.map((id: number) => (
                            <PhotoView key={id} src={imageBaseUrl + id}>
                              <li>
                                <img
                                  src={imageBaseUrl + id}
                                  alt={id.toString()}
                                />
                              </li>
                            </PhotoView>
                          ))}
                        </ul>
                      </PhotoProvider>
                    )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else {
    return (
      <EmptyResults
        isEmpty={true}
        desktop={true}
        mobile={true}
        message='No se encontró ningún paquete.'
      />
    );
  }
};
