import styles from '@components/common/EmptyResults/EmptyResults.module.scss';
import { Icon } from '@components/ui';
import { classNames } from '@libs/utils';
import { FC, Fragment, ReactElement } from 'react';

type EmptyResultsProps = {
  isEmpty: boolean;
  message: string;
  desktop?: boolean;
  mobile?: boolean;
};

export const EmptyResults: FC<EmptyResultsProps> = ({
  isEmpty,
  message,
  desktop = false,
  mobile = false,
}): ReactElement =>
  isEmpty ? (
    <div
      className={classNames(
        styles.root,
        desktop ? styles.root__desktop : '',
        mobile ? styles.root__mobile : '',
        'fx-align-center',
        'fx-content-center'
      )}
    >
      <div>
        <Icon
          name='x0032'
          width={desktop ? 68 : 54}
          height={desktop ? 68 : 54}
        />
        <h6>{message}</h6>
      </div>
    </div>
  ) : (
    <Fragment />
  );
