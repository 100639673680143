import { FormFeedbackPopup, HttpLoader } from '@components/common';
import styles from '@components/common/EditPackage/Form/Form.module.scss';
import { Button, FormControl, FormControlRow } from '@components/ui';
import { LayoutContext } from '@contexts/LayoutContext';
import { yupResolver } from '@hookform/resolvers/yup';
import editPackageValidator from '@libs/validators/edit-package.validator';
import { useEditPackageMutation } from '@store/services/packages/packagesService';
import {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

type FormProps = {
  code: string;
};

type FormDataType = {
  options?: string;
  code: string;
  action: string;
  message: string;
};

export const Form: FC<FormProps> = ({ code }): ReactElement => {
  const formRef = useRef<HTMLFormElement>(null);
  const [feedbackOpened, setFeedbackOpened] = useState<boolean>(false);
  const { setPackageCode } = useContext(LayoutContext);
  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(editPackageValidator()),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [editPackage, { data, isLoading, isSuccess, isError }] =
    useEditPackageMutation();

  useEffect(() => {
    !isLoading && (isSuccess || isError) && setFeedbackOpened(true);
  }, [isLoading, isSuccess, isError]);

  const onCloseFeedback = () => {
    setPackageCode(null);
    setFeedbackOpened(false);
  };

  const onSubmit: any = (formData: FormDataType, event: any) => {
    event.preventDefault();

    editPackage({ ...formData, code });
    reset();

    if (formRef.current) {
      formRef.current.querySelectorAll('input, textarea').forEach((item) => {
        if (item instanceof HTMLInputElement) {
          item.value = '';
        }

        if (item instanceof HTMLTextAreaElement) {
          item.value = '';
        }
      });
    }
  };

  return (
    <>
      <form
        ref={formRef}
        className={styles.root}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControlRow>
          <FormControl
            control={control as any}
            type='select'
            label='Escoge una opción'
            fieldName='action'
            placeholder='Escoge una opción'
            options={[
              {
                value: 'Cambiar fecha de entrega',
                text: 'Cambiar fecha de entrega',
              },
              { value: 'Cancelar entrega', text: 'Cancelar entrega' },
            ]}
            disabled={isLoading}
            width={100}
          />
        </FormControlRow>
        <FormControlRow>
          <FormControl
            control={control as any}
            type='textarea'
            label='Mensaje'
            fieldName='message'
            placeholder='Mensaje'
            rows={6}
            disabled={isLoading}
            isPassword={true}
          />
        </FormControlRow>
        <Button onClick={handleSubmit(onSubmit)} type='submit' text='Enviar' />
      </form>
      <HttpLoader active={isLoading} />
      <FormFeedbackPopup onClose={onCloseFeedback} isOpened={feedbackOpened}>
        <div className={styles.popup}>
          {!isLoading && isSuccess && <h6>¡Éxito!</h6>}
          {!isLoading && isError && <h6>Error!</h6>}
          {<p>{data?.message}</p>}
        </div>
      </FormFeedbackPopup>
    </>
  );
};
