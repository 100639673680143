import styles from '@components/common/SearchForm/SearchForm.module.scss';
import { FormField, FormGroup } from '@components/ui';
import { useOutsideClick } from '@hooks/useOutsideClick';
import routesSchema from '@libs/routes-schema';
import { classNames } from '@libs/utils';
import { useSearchPackagesMutation } from '@store/services/packages/packagesService';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

type SearchFormProps = {};

export const SearchForm: FC<SearchFormProps> = ({}): ReactElement => {
  const [query, setQuery] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const fieldRef = useRef();
  const fieldOutside = useOutsideClick(fieldRef);
  const [searchPackages, { data, isLoading, isSuccess, isError, reset }] =
    useSearchPackagesMutation();

  useEffect(() => {
    return () => {
      setQuery('');
      reset();
    };
  }, []);

  useEffect(() => {
    setDisabled(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (query && query?.length > 0) {
        setDisabled(true);
        searchPackages({ code: query as string });
      }
    }, 1500);

    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    if (fieldOutside) {
      setQuery('');
      reset();
    }
  }, [fieldOutside]);

  function onChange(event: any) {
    setQuery(event.target.value);
  }

  return (
    <form
      className={classNames(styles.root, isMobile ? styles.root__mobile : '')}
    >
      <FormGroup classList={styles.form_control_row}>
        <FormField
          ref={fieldRef}
          disabled={disabled}
          value={query}
          type='text'
          fieldName='code'
          placeholder='Código de seguimiento'
          icon='x0028'
          className={styles.form_control_row__field}
          isLoading={isLoading}
          onChange={onChange}
        />
      </FormGroup>
      <ul
        className={classNames(
          styles.dropdown,
          query.length > 0 && !isLoading && isSuccess && data && !fieldOutside
            ? styles.dropdown__active
            : ''
        )}
      >
        {data &&
          data[0]?.code?.length > 0 &&
          data.map(({ code }) => (
            <li key={code} className={styles.dropdown__item}>
              <Link
                to={routesSchema.packages.view + '/' + code}
                className={classNames(
                  'fx-content-between',
                  'fx-align-center',
                  styles.dropdown__item__inner
                )}
              >
                <span>{code}</span>
                {/* <b className={styles[`${slug}`]}>{name}</b> */}
              </Link>
            </li>
          ))}
      </ul>
      <div
        className={classNames(
          styles.not_found,
          (query.length > 0 && isError && !fieldOutside) ||
            (data && data[0]?.code?.length === 0)
            ? styles.not_found__active
            : ''
        )}
      >
        <p>No se han encontrado resultados.</p>
      </div>
    </form>
  );
};
