import { useEffect, useState } from 'react';

const prefix = 'REACT_APP_';

export const useEnv = (prop: string) => {
  const [value, setValue] = useState<string | undefined>();

  useEffect(() => {
    const arrStr: string[] = prop.split(/[-_]/g);
    const suffix: string = arrStr.map((str) => str.toUpperCase()).join('_');

    if (suffix && suffix?.length > 0) {
      const varValue = process.env[`${prefix + suffix}`];
      setValue(varValue && varValue?.length > 0 ? varValue : undefined);
    }
  }, []);

  return value;
};
