// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu_root__r2E5l {
  width: 100%;
  margin: 0 0 30px;
  padding: 0 30px;
}
.Menu_root__item__vjzdB {
  display: block;
  width: 100%;
  position: relative;
}
.Menu_root__item__vjzdB:before {
  content: "";
  display: block;
  width: 4px;
  height: 24px;
  background-color: var(--color-primary);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  transform: translate(-34px, -50%);
  transition: transform var(--def-transition-300ms);
  position: absolute;
  top: 50%;
  left: 0;
}
.Menu_root__item__active__qeHsh:before {
  transform: translate(-30px, -50%);
}
.Menu_root__item__vjzdB:hover:before {
  transform: translate(-30px, -50%);
}
.Menu_root__item__inner__MIwsJ {
  padding: 10px 0;
}
.Menu_root__item__text__O01ZR {
  display: inline-block;
  width: calc(100% - 34px);
  font-weight: var(--medium);
  font-size: 16px;
  color: var(--color-manatee);
  margin: 0 0 0 10px;
  transition: color var(--def-transition-300ms);
}
.Menu_root__item__text__active__4U21e {
  color: var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/SideBar/Menu/Menu.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,gBAAA;EACA,eAAA;AAAF;AACE;EACE,cAAA;EACA,WAAA;EACA,kBAAA;AACJ;AAAI;EACE,WAAA;EACA,cAAA;EACA,UAAA;EACA,YAAA;EACA,sCAAA;EACA,4DAAA;EACA,iCAAA;EACA,iDAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;AAEN;AACM;EACE,iCAAA;AACR;AAGM;EACE,iCAAA;AADR;AAII;EACE,eAAA;AAFN;AAII;EACE,qBAAA;EACA,wBAAA;EACA,0BAAA;EACA,eAAA;EACA,2BAAA;EACA,kBAAA;EACA,6CAAA;AAFN;AAGM;EACE,2BAAA;AADR","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  margin: 0 0 30px;\n  padding: 0 30px;\n  &__item {\n    display: block;\n    width: 100%;\n    position: relative;\n    &:before {\n      content: '';\n      display: block;\n      width: 4px;\n      height: 24px;\n      background-color: var(--color-primary);\n      border-radius: 0 var(--border-radius) var(--border-radius) 0;\n      transform: translate(-34px, -50%);\n      transition: transform var(--def-transition-300ms);\n      position: absolute;\n      top: 50%;\n      left: 0;\n    }\n    &__active {\n      &:before {\n        transform: translate(-30px, -50%);\n      }\n    }\n    &:hover {\n      &:before {\n        transform: translate(-30px, -50%);\n      }\n    }\n    &__inner {\n      padding: 10px 0;\n    }\n    &__text {\n      display: inline-block;\n      width: calc(100% - 34px);\n      font-weight: var(--medium);\n      font-size: 16px;\n      color: var(--color-manatee);\n      margin: 0 0 0 10px;\n      transition: color var(--def-transition-300ms);\n      &__active {\n        color: var(--color-primary);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Menu_root__r2E5l`,
	"root__item": `Menu_root__item__vjzdB`,
	"root__item__active": `Menu_root__item__active__qeHsh`,
	"root__item__inner": `Menu_root__item__inner__MIwsJ`,
	"root__item__text": `Menu_root__item__text__O01ZR`,
	"root__item__text__active": `Menu_root__item__text__active__4U21e`
};
export default ___CSS_LOADER_EXPORT___;
