import { setTokens } from '@store/features/auth/authSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useSetAuthTokens = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const storageAccessToken = localStorage.getItem('access_token');
    const storageRefreshToken = localStorage.getItem('refresh_token');

    const sessionAccessToken = sessionStorage.getItem('access_token');
    const sessionRefreshToken = sessionStorage.getItem('refresh_token');

    const access = storageAccessToken || sessionAccessToken;
    const refresh = storageRefreshToken || sessionRefreshToken;

    if (access && access?.length > 0 && refresh && refresh?.length > 0) {
      dispatch(setTokens({ access, refresh }));
    }
  }, []);
};
