import styles from '@components/common/ToolBar/ToolBar.module.scss';
import { Icon } from '@components/ui';
import { classNames, setStyleSheetVars } from '@libs/utils';
import { selectRouterPathname } from '@store/features/router';
import { useAppSelector } from '@store/store';
import { FC, ReactElement, useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

type ToolBarProps = {
  title: string;
  action?: {
    text?: string;
    icon?: string;
    iconWidth?: number;
    iconHeight?: number;
    onClick?: () => void;
  };
};

export const ToolBar: FC<ToolBarProps> = ({ title, action }): ReactElement => {
  const { pathname } = useAppSelector(selectRouterPathname);
  const navigate = useNavigate();
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref && ref.current) {
      const element = ref.current as HTMLElement;

      if (element && element.clientHeight) {
        const vars = {
          toolBarHeight: element.clientHeight + 'px',
        };
        setStyleSheetVars(vars, 'x003');
      }
    }

    return () => {
      const vars = {
        toolBarHeight: '0px',
      };

      setStyleSheetVars(vars, 'x003');
    };
  }, [pathname]);

  return (
    <nav
      ref={ref}
      className={classNames(
        'fx-align-center',
        'fx-content-between',
        styles.root
      )}
    >
      <button
        type='button'
        className={classNames('fx-align-center', styles.side__left)}
        onClick={() => navigate(-1)}
      >
        <Icon name='x0011' width={32} height={32} />
      </button>
      <div className={styles.side__center}>
        <span className={styles.title}>{title}</span>
      </div>
      <div className={classNames('fx-content-end', styles.side__right)}>
        {action && action.icon && action.onClick && (
          <button
            type='button'
            onClick={action.onClick}
            className={styles.action}
          >
            {action?.icon && (
              <Icon
                name={action.icon}
                width={action.iconWidth}
                height={action.iconHeight}
              />
            )}
          </button>
        )}
      </div>
    </nav>
  );
};
