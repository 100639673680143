import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import { IGenericResponse } from '@store/services/otp/types';
import qs from 'qs';

const BASE_URL =
  `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SUFFIX}` as string;

const uri = {
  sendCode: 'send/',
  verify: 'verify/',
};

/**
 * Slice / Api
 */
export const otpService = createApi({
  tagTypes: ['OTP'],
  reducerPath: 'otpService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/otp`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    sendOtpCode: builder.mutation<IGenericResponse, { method: string }>({
      query(data) {
        return {
          url: uri.sendCode,
          method: 'POST',
          body: qs.stringify(data),
        };
      },
    }),
    verify: builder.mutation<IGenericResponse, { code: string }>({
      query(data) {
        return {
          url: uri.verify,
          method: 'POST',
          body: qs.stringify(data),
        };
      },
    }),
  }),
});

export const { useSendOtpCodeMutation, useVerifyMutation } = otpService;
