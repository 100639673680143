import { FC, ReactElement } from 'react';
import parse from 'html-react-parser';
import styles from '@components/common/RichText/RichText.module.scss';

type RichTextProps = {
  headline?: string;
  description?: string;
};

export const RichText: FC<RichTextProps> = ({
  headline,
  description,
}): ReactElement => (
  <div className={styles.root}>
    {headline && headline?.length > 0 && <h1>{parse(headline || '')}</h1>}
    {description && description?.length > 0 && (
      <p>{parse(description || '')}</p>
    )}
  </div>
);
