// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideBar_root__m3ypd {
  width: 260px;
  height: 100%;
  background-color: var(--color-white);
  box-shadow: 0 4px 16px rgba(19, 23, 39, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
}
.SideBar_root__inner__bREhP {
  width: 100%;
  height: 100%;
  min-height: 590px;
  position: relative;
}

.SideBar_divider__dUVNu {
  width: 80%;
  height: 1px;
  background-color: var(--color-athens-gray);
  margin: 30px auto;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/SideBar/SideBar.module.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,YAAA;EACA,oCAAA;EACA,4CAAA;EAEA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AADJ;;AAKA;EACE,UAAA;EACA,WAAA;EACA,0CAAA;EACA,iBAAA;AAFF","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 260px;\n  height: 100%;\n  background-color: var(--color-white);\n  box-shadow: 0 4px 16px set-color(--color-mirage, 0.1);\n  // overflow-y: auto;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 1040;\n\n  &__inner {\n    width: 100%;\n    height: 100%;\n    min-height: 590px;\n    position: relative;\n  }\n}\n\n.divider {\n  width: 80%;\n  height: 1px;\n  background-color: var(--color-athens-gray);\n  margin: 30px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `SideBar_root__m3ypd`,
	"root__inner": `SideBar_root__inner__bREhP`,
	"divider": `SideBar_divider__dUVNu`
};
export default ___CSS_LOADER_EXPORT___;
