import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import { IPage } from '@store/services/pages/types';

const BASE_URL =
  `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SUFFIX}` as string;

/**
 * Slise / Api
 */
export const pagesService = createApi({
  tagTypes: ['Pages'],
  reducerPath: 'pagesService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/pages/page/`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    fetchPage: builder.query<IPage, { slug: string }>({
      query: (args) => {
        return {
          url: args.slug + '/',
          method: 'GET',
        };
      },
      transformResponse: (result: any, meta, arg) => result?.data || null,
    }),
  }),
});

export const { useFetchPageQuery } = pagesService;
