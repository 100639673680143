import { EmptyResults } from '@components/common/EmptyResults/EmptyResults';
import { PackageGroup } from '@components/common/PackageGroup/PackageGroup';
import { selectSelectedCategory } from '@store/features/packages/packages.slice';
import { useFetchPackagesQuery } from '@store/services/packages/packagesService';
import { useAppSelector } from '@store/store';
import { FC, ReactElement, useState } from 'react';

type PackagesMobileProps = {};

export const PackagesMobile: FC<PackagesMobileProps> = ({}): ReactElement => {
  const [offset, setOffset] = useState<number>(0);
  const { type, text } = useAppSelector(selectSelectedCategory);
  const { data, isLoading, isSuccess, isError } = useFetchPackagesQuery(
    {
      type,
      offset,
      quantity: 50,
    },
    { skip: !type || type?.length === 0 }
  );

  return (
    <>
      {isLoading && <p>Cargando...</p>}
      {isError && (
        <EmptyResults
          isEmpty={true}
          desktop={true}
          message='Error al cargar. Inténtalo de nuevo.'
        />
      )}
      {isSuccess && (!data || data?.length === 0) && (
        <EmptyResults
          isEmpty={true}
          desktop={true}
          message='¡No se encontraron paquetes!'
        />
      )}
      {isSuccess && data?.length > 0 && (
        <PackageGroup headline={text} list={data} />
      )}
    </>
  );
};
