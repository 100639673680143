// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BottomBar_root__lXgUd {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1030;
}
.BottomBar_root__ios__r5F7W {
  padding: 10px 15px 30px;
}
.BottomBar_root__android__nA0Dh {
  padding: 10px 15px 15px;
}
.BottomBar_root__desktop__6psWk {
  padding: 10px 15px;
}

.BottomBar_link__5kYHV {
  width: 68px;
}
.BottomBar_link__active__O0WqJ .BottomBar_link__text__ot-l1 {
  color: var(--color-primary);
}
.BottomBar_link__text__ot-l1 {
  display: inline-block;
  font-weight: var(--medium);
  font-size: 12px;
  color: var(--color-manatee);
  margin: 2px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/@core/components/common/BottomBar/BottomBar.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,oCAAA;EACA,6BAAA;EACA,kBAAA;EACA,eAAA;EACA,OAAA;EACA,SAAA;EACA,aAAA;AAAF;AACE;EACE,uBAAA;AACJ;AACE;EACE,uBAAA;AACJ;AACE;EACE,kBAAA;AACJ;;AAGA;EACE,WAAA;AAAF;AAEI;EACE,2BAAA;AAAN;AAGE;EACE,qBAAA;EACA,0BAAA;EACA,eAAA;EACA,2BAAA;EACA,eAAA;AADJ","sourcesContent":["@import 'src/@core/styles/scss/includes.scss';\n.root {\n  width: 100%;\n  background-color: var(--color-white);\n  box-shadow: var(--box-shadow);\n  padding: 10px 15px;\n  position: fixed;\n  left: 0;\n  bottom: 0;\n  z-index: 1030;\n  &__ios {\n    padding: 10px 15px 30px;\n  }\n  &__android {\n    padding: 10px 15px 15px;\n  }\n  &__desktop {\n    padding: 10px 15px;\n  }\n}\n\n.link {\n  width: 68px;\n  &__active {\n    .link__text {\n      color: var(--color-primary);\n    }\n  }\n  &__text {\n    display: inline-block;\n    font-weight: var(--medium);\n    font-size: 12px;\n    color: var(--color-manatee);\n    margin: 2px 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `BottomBar_root__lXgUd`,
	"root__ios": `BottomBar_root__ios__r5F7W`,
	"root__android": `BottomBar_root__android__nA0Dh`,
	"root__desktop": `BottomBar_root__desktop__6psWk`,
	"link": `BottomBar_link__5kYHV`,
	"link__active": `BottomBar_link__active__O0WqJ`,
	"link__text": `BottomBar_link__text__ot-l1`
};
export default ___CSS_LOADER_EXPORT___;
