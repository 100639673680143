// import styles from '@components/common/TopBar/Tabs/Tabs.module.scss';
import styles from '@components/common/TopBar/Tabs/Tabs.module.scss';
import { classNames } from '@libs/utils';
import { useFetchMenuQuery } from '@store/services/menus/menusService';
import { useAppSelector } from '@store/store';
import { FC, Fragment, ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';

type TabsInfoProps = {};

export const TabsInfo: FC<TabsInfoProps> = (): ReactElement => {
  const { pathname } = useAppSelector((state) => state.router.location);
  const memoizedUri = useMemo((): string => {
    const pathParts = pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    return lastPart === 'info' ? '/' : '/' + lastPart || '/';
  }, [pathname]);
  const { data, isSuccess } = useFetchMenuQuery({ slug: 'info-menu' });

  return isSuccess && data?.items ? (
    <ul className={classNames('fx-align-center', styles.root)}>
      {data?.items.map(({ id, name, uri }) => (
        <li key={id} className={styles.root__item}>
          <Link
            to={'/info' + uri}
            className={classNames(
              styles.root__item__element,
              memoizedUri === uri ? styles.root__item__element__active : ''
            )}
          >
            {name}
          </Link>
        </li>
      ))}
    </ul>
  ) : (
    <Fragment />
  );
};
