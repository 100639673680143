import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customPrepareHeaders from '@store/middlewares/customPrepareHeaders';
import { ISettings } from '@store/services/settings/types';

const BASE_URL =
  `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SUFFIX}` as string;

export interface IResult {
  data: ISettings;
  message: string;
  success: boolean;
}

/**
 * Slise / Api
 */
export const settingsService = createApi({
  tagTypes: ['Settings'],
  reducerPath: 'settingsService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/settings/`,
    prepareHeaders: customPrepareHeaders,
  }),
  endpoints: (builder) => ({
    fetchSettings: builder.query<any, any>({
      query: (args) => {
        return {
          url: '/',
          method: 'GET',
        };
      },
      transformResponse: (result: IResult, meta, arg): ISettings =>
        result?.data || [],
    }),
  }),
});

export const { useFetchSettingsQuery } = settingsService;
